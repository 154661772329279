import * as React from "react";
import { View } from "react-native";

import InputNumericExercise from "./exercise";
import InputNumericRest from "./rest";
import InputNumericRounds from "./rounds";

const InputNumericContainer = () => {
  return (
    <View>
      <InputNumericExercise isLast={false} />
      <InputNumericRest isLast={false} />
      <InputNumericRounds isLast={true} />
    </View>
  );
};

export default InputNumericContainer;
