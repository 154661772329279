import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import CONST from "components/CONST";
import DragContainer from "./drag-container";
import INPUT_TEXT_CONST from "./INPUT_TEXT_CONST";

type Props = {
  breakPoint: boolean;
};

const InputTextContainer = ({ breakPoint }: Props) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: "column",
      backgroundColor: "white",
      borderRadius: breakPoint ? 24 : 16,
      padding: breakPoint
        ? INPUT_TEXT_CONST.containerPaddingBreakpoint
        : INPUT_TEXT_CONST.containerPaddingBreakpoint,
      paddingBottom: breakPoint ? 12 : 4,
    },
    title: {
      fontFamily: CONST.fonts.latoBold,
      color: CONST.colorFontGrey,
      fontSize: breakPoint ? 32 : 24,
      marginBottom: 10,
    },
  });

  return (
    <View style={[styles.container]}>
      <Text style={styles.title}>Exercises</Text>
      <DragContainer />
    </View>
  );
};

export default InputTextContainer;
