import { ThunkAction } from "redux-thunk";
import {
  state,
  action,
  deleteWorkoutArgs,
  inputsWithoutIds,
  moveExerciseArgs,
  popularWorkoutsModalToggleArgs,
  removeExerciseArgs,
  reorderExercisesArgs,
  setHiitWorkoutInputsArgs,
  setCustomWorkoutInputsArgs,
  setDimensionsArgs,
  setExerciseArgs,
  setLegalConsentArgs,
  setRoundsArgs,
  setSecondsExerciseArgs,
  setSecondsRestArgs,
  socialShareModalToggleArgs,
  toggleMobileMenuArgs,
  toggleSoundArgs,
  toggleWorkoutTypeArgs,
  customSetWeightValueArgs,
  customSetWeightFormatArgs,
  customSetDurationFormatArgs,
  customSetDurationValueArgs,
  customSetRestDurationArgs,
  setEmailAddressArgs,
  sendSubscriptionRequestArgs,
  setEmailSubscriptionStatusArgs,
  saveWorkoutModalToggleArgs,
  saveNewWorkoutArgs,
  moveSavedWorkoutArgs,
  renameSavedWorkoutArgs,
  overwriteSavedWorkoutArgs,
} from "store/types";
import { subscribe } from "./utils/subscribeEmailAddress";

export const showEmailSignup = () => ({
  type: "showEmailSignup",
});

export const setEmailSignupWasClosed = () => ({
  type: "setEmailSignupWasClosed",
});

export const setEmailSubscriptionStatus = ({
  emailAddress,
  isBeingSubscribed,
  hasSubscribedSuccessfully,
}: setEmailSubscriptionStatusArgs): action => ({
  type: "setEmailSubscriptionStatus",
  hasSubscribedSuccessfully,
  isBeingSubscribed,
  emailAddress,
});

export const sendSubscriptionRequest = ({
  emailAddress,
}: sendSubscriptionRequestArgs): ThunkAction<void, state, void, action> => {
  return async dispatch => {
    // Clear status of the subscription
    dispatch(
      setEmailSubscriptionStatus({
        emailAddress,
        isBeingSubscribed: true,
        hasSubscribedSuccessfully: false,
      }),
    );

    let hasSubscribedSuccessfully = false;
    try {
      hasSubscribedSuccessfully = await subscribe(emailAddress);
    } catch (e) {
      // An unexpected error occurred, most probably we haven't subscribed successfully
      console.log(e);
    }

    // Update status with whatever came from the subscribe function
    dispatch(
      setEmailSubscriptionStatus({
        emailAddress,
        isBeingSubscribed: false,
        hasSubscribedSuccessfully,
      }),
    );
  };
};

export const setEmailAddress = ({
  emailAddress,
}: setEmailAddressArgs): action => ({
  type: "setEmailAddress",
  emailAddress,
});

export const soundHackActivatedToggle = ({
  value,
}: {
  value: boolean;
}): action => ({
  type: "soundHackActivatedToggle",
  value,
});

// TODO Remove isLandscape
export const setDimensions = ({
  width,
  height,
  isLandscape,
}: setDimensionsArgs): action => ({
  type: "setDimensions",
  width,
  height,
  isLandscape,
});

// Set custom workout
export const customSetRounds = ({ value }: setRoundsArgs): action => ({
  type: "customSetRounds",
  value,
});

export const customSetSecondsRest = ({
  value,
}: setSecondsRestArgs): action => ({
  type: "customSetSecondsRest",
  value,
});

export const customReorderExercises = ({
  oldIndex,
  newIndex,
}: reorderExercisesArgs): action => ({
  type: "customReorderExercises",
  oldIndex,
  newIndex,
});

export const customSetExerciseText = ({
  index,
  text,
}: setExerciseArgs): action => ({
  type: "customSetExerciseText",
  index,
  text,
});

export const customAddExercise = () => ({ type: "customAddExercise" });

export const customAddRest = () => ({ type: "customAddRest" });

export const customSetWeightValue = ({
  index,
  value,
}: customSetWeightValueArgs): action => ({
  type: "customSetWeightValue",
  index,
  value,
});

export const customSetWeightFormat = ({
  index,
  format,
}: customSetWeightFormatArgs): action => ({
  type: "customSetWeightFormat",
  index,
  format,
});

export const customSetDurationValue = ({
  index,
  value,
}: customSetDurationValueArgs): action => ({
  type: "customSetDurationValue",
  index,
  value,
});

export const customSetRestDuration = ({
  index,
  value,
}: customSetRestDurationArgs): action => ({
  type: "customSetRestDuration",
  index,
  value,
});

export const customSetDurationFormat = ({
  index,
  format,
}: customSetDurationFormatArgs): action => ({
  type: "customSetDurationFormat",
  index,
  format,
});

// Set workout
export const setSecondsExercise = ({
  value,
}: setSecondsExerciseArgs): action => ({
  type: "setSecondsExercise",
  value,
});

export const setSecondsRest = ({ value }: setSecondsRestArgs): action => ({
  type: "setSecondsRest",
  value,
});

export const setRounds = ({ value }: setRoundsArgs): action => ({
  type: "setRounds",
  value,
});

export const setExercise = ({ index, text }: setExerciseArgs): action => ({
  type: "setExercise",
  index,
  text,
});

export const reorderExercises = ({
  oldIndex,
  newIndex,
}: reorderExercisesArgs): action => ({
  type: "reorderExercises",
  oldIndex,
  newIndex,
});

export const removeExercise = ({ index }: removeExerciseArgs): action => ({
  type: "removeExercise",
  index,
});

export const removeExerciseCustom = ({
  index,
}: removeExerciseArgs): action => ({
  type: "removeExerciseCustom",
  index,
});

export const moveExerciseUp = ({ index }: moveExerciseArgs): action => ({
  type: "moveExerciseUp",
  index,
});

export const moveExerciseDown = ({ index }: moveExerciseArgs): action => ({
  type: "moveExerciseDown",
  index,
});

export const resetWorkout = (): action => ({
  type: "resetWorkout",
});

export const countdownTick = (): action => ({
  type: "countdownTick",
});

export const togglePause = (): action => ({
  type: "togglePause",
});

export const toggleMobileMenu = ({ isOpen }: toggleMobileMenuArgs): action => ({
  type: "toggleMobileMenu",
  isOpen,
});

export const toggleSound = ({ isPlaying }: toggleSoundArgs): action => ({
  type: "toggleSound",
  isPlaying,
});

export const skipCurrent = (): action => ({
  type: "skipCurrent",
});

export const prevActivity = (): action => ({
  type: "prevActivity",
});

export const setLegalConsent = ({ value }: setLegalConsentArgs): action => ({
  type: "setLegalConsent",
  value,
});

export const socialShareModalToggle = ({
  isOpen,
}: socialShareModalToggleArgs): action => ({
  type: "socialShareModalToggle",
  isOpen,
});

export const popularWorkoutsModalToggle = ({
  isOpen,
}: popularWorkoutsModalToggleArgs): action => ({
  type: "popularWorkoutsModalToggle",
  isOpen,
});

export const selectAWorkout = ({
  secondsExercise,
  secondsRest,
  rounds,
  exercises,
}: inputsWithoutIds): action => ({
  type: "selectAWorkout",
  secondsExercise,
  secondsRest,
  rounds,
  exercises,
});

export const setHiitWorkoutInputs = (
  args: setHiitWorkoutInputsArgs,
): action => ({
  ...args,
  type: "setHiitWorkoutInputs",
});

export const setCustomWorkoutInputs = (
  args: setCustomWorkoutInputsArgs,
): action => ({
  ...args,
  type: "setCustomWorkoutInputs",
});

export const saveWorkoutModalToggle = ({
  value,
}: saveWorkoutModalToggleArgs): action => ({
  type: "saveWorkoutModalToggle",
  value,
});

export const deleteWorkout = ({ index }: deleteWorkoutArgs): action => ({
  type: "deleteWorkout",
  index,
});

export const saveNewWorkout = ({ name }: saveNewWorkoutArgs) => ({
  type: "saveNewWorkout",
  name,
});

export const overwriteSavedWorkout = ({
  index,
}: overwriteSavedWorkoutArgs) => ({
  type: "overwriteSavedWorkout",
  index,
});

export const renameSavedWorkout = ({
  index,
  name,
}: renameSavedWorkoutArgs) => ({
  type: "renameSavedWorkout",
  name,
  index,
});

export const moveSavedWorkoutUp = ({ index }: moveSavedWorkoutArgs) => ({
  type: "moveSavedWorkoutUp",
  index,
});

export const moveSavedWorkoutDown = ({ index }: moveSavedWorkoutArgs) => ({
  type: "moveSavedWorkoutDown",
  index,
});

export const toggleWorkoutType = ({
  workoutType,
}: toggleWorkoutTypeArgs): action => ({
  type: "toggleWorkoutType",
  workoutType,
});
