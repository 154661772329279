import { connect } from "react-redux";
// import {state} from 'store/types'
import { popularWorkoutsModalToggle } from "store/actions";
import PopularWorkoutsButton from "./popular-workouts-button";

const mapDispatch = {
  popularWorkoutsModalToggle,
};

export default connect(null, mapDispatch)(PopularWorkoutsButton);
