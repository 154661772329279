import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";
import CONST from "./CONST";

type Props = {
  color?: string;
};

const IconContact = ({ color }: Props) => {
  const color2 = color || "white";

  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      {...preventTabbing}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 8.25H15v1.5H9.75V15h-1.5V9.75H3v-1.5h5.25V3h1.5v5.25z"
        fill={color2}
        {...preventTabbing}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 8.25H15v1.5H3v-1.5h6.75z"
        fill={color2}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconContact;
