import { saveWorkoutModalToggleArgs } from "store/types";
import * as React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import SaveIcon from "components/global/components/icons/save";
import CONST from "components/CONST";

type Props = {
  saveWorkoutModalToggle: (arg0: saveWorkoutModalToggleArgs) => void;
  sharedStyles: any;
  breakPoint: boolean;
};

const SaveWorkout = ({
  saveWorkoutModalToggle,
  sharedStyles,
  breakPoint,
}: Props) => {
  const styles = StyleSheet.create({
    button: {
      flexGrow: 1,
    },
    button__breakpoint: {
      minWidth: 185,
    },
  });

  const colors = [CONST.colorBtnSecondary, CONST.colorBtnSecondary];

  return (
    <TouchableOpacity onPress={() => saveWorkoutModalToggle({ value: true })}>
      <LinearGradient
        colors={colors}
        style={[
          sharedStyles.button,
          styles.button,
          breakPoint && styles.button__breakpoint,
        ]}
        start={[0, 0]}
        end={[1, 0]}
      >
        <SaveIcon />
        <Text
          style={[sharedStyles.buttonText, sharedStyles.buttonText__withIcon]}
        >
          Save
        </Text>
      </LinearGradient>
    </TouchableOpacity>
  );
};

export default SaveWorkout;
