import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";

type Props = {
  children: React.ReactElement;
};

const NavigationProvider = ({ children }: Props) => {
  return <Router>{children}</Router>;
};

export default NavigationProvider;
