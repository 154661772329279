import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import Elapsed from "./elapsed";
import { routineCurrent } from "application/store/computed/calculateRoutine";

const mapState = (state: state) => {
  return {
    elapsed: routineCurrent(state).elapsedRoutineLength,
  };
};

export default connect(mapState)(Elapsed);
