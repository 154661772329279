import { CalculatedRoutineMoment, RoutineActivity } from "application/types";
import * as React from "react";
import * as Speech from "expo-speech";

type Props = {
  routineCurrent: CalculatedRoutineMoment;
  warmUpIsFinished: boolean;
  sound: boolean;
  warmUpTimeIndex: number;
};

function routineActivityToSpeech(activity: RoutineActivity): string {
  if (typeof activity === "string") {
    return activity;
  }

  const activityWithWeight = (() => {
    if (activity.weight.amount === 0) {
      return activity.name;
    }
    return `${activity.name}, ${activity.weight.amount} ${activity.weight.format}`;
  })();

  if (activity.repeat.format === "max") {
    return `${activityWithWeight}, maximum`;
  } else if (activity.repeat.format === "reps") {
    return `${activityWithWeight}, ${activity.repeat.amount} reps`;
  }

  // We do not read time
  return activityWithWeight;
}

const MakeSoundInvisible = ({
  routineCurrent,
  warmUpIsFinished,
  sound,
}: Props) => {
  if (!sound || !warmUpIsFinished) {
    return null;
  }

  const { activity, activityDuration, activityDurationIndex } = routineCurrent;

  React.useEffect(() => {
    if (activityDuration !== "at-will") {
      const countDownNumber = activityDuration - activityDurationIndex;
      if (countDownNumber <= 3 && countDownNumber > 0) {
        Speech.speak(`${countDownNumber}`);
      }
    }

    if (warmUpIsFinished && activityDurationIndex === 0) {
      Speech.speak(routineActivityToSpeech(activity));
    }
  }, [warmUpIsFinished, activityDurationIndex, activityDuration]);

  return null;
};

export default MakeSoundInvisible;
