import { InputsHiit, reorderExercisesArgs } from "store/types";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortEnd,
} from "react-sortable-hoc";
import InputText from "../input-text";
import DragHandleComponent from "../drag-handle";

type SortableItemProps = {
  value: string;
  index2: number;
  isLast: boolean;
};

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    marginBottom: 0,
    borderBottomWidth: 1,
    borderBottomColor: "#F3F2F5",
    backgroundColor: "white",
  },
});

const DragHandle = SortableHandle(() => <DragHandleComponent />);

const SortableItem = SortableElement(
  ({ value, index2, isLast }: SortableItemProps) => {
    return (
      <View style={[styles.item, isLast && { borderBottomWidth: 0 }]}>
        <View>
          <DragHandle />
        </View>
        <InputText index={index2} text={value} isLast={isLast} />
      </View>
    );
  },
);

type SortableListProps = {
  exercises: InputsHiit["exercises"];
};

const SortableList = SortableContainer(({ exercises }: SortableListProps) => {
  return (
    <View>
      {exercises.map((item, index) => {
        const isLast = exercises.length - 1 === index;
        const { text, id } = item;
        return (
          <SortableItem
            key={id}
            index={index}
            index2={index}
            value={text}
            isLast={isLast}
          />
        );
      })}
    </View>
  );
});

type DragContainerProps = {
  exercises: InputsHiit["exercises"];
  reorderExercises: (args: reorderExercisesArgs) => void;
};

const DragContainer = ({ exercises, reorderExercises }: DragContainerProps) => {
  const handleDragEnd = (e: SortEnd) => {
    const { oldIndex, newIndex } = e;
    reorderExercises({ oldIndex, newIndex });
  };
  return (
    <SortableList
      lockAxis="y"
      useDragHandle={true}
      exercises={exercises}
      onSortEnd={handleDragEnd}
    />
  );
};

export default DragContainer;
