import * as React from "react";
import FinishedPage from "./finished";
import InProgressPage from "./in-progress";

type Props = {
  routineIsFinished: boolean;
};

const GoPage = ({ routineIsFinished }: Props) => {
  if (routineIsFinished) {
    return <FinishedPage />;
  }
  return <InProgressPage />;
};

export default GoPage;
