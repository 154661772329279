import * as React from "react";
import { connect } from "react-redux";
import { removeExerciseCustom, customSetExerciseText } from "store/actions";
import Exercise from "./exercise";

const mapDispatch = {
  removeExerciseCustom,
  customSetExerciseText,
};

export default connect(null, mapDispatch)(Exercise);
