// import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import { removeExerciseCustom } from "store/actions";
import Rest from "./rest";

const mapDispatch = {
  removeExerciseCustom,
};

export default connect(null, mapDispatch)(Rest);
