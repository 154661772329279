import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import { customReorderExercises } from "store/actions";
import Routine from "./routine";

const mapState = (state: state) => {
  const { routine } = state.inputsCustom;
  return {
    routine,
  };
};

const mapDispatch = {
  customReorderExercises: customReorderExercises,
};

export default connect(mapState, mapDispatch)(Routine);
