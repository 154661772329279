import { RoutineActivity } from "application/types";

export default function calculateRoutineActivityName(
  activity: RoutineActivity,
) {
  if (typeof activity === "string") {
    return activity;
  }

  return activity.name;
}
