import * as React from "react";
import { Picker as RNPicker, Platform } from "react-native";

export interface CustomPickerItem<T> {
  label: string;
  value: T;
}

export function useSelectedLabel<T extends string | number>(
  items: CustomPickerItem<T>[] | undefined,
  selectedValue?: T | undefined,
) {
  return React.useMemo(
    () => items?.find(({ value }) => value === selectedValue)?.label,
    [items, selectedValue],
  );
}

export function usePrompt(
  prompt: undefined | string | (() => string | undefined),
) {
  return React.useMemo(() => {
    if (!prompt || typeof prompt === "string") {
      return prompt;
    }

    return prompt();
  }, [prompt]);
}

export function useInitialValues<T extends string | number>(
  items: CustomPickerItem<T>[] | undefined,
  selectedValue?: T | undefined,
): [T | undefined, number] {
  return React.useMemo<[T | undefined, number]>(() => {
    const selectedValueIndex =
      items?.findIndex(({ value }) => value === selectedValue) ?? -1;
    return [selectedValue, selectedValueIndex];
  }, []);
}

const ANDROID_LABEL_SPACING_PREFIX = "     ";

export function CustomPickerItem<T extends string | number>({
  label,
  value,
}: CustomPickerItem<T>) {
  return (
    <RNPicker.Item
      key={value}
      value={value}
      label={Platform.select({
        android: `${ANDROID_LABEL_SPACING_PREFIX}${label}`,
        default: label,
      })}
    />
  );
}
