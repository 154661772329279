import * as React from "react";
import { StyleSheet, View } from "react-native";
import INPUT_TEXT_CONST from "../INPUT_TEXT_CONST";
import IconDragHandle from "components/global/components/icons/dragHandle";

const DragHandle = () => {
  return (
    <View style={styles.container}>
      <IconDragHandle />
    </View>
  );
};

// TODO Use setNativeProps to apply hover drag style

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "rgba(50,0,100,.5)", // purple
    width: INPUT_TEXT_CONST.dragHandleWidth,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});

export default DragHandle;
