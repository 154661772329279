import { state } from "application/store/types";

import { connect } from "react-redux";

import LegalMessageWeb from "./legalMessageWeb";
import { setLegalConsent } from "store/actions";
import breakPoint from "store/computed/breakPoints";

const mapState = (state: state) => {
  const { width, legalConsentGranted } = state;
  return {
    legalConsentGranted,
    breakPoint: breakPoint(state),
    customBreakPoint1: width > 580,
    customBreakPoint2: width > 920,
  };
};

const mapDispatch = {
  setLegalConsent,
};

export default connect(mapState, mapDispatch)(LegalMessageWeb);
