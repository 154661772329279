import { state } from "store/types";

import * as React from "react";
import { connect } from "react-redux";

import { routineCurrent } from "store/computed/calculateRoutine";
import { togglePause } from "store/actions";
import breakPoint from "store/computed/breakPoints";

import Activity from "./activity";

const mapState = (state: state) => {
  const { height, warmUpIsFinished, paused } = state;
  return {
    // TODO Remove unnecessary fields?
    routineCurrent: routineCurrent(state),
    warmUpIsFinished,
    breakPoint: breakPoint(state),
    paused,
    heightBreakPoint: height > 600,
  };
};

const mapDispatch = {
  togglePause,
};

export default connect(mapState, mapDispatch)(Activity);
