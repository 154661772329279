import * as React from "react";
import { TextInput, TextInputProps } from "react-native";

// import TextInputListContext from "./text-input-list-context";

export interface ListAwareTextInputProps extends TextInputProps {
  index: number;
}

export default function ListAwareTextInput({
  index,
  ...rest
}: ListAwareTextInputProps) {
  // const refs = React.useContext(TextInputListContext);
  // const isLast = index === refs.length - 1;
  return (
    <TextInput
      // This would be a nice UX improvement, however, the return key
      // type seems to not update while the input is focused,
      // so focusing on the last text input (the one with which one can
      // add another exercise) and adding some text (which creates
      // a new "Add exercise..." row) keeps the return button in
      // the "done" state which seems odd.
      // returnKeyType={/*isLast ? "done" : */ "next"}
      // blurOnSubmit={isLast}
      {...rest}
      // ref={refs[index]}
    />
  );
}
