import * as React from "react";
import { connect } from "react-redux";
import { state } from "store/types";
import MakeSoundInvisible from "./make-sound-invisible";

const mapState = (state: state) => {
  const { sound } = state;
  return {
    sound,
  };
};

export default connect(mapState)(MakeSoundInvisible);
