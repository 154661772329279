import { popularWorkoutsModalToggleArgs } from "store/types";
import * as React from "react";
import { StyleSheet, Text, View } from "react-native";

import CONST from "components/CONST";
import { advanced, beginner, intermediate } from "./workout-lists";

import Modal from "components/global/components/modal";
import Workout from "./workout";

type Props = {
  popularWorkoutsModalToggle: (arg0: popularWorkoutsModalToggleArgs) => void;
  breakPoint: boolean;
  popularWorkoutsModalIsOpen: boolean;
  setModalMinWidth: boolean;
};

// TODO Make heading semantic
const PopularWorkoutsModal = ({
  popularWorkoutsModalIsOpen,
  popularWorkoutsModalToggle,
  breakPoint,
  setModalMinWidth,
}: Props) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: breakPoint ? "row" : "column",
      flexWrap: "wrap",
      flex: 1,
      marginBottom: -20,
      overflow: "hidden",
      minWidth: setModalMinWidth ? 720 : undefined,
    },
    workoutSection: {
      width: breakPoint ? "33.33%" : "100%",
      borderBottomColor: "rgb(229, 231, 231)",
      borderBottomWidth: breakPoint ? 0 : 1,
      marginBottom: breakPoint ? 10 : 20,
      paddingRight: breakPoint ? 40 : 0,
    },
    workoutSectionLast: {
      borderBottomWidth: 0,
      paddingRight: 0,
    },
    levelHeading: {
      fontFamily: CONST.fonts.latoRegular,
      color: CONST.colorFontGrey,
      textTransform: "uppercase",
      marginBottom: 20,
    },
  });

  return (
    <Modal
      isOpen={popularWorkoutsModalIsOpen}
      close={() => popularWorkoutsModalToggle({ isOpen: false })}
      heading="Popular workouts"
    >
      <View style={styles.container}>
        <View style={[styles.workoutSection]}>
          <Text style={styles.levelHeading}>Beginner</Text>
          {beginner.map((workout, index) => {
            return <Workout key={index} workout={workout} />;
          })}
        </View>

        <View style={[styles.workoutSection]}>
          <Text style={styles.levelHeading}>Intermediate</Text>
          {intermediate.map((workout, index) => {
            return <Workout key={index} workout={workout} />;
          })}
        </View>

        <View style={[styles.workoutSection, styles.workoutSectionLast]}>
          <Text style={styles.levelHeading}>Advanced</Text>
          {advanced.map((workout, index) => {
            return <Workout key={index} workout={workout} />;
          })}
        </View>
      </View>
    </Modal>
  );
};

export default PopularWorkoutsModal;
