import { customSetRestDurationArgs } from "store/types";
import * as React from "react";
import { View, StyleSheet, Platform } from "react-native";
import CONST from "components/CONST";
import TimeFormat from "hh-mm-ss";
import DurationPicker from "application/components/global/components/duration-picker";
// import IconSelectChevron from "components/global/components/icons/select-chevron";

type Props = {
  first: string | number;
  isRest?: boolean;
  customSetRestDuration: (arg0: customSetRestDurationArgs) => void;
  index: number;
};

const CHEVRON_VERTICAL_OFFSET = 3;

// TODO Is numeric the best keyboard type?
// Use keyboard aware View?

const Input = ({ first, isRest, customSetRestDuration, index }: Props) => {
  const styles = StyleSheet.create({
    root: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 1,
      borderColor: isRest ? "#5E5375" : "#D1CED8",
      backgroundColor: isRest ? "#3F325A" : "#F6F5F7",
      paddingHorizontal: 6,
      paddingVertical: 3,
      borderRadius: 8,
      marginLeft: 10,
    },
    root_notIos: {
      flexGrow: 1,
      maxWidth: 108,
    },
    divider: {
      backgroundColor: "#D1CED8",
      width: 1,
      height: 16,
      marginHorizontal: 4,
    },
    textContainer: {
      // Setting static height for the input
      // to be the same height as inputs
      // for exercises.
      height: 28,
      justifyContent: "center",
    },
    textContainer_ios: {
      width: 52,
    },
    textContainer_notIos: {
      flexGrow: 1,
    },
    text: {
      color: isRest ? "white" : "#564B6E",
      fontFamily: CONST.fonts.latoBold,
      fontSize: 15,
      textAlign: "center",
    },
    iconWrapper: {
      position: "absolute",
      right: 4,
    },
    iconWrapper_1: {
      top: CHEVRON_VERTICAL_OFFSET,
    },
    iconWrapper_2: {
      bottom: CHEVRON_VERTICAL_OFFSET,
    },
  });

  // const icons = (
  //   <>
  //     <View style={[styles.iconWrapper, styles.iconWrapper_1]}>
  //       <IconSelectChevron />
  //     </View>
  //     <View style={[styles.iconWrapper, styles.iconWrapper_2]}>
  //       <IconSelectChevron direction={"down"} />
  //     </View>
  //   </>
  // );

  return (
    <View style={[styles.root, Platform.OS !== "ios" && styles.root_notIos]}>
      <DurationPicker
        prompt="Rest"
        selectedValue={TimeFormat.fromS(Number(first))}
        style={[
          styles.textContainer,
          Platform.OS === "ios"
            ? styles.textContainer_ios
            : styles.textContainer_notIos,
        ]}
        spacerStyle={styles.text}
        indicatorsStyle={styles.text}
        onValueChange={newValue =>
          customSetRestDuration({
            index,
            value: +TimeFormat.toS(newValue),
          })
        }
      />
      {/*{!isMax && !isRest && <View style={styles.divider} />}*/}
      {/*{second && (*/}
      {/*  <Text style={styles.text}>*/}
      {/*    {second}*/}
      {/*    /!*{icons}*!/*/}
      {/*  </Text>*/}
      {/*)}*/}
    </View>
  );
};

export default Input;
