import { Pages } from "application/types";

import React from "react";
import { StyleSheet, Text, View } from "react-native";
// import styles from "./STYLES";
import Link from "components/global/components/link";
import CONST from "components/CONST";
// import CONST from "components/CONST";

export type Props = {
  text: string;
  Icon: React.ElementType;
  isCurrentPage: boolean;
  goTo: Pages;
};

const styles = StyleSheet.create({
  link: {
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    marginLeft: 40,
    borderBottomWidth: 2,
    paddingBottom: 10,
    paddingTop: 12,
    borderBottomColor: "transparent",
  },
  linkIsCurrentPage: {
    borderBottomColor: "#4DD599",
  },
  text: {
    color: "white",
    marginLeft: 8,
    fontFamily: CONST.fonts.latoRegular,
    fontSize: 16,
    lineHeight: 16,
  },
});

const DesktopLink = ({ text, Icon, isCurrentPage, goTo }: Props) => {
  return (
    <Link goTo={goTo}>
      <View style={[styles.link, isCurrentPage && styles.linkIsCurrentPage]}>
        <Icon isDark={false} />
        <Text style={styles.text}>{text}</Text>
      </View>
    </Link>
  );
};

export default DesktopLink;
