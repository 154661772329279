import {
  customSetDurationFormatArgs,
  customSetDurationValueArgs,
  CustomExerciseDurationFormat,
} from "store/types";
import * as React from "react";
import { View, TextInput, StyleSheet, Platform } from "react-native";
import TimeFormat from "hh-mm-ss";
import { styles } from "../weight-input/weight-input";
import CustomPicker from "application/components/global/components/custom-picker";
import DurationPicker from "application/components/global/components/duration-picker";

interface Props {
  value: number;
  format: CustomExerciseDurationFormat;
  index: number;
  width: number;
  breakPoint: boolean;
  customSetDurationFormat: (arg0: customSetDurationFormatArgs) => void;
  customSetDurationValue: (arg0: customSetDurationValueArgs) => void;
}

const stylesLocal = StyleSheet.create({
  nonIosRoot: {
    flexGrow: 1,
    marginLeft: 10,
    maxWidth: 166,
  },
  nonIosDurationInputWrapper: {
    flexGrow: 1,
    alignItems: "center",
  },
});

// TODO Is numeric the best keyboard type?
// Use keyboard aware View?
const DurationInput = ({
  value,
  format,
  index,
  customSetDurationFormat,
  customSetDurationValue,
  breakPoint,
  width,
}: Props) => {
  const isMaximum = format === "max";

  const amountLabelWidth = (() => {
    if (isMaximum) return 110;
    if (Platform.OS === "ios") return 50;
    if (breakPoint) return 50;
    if (width > 400) return 40;
    return 34;
  })();

  return (
    <View
      style={[styles.root, Platform.OS !== "ios" && stylesLocal.nonIosRoot]}
    >
      {!isMaximum && (
        <>
          <View
            style={[
              styles.inputWrapper,
              Platform.OS !== "ios" && stylesLocal.nonIosDurationInputWrapper,
            ]}
          >
            {format === "time" ? (
              <DurationPicker
                prompt="Exercise"
                selectedValue={TimeFormat.fromS(value)}
                style={[
                  styles.textContainer,
                  styles.textInput,
                  Platform.OS !== "ios" && {
                    width: "100%",
                  },
                ]}
                spacerStyle={styles.text}
                indicatorsStyle={styles.text}
                onValueChange={newValue =>
                  customSetDurationValue({
                    index,
                    value: +TimeFormat.toS(newValue),
                  })
                }
              />
            ) : (
              <TextInput
                style={[styles.textContainer, styles.text, styles.textInput]}
                keyboardType="numeric"
                value={`${value}`}
                onChangeText={newValue => {
                  customSetDurationValue({ index, value: +newValue });
                }}
              />
            )}
          </View>
          <View style={styles.divider} />
        </>
      )}

      <View style={[styles.inputWrapper, styles.textInput]}>
        <CustomPicker
          style={[
            styles.select,
            {
              width: amountLabelWidth,
            },
          ]}
          indicatorStyle={styles.text}
          prompt="Duration unit"
          selectedValue={format}
          onValueChange={newFormat => {
            customSetDurationFormat({ index, format: newFormat });
          }}
          items={[
            { label: "reps", value: "reps" },
            { label: "time", value: "time" },
            { label: "maximum", value: "max" },
          ]}
        />
      </View>
    </View>
  );
};

export default DurationInput;
