import { StyleSheet } from "react-native";
import CONST from "components/CONST";

const marginBottom = 24;

const styles = StyleSheet.create({
  h1: {
    color: "white",
    fontFamily: CONST.fonts.latoBold,
    fontSize: 48,
    marginBottom,
  },
  h2: {
    color: "white",
    fontFamily: CONST.fonts.latoBold,
    fontSize: 36,
    marginBottom,
  },
  h3: {
    color: "white",
    fontFamily: CONST.fonts.latoBold,
    fontSize: 24,
    marginBottom,
  },
  p: {
    color: "white",
    fontFamily: CONST.fonts.latoRegular,
    fontSize: 16,
    marginBottom,
  },
  li: {
    color: "white",
    fontFamily: CONST.fonts.latoRegular,
    fontSize: 16,
    marginBottom,
  },
  strong: {
    fontFamily: CONST.fonts.latoBold,
  },
  a: {
    color: "white",
    fontFamily: CONST.fonts.latoRegular,
    fontSize: 18,
    marginBottom,
    textDecorationStyle: "solid",
    textDecorationColor: "white",
    textDecorationLine: "underline",
  },
});

export default styles;
