import { Pages } from "application/types";
import * as React from "react";
import { useLocation } from "react-router-dom";
import useCustomNav from "components/global/hooks/useCustomNav";

const RedirectOnPageLoadInvisible = () => {
  const location = useLocation();
  const navigate = useCustomNav();

  React.useEffect(() => {
    const page = location.pathname.replace(/^\//, "");
    if (page === Pages.go) {
      navigate.goTo(Pages.choose);
    }
  }, []);
  return null;
};

export default RedirectOnPageLoadInvisible;
