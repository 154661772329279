import { InputNumericTypes } from "application/types";
import { setSecondsExerciseArgs } from "store/types";

import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Svg, { G, Path } from "react-native-svg";
import TimeFormat from "hh-mm-ss";
import range from "lodash/range";

import { preventTabbing } from "components/CONST";
import CONST from "components/CONST";

import DurationPicker from "components/global/components/duration-picker";
import CustomPicker from "application/components/global/components/custom-picker/custom-picker";
import Slider from "./slider";

const roundsItems = range(1, 21).map(item => ({
  label: `${item}`,
  value: `${item}`,
}));

type Props = {
  type: InputNumericTypes;
  value: number;
  set: (args: setSecondsExerciseArgs) => void;
  increment: number;
  breakPoint: boolean;
  isLast: boolean;
  title: string;
  /**
   * For modal prompt `title` prop is used by default.
   * Provide `modalPrompt` if you want the modal prompt
   * to be different than the `title`.
   */
  modalPrompt?: string | (() => string | undefined);
};

export type ItemsType = {
  label: string;
  value: string;
}[];

export enum order {
  first = "first",
  last = "last",
  singular = "singular",
}

const InputNumeric = ({
  type,
  set,
  value,
  increment,
  breakPoint,
  isLast,
  title,
  modalPrompt,
}: Props) => {
  const columnWidth = 38;

  const styles = StyleSheet.create({
    container: {
      position: "relative",
      marginBottom: isLast ? undefined : breakPoint ? 48 : 32,
    },
    container1: {
      flexDirection: "row",
      paddingHorizontal: columnWidth,
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 8,
    },
    container2: {
      flexDirection: "row",
      alignItems: "center",
    },
    container2Col: {
      width: columnWidth,
    },
    container2Col2nd: {
      alignItems: "flex-end",
    },
    title: {
      fontFamily: CONST.fonts.latoRegular,
      textTransform: "uppercase",
      color: "white",
      // opacity: 0.5,
      fontSize: breakPoint ? 24 : 14,
    },
    inputContainer: {
      flexDirection: "row",
      backgroundColor: CONST.colorBlueInputBackground,
      borderColor: "#473A61",
      borderWidth: 1,
      borderRadius: CONST.buttonBorderRadius,
      paddingHorizontal: 10,
      // width: Platform.OS == "web" ? (breakPoint ? 96 : 90) : undefined,
      width: breakPoint ? 110 : 90,
      justifyContent: "center",
      alignItems: "center",
      // overflow: "hidden", // Needed for fake input
    },
    inputReal: {
      width: 32,
      textAlign: "left",
    },
    inputRealFirst: {
      textAlign: "right",
    },
    picker: {
      flex: 1,
    },
    pickerIndicator: {
      color: "white",
      textAlign: "center",
      fontFamily: CONST.fonts.latoBold,
      fontSize: breakPoint ? 26 : 21,
      lineHeight: breakPoint ? 48 : 34,
    },
    pickersSpacer: {
      color: "white",
      fontFamily: CONST.fonts.latoBold,
      textAlign: "center",
      paddingHorizontal: 2,
      fontSize: breakPoint ? 24 : 14,
    },
  });

  const lowercaseTitle = title.toLocaleLowerCase();

  return (
    <View style={styles.container}>
      <View style={styles.container1}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.inputContainer}>
          {type === InputNumericTypes.ROUNDS ? (
            <CustomPicker
              prompt={modalPrompt ?? title}
              items={roundsItems}
              selectedValue={`${value}`}
              onValueChange={newValue => set({ value: +newValue })}
              style={styles.picker}
              indicatorStyle={styles.pickerIndicator}
            />
          ) : (
            <DurationPicker
              prompt={modalPrompt ?? title}
              maxMinute={20}
              selectedValue={TimeFormat.fromS(value)}
              onValueChange={newDuration =>
                set({ value: +TimeFormat.toS(newDuration) })
              }
              style={styles.picker}
              pickersStyle={styles.picker}
              spacerStyle={styles.pickersSpacer}
              indicatorsStyle={styles.pickerIndicator}
            />
          )}
        </View>
      </View>

      <View style={styles.container2}>
        <View style={styles.container2Col}>
          <TouchableOpacity
            accessibilityLabel={`Decrease ${lowercaseTitle} duration`}
            onPress={() => {
              set({ value: value - increment });
            }}
          >
            <MinusIcon />
          </TouchableOpacity>
        </View>
        <Slider type={type} value={value} set={set} />
        <View style={[styles.container2Col, styles.container2Col2nd]}>
          <TouchableOpacity
            accessibilityLabel={`Increase ${lowercaseTitle} duration`}
            onPress={() => {
              set({ value: value + increment });
            }}
          >
            <PlusIcon />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const PlusIcon = () => {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 36 38"
      fill="none"
      {...preventTabbing}
    >
      <G fill="#fff" {...preventTabbing}>
        <Path
          d="M19.5 17.928H30v3H19.5v10.5h-3v-10.5H6v-3h10.5v-10.5h3v10.5z"
          {...preventTabbing}
        />
        <Path d="M19.5 17.928H30v3H6v-3h13.5z" {...preventTabbing} />
      </G>
    </Svg>
  );
};
const MinusIcon = () => {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 25"
      fill="none"
      {...preventTabbing}
    >
      <Path d="M13 11.952h7v2H4v-2h9z" fill="#fff" {...preventTabbing} />
    </Svg>
  );
};

export default InputNumeric;
