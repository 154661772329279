import { state, toggleSoundArgs } from "store/types";

type Args = {
  state: state;
  action: toggleSoundArgs;
};

const toggleSound = ({ state, action }: Args): state => {
  return {
    ...state,
    sound: action.isPlaying,
  };
};

export default toggleSound;
