import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";
import CONST from "./CONST";

type Props = {
  isDark?: boolean;
};

const IconStar = ({ isDark }: Props) => {
  const { darkColor } = CONST;
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" {...preventTabbing}>
      <Path
        d="M5.14186 12.4119L3.99501 19.0985L9.99999 15.9415L16.005 19.0985L14.8581 12.4119L19.7163 7.67635L13.0025 6.70078L9.99999 0.617065L6.9975 6.70078L0.283722 7.67635L5.14186 12.4119ZM13.0673 11.83L13.7914 16.0518L10 14.0585L6.20856 16.0518L6.93266 11.83L3.86533 8.84008L8.10428 8.22413L10 4.38298L11.8957 8.22413L16.1347 8.84008L13.0673 11.83Z"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconStar;
