import * as React from "react";
import { connect } from "react-redux";
import InputText from "./input-text";
import breakPoint from "store/computed/breakPoints";
import {
  moveExerciseDown,
  moveExerciseUp,
  removeExercise,
  setExercise,
} from "store/actions";
import { state } from "store/types";

const mapState = (state: state) => {
  const { isLandscape } = state;
  return {
    isLandscape,
    breakPoint: breakPoint(state),
  };
};

const mapDispatch = {
  setExercise,
  removeExercise,
  moveExerciseUp,
  moveExerciseDown,
};

export default connect(mapState, mapDispatch)(InputText);
