import { Pages } from "application/types";

import IconWorkout from "components/global/components/icons/workout";
import IconAbout from "components/global/components/icons/about";
import IconApple from "components/global/components/icons/appleAppStore";
import IconGoogle from "components/global/components/icons/googlePlayStore";
import IconStar from "components/global/components/icons/star";

const links = [
  {
    text: "Choose workout",
    Icon: IconWorkout,
    goTo: Pages.choose,
  },
  {
    text: "Saved",
    Icon: IconStar,
    goTo: Pages.saved,
  },
  {
    text: "About",
    Icon: IconAbout,
    goTo: Pages.about,
  },
  {
    text: "Android App",
    Icon: IconGoogle,
    goTo: Pages.appAndroid,
  },
  {
    text: "iOS App",
    Icon: IconApple,
    goTo: Pages.appIOs,
  },
];

export default links;
