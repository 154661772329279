import { inputsWithoutIds } from "store/types";
import { level as levelType, WorkoutInput } from "./workout-lists";

import * as React from "react";
import { connect } from "react-redux";
import { selectAWorkout } from "store/actions";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import CONST, { preventTabbing } from "components/CONST";
import { Path, Svg } from "react-native-svg";

import routineLength from "store/utils/calculateRoutineUtil/routineLength";
import { formatRoutineLength } from "components/utils/formatSeconds";

type Props = {
  selectAWorkout: (arg0: inputsWithoutIds) => void;
  workout: WorkoutInput;
};

const Workout = ({ selectAWorkout, workout }: Props) => {
  const styles = StyleSheet.create({
    workoutRow: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 20,
      flex: 1,
    },
    workoutName: {
      fontFamily: CONST.fonts.latoRegular,
      marginLeft: 10,
      marginRight: "auto",
    },
    workoutTime: {
      fontFamily: CONST.fonts.latoRegular,
      color: "#8C8B8E",
      marginLeft: 10,
    },
  });

  const {
    level,
    title,
    secondsExercise,
    secondsRest,
    rounds,
    exercises,
  } = workout;

  const routineLengthVal = routineLength({
    secondsExercise,
    secondsRest,
    rounds,
    exercises,
  });

  const timeToDisplay = formatRoutineLength(routineLengthVal);

  return (
    <TouchableOpacity
      style={styles.workoutRow}
      onPress={() =>
        selectAWorkout({
          secondsExercise,
          secondsRest,
          rounds,
          exercises,
        })
      }
    >
      <LevelIcon level={level} />
      <Text style={styles.workoutName}>{title}</Text>
      <Text style={styles.workoutTime}>{timeToDisplay}</Text>
    </TouchableOpacity>
  );
};

const LevelIcon = ({ level }: { level: levelType }) => {
  if (level === levelType.BEGINNER) {
    return (
      <Svg width="10" height="10" viewBox="0 0 10 10" {...preventTabbing}>
        <Path
          d="M7.48528 2L0 2L4.21468e-07 0H9.48528V9.48528L7.48528 9.48528V2Z"
          fill="#4DD599"
          {...preventTabbing}
        />
      </Svg>
    );
  }

  if (level === levelType.INTERMEDIATE) {
    return (
      <Svg width="14" height="14" viewBox="0 0 14 14" {...preventTabbing}>
        <Path
          d="M3.53553 0L3.53553 2L11.0208 2V9.48528L13.0208 9.48528V0H3.53553Z"
          fill="#FD9644"
          {...preventTabbing}
        />
        <Path
          d="M0 5.53553L7.48528 5.53553V13.0208L9.48528 13.0208V3.53553H4.21468e-07L0 5.53553Z"
          fill="#FD9644"
          {...preventTabbing}
        />
      </Svg>
    );
  }

  return (
    <Svg width="17" height="17" viewBox="0 0 17 17" {...preventTabbing}>
      <Path
        d="M7.07107 0L7.07107 2L14.5563 2L14.5563 9.48528L16.5563 9.48528V0H7.07107Z"
        fill="#FC5C65"
        {...preventTabbing}
      />
      <Path
        d="M3.53553 5.53553L3.53553 3.53553H13.0208V13.0208L11.0208 13.0208V5.53553L3.53553 5.53553Z"
        fill="#FC5C65"
        {...preventTabbing}
      />
      <Path
        d="M7.48528 9.07107H2.52881e-07L0 7.07107L9.48528 7.07107L9.48528 16.5563H7.48528V9.07107Z"
        fill="#FC5C65"
        {...preventTabbing}
      />
    </Svg>
  );
};

const mapDispatch = {
  selectAWorkout: selectAWorkout,
};

export default connect(null, mapDispatch)(Workout);
