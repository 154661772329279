import * as React from "react";
import { StyleSheet, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

type Props = {
  progress: number;
  isResting: boolean;
};

const ProgressBar = ({ progress, isResting }: Props) => {
  const backgroundColor = !isResting ? "#1C2946" : "#241057";

  let startColor = !isResting ? "#0A6D75" : "#6442A1";
  let endColor = !isResting ? "#3C997C" : "#A28FFF";

  const styles = StyleSheet.create({
    cont: {
      backgroundColor: backgroundColor, // TODO Animate
      width: "100%",
    },
    inner: {
      height: 25,
      width: `${progress * 100}%`,
    },
  });

  return (
    <View style={styles.cont}>
      <LinearGradient
        start={[0, 1]}
        style={styles.inner}
        colors={[startColor, endColor]}
      />
    </View>
  );
};

export default ProgressBar;
