import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import { popularWorkoutsModalToggle } from "store/actions";
import breakPoint from "store/computed/breakPoints";
import PopularWorkoutsModal from "./popular-workouts-modal";

const mapState = (state: state) => {
  const { popularWorkoutsModalIsOpen, width } = state;
  return {
    breakPoint: breakPoint(state),
    popularWorkoutsModalIsOpen,
    setModalMinWidth: width > 900,
  };
};

const mapDispatch = {
  popularWorkoutsModalToggle,
};

export default connect(mapState, mapDispatch)(PopularWorkoutsModal);
