import { socialShareModalToggleArgs } from "store/types";

import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import CONST from "components/CONST";

import ShareIcon from "components/global/components/icons/share-icon";

type Props = {
  socialShareModalToggle: (arg0: socialShareModalToggleArgs) => void;
};

const ShareWorkoutBtn = ({ socialShareModalToggle }: Props) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#2E2B59",
      borderRadius: CONST.buttonBorderRadius,
      padding: 14,
    },
    iconContainer: {
      width: 24,
      marginRight: 12,
    },
    text: {
      fontFamily: CONST.fonts.latoBold,
      fontSize: 18,
      color: "white",
    },
  });

  return (
    <TouchableOpacity
      onPress={() => socialShareModalToggle({ isOpen: true })}
      style={[styles.container]}
    >
      <View style={styles.iconContainer}>
        <ShareIcon fullWidth={true} />
      </View>
      <Text style={styles.text}>Share Workout</Text>
    </TouchableOpacity>
  );
};

export default ShareWorkoutBtn;
