import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import {
  routineCurrent,
  totalNumberOfExercises,
} from "store/computed/calculateRoutine";
import ExerciseNumber from "./exercise-number";

const mapState = (state: state) => {
  const { exerciseWithinRoutineIndex } = routineCurrent(state);

  return {
    exerciseWithinRoutineIndex,
    totalNumberOfExercises: totalNumberOfExercises(state),
  };
};

export default connect(mapState)(ExerciseNumber);
