import {
  state,
  InputCustomExercise,
  InputCustomRest,
  RoutineLength,
} from "store/types";
import {
  Activities,
  RoutineActivity,
  CalculatedRoutineType,
} from "application/types";

function calculateHiitWorkout({
  rounds,
  secondsExercise,
  secondsRest,
  exercises: exercisesDirty,
}: state["inputsHiit"]): CalculatedRoutineType {
  // TODO Refactor to hold on to the ID?
  const exercises = exercisesDirty
    .map(item => item.text)
    .filter(item => {
      return item.length > 0;
    });

  let routineArray = [];

  let roundIndex = 0;
  let exerciseWithinRoundIndex = 0;
  let secondsExerciseIndex = 0;
  let secondsRestIndex = 0;

  // Loop over each round
  while (roundIndex < rounds) {
    // Loop over each exercise
    while (exerciseWithinRoundIndex < exercises.length) {
      const activity = exercises[exerciseWithinRoundIndex];
      // Loop over each second for an exercise
      while (secondsExerciseIndex < secondsExercise) {
        const nextActivity = (() => {
          const isLastExerciseInRound =
            exerciseWithinRoundIndex === exercises.length - 1;
          const isLastRound = roundIndex === rounds - 1;
          // If we've reached the last exercise and round
          if (isLastExerciseInRound && isLastRound) {
            return Activities.FINISH;
          }
          // If the routine includes rest between exercises
          if (secondsRest > 0) {
            return Activities.REST;
          }
          // Return the next exercise:
          // 1: If there is another exercise left in this round
          if (exerciseWithinRoundIndex + 1 < exercises.length) {
            return exercises[exerciseWithinRoundIndex + 1];
          }
          // 2: If no exercise left in this round, then get the first exercise (from the next round)
          return exercises[0];
        })();
        routineArray.push({
          activity,
          nextActivity,
          roundIndex,
          exerciseWithinRoundIndex: exerciseWithinRoundIndex,
          exerciseWithinRoutineIndex:
            roundIndex * exercises.length + exerciseWithinRoundIndex,
          activityDuration: secondsExercise,
          activityDurationIndex: secondsExerciseIndex,
          elapsedRoutineLength: {
            // Calculations for HIIT workouts are always accurate
            isAccurate: true,
            seconds: routineArray.length,
          },
        });
        secondsExerciseIndex++;
      }
      secondsExerciseIndex = 0;
      if (
        roundIndex === rounds - 1 &&
        exerciseWithinRoundIndex === exercises.length - 1
      ) {
        // We've finished
      } else {
        // Loop over each second for a rest
        while (secondsRestIndex < secondsRest) {
          const nextActivity =
            exercises[exerciseWithinRoundIndex + 1] || exercises[0];
          routineArray.push({
            activity: Activities.REST,
            nextActivity,
            roundIndex,
            exerciseWithinRoundIndex: exerciseWithinRoundIndex,
            exerciseWithinRoutineIndex:
              roundIndex * exercises.length + exerciseWithinRoundIndex,
            activityDuration: secondsRest,
            activityDurationIndex: secondsRestIndex,
            elapsedRoutineLength: {
              // Calculations for HIIT workouts are always accurate
              isAccurate: true,
              seconds: routineArray.length,
            },
          });
          secondsRestIndex++;
        }
        secondsRestIndex = 0;
      }
      exerciseWithinRoundIndex++;
    }
    exerciseWithinRoundIndex = 0;
    roundIndex++;
  }

  return routineArray;
}

export default function calculateRoutine(
  // Usually we'll pass the whole state here,
  // but sometimes (eg. in tests) we may not want to mock
  // whole state, only what we actually need.
  state: Pick<state, "workoutType" | "inputsCustom" | "inputsHiit">,
): CalculatedRoutineType {
  if (state.workoutType === "Custom") {
    return calculateCustomWorkout(state.inputsCustom);
  }
  return calculateHiitWorkout(state.inputsHiit);
}

function momentOfCustomWorkoutActivity(
  activity: string | InputCustomExercise | InputCustomRest,
): RoutineActivity {
  if (typeof activity === "string") {
    return activity;
  }

  if (activity.isRest) {
    return Activities.REST;
  }

  const { name, weight, repeat } = activity;

  return {
    name,
    weight,
    repeat,
  };
}

function calculateCustomWorkout({
  rounds,
  rest: restBetweenRounds,
  routine: routineDirty,
}: state["inputsCustom"]): CalculatedRoutineType {
  const routine = routineDirty.filter(item => {
    if (item.isRest) {
      return item.time > 0;
    } else {
      const isNameValid = item.name.length > 0;
      const shouldBeExecuted =
        item.repeat.format === "max" || item.repeat.amount > 0;
      return isNameValid && shouldBeExecuted;
    }
  });

  let routineArray: CalculatedRoutineType = [];
  const numberOfExercisesInRoutine = routine.filter(item => !item.isRest)
    .length;

  const isAnyExerciseManuallySkipped = routine.some(
    // Rests are time-based
    item => !item.isRest && item.repeat.format !== "time",
  );
  // If there's any non-time-based exercise,
  // the calculation will not be accurate.
  // We decide that isAccurate should be
  // the same for all moments of a particular workout.
  // (Elapsed time for exercises before an at-will exercise should
  // be displayed as inaccurate too.)
  const isElapsedSecondsCalculatedAccurate = !isAnyExerciseManuallySkipped;
  let elapsedSeconds = 0;

  // Loop over each round
  for (let roundIndex = 0; roundIndex < rounds; roundIndex += 1) {
    let exerciseWithinRoundIndex = 0;
    let pendingExerciseWithinRoundIndexIncrease = false;
    // Loop over each activity
    for (
      let activityWithinRoundIndex = 0;
      activityWithinRoundIndex < routine.length;
      activityWithinRoundIndex += 1
    ) {
      const activity = routine[activityWithinRoundIndex];
      if (pendingExerciseWithinRoundIndexIncrease && !activity.isRest) {
        pendingExerciseWithinRoundIndexIncrease = false;
        exerciseWithinRoundIndex += 1;
      }
      const exerciseWithinRoutineIndex =
        roundIndex * numberOfExercisesInRoutine + exerciseWithinRoundIndex;
      const nextActivity = (() => {
        const isLastExerciseInRound =
          activityWithinRoundIndex === routine.length - 1;
        const isLastRound = roundIndex === rounds - 1;
        // If we've reached the last exercise and round
        if (isLastExerciseInRound && isLastRound) {
          return Activities.FINISH;
        }
        // If the routine includes rest between exercises
        // and there should be more than one round
        // and this is the last exercise of the round
        if (restBetweenRounds > 0 && rounds > 0 && isLastExerciseInRound) {
          return Activities.REST;
        }
        // Return the next exercise:
        // 1: If there is another exercise left in this round
        if (activityWithinRoundIndex + 1 < routine.length) {
          return routine[activityWithinRoundIndex + 1];
        }
        // 2: If no exercise left in this round, then get the first exercise (from the next round)
        // (there must be also no rest between rounds for this code to go straight to the first activity,
        // otherwise we'd return Activities.REST early a couple of lines above).
        return routine[0];
      })();
      if (activity.isRest || activity.repeat.format === "time") {
        const secondsActivity = activity.isRest
          ? activity.time
          : activity.repeat.amount;
        // Loop over each second for an exercise
        for (
          let secondsActivityIndex = 0;
          secondsActivityIndex < secondsActivity;
          secondsActivityIndex += 1
        ) {
          routineArray.push({
            activity: momentOfCustomWorkoutActivity(activity),
            nextActivity: momentOfCustomWorkoutActivity(nextActivity),
            roundIndex,
            exerciseWithinRoundIndex,
            exerciseWithinRoutineIndex,
            activityDuration: secondsActivity,
            activityDurationIndex: secondsActivityIndex,
            elapsedRoutineLength: {
              seconds: elapsedSeconds,
              isAccurate: isElapsedSecondsCalculatedAccurate,
            },
          });
          elapsedSeconds += 1;
        }
      } else {
        // max or reps
        routineArray.push({
          activity: momentOfCustomWorkoutActivity(activity),
          nextActivity: momentOfCustomWorkoutActivity(nextActivity),
          roundIndex,
          exerciseWithinRoundIndex,
          exerciseWithinRoutineIndex,
          activityDuration: "at-will",
          activityDurationIndex: 0,
          elapsedRoutineLength: {
            seconds: elapsedSeconds,
            isAccurate: isElapsedSecondsCalculatedAccurate,
          },
        });
        // Not increasing elapsedSeconds as "at-will"
        // exercises have no notion of elapsed time.
      }

      if (!activity.isRest) {
        pendingExerciseWithinRoundIndexIncrease = true;
      }
    }

    // If this was not the last round
    if (roundIndex < rounds - 1) {
      const nextActivity =
        routine[0] ??
        // If next round will be the last one (i.e. there will be no rest after it)
        (roundIndex + 1 === rounds - 1 ? Activities.FINISH : Activities.REST);
      // Loop over each second of the rest between rounds
      for (
        let secondsRestIndex = 0;
        secondsRestIndex < restBetweenRounds;
        secondsRestIndex += 1
      ) {
        const exerciseWithinRoutineIndex =
          roundIndex * numberOfExercisesInRoutine + exerciseWithinRoundIndex;
        routineArray.push({
          activity: Activities.REST,
          nextActivity: momentOfCustomWorkoutActivity(nextActivity),
          roundIndex,
          exerciseWithinRoundIndex,
          exerciseWithinRoutineIndex,
          activityDuration: restBetweenRounds,
          activityDurationIndex: secondsRestIndex,
          elapsedRoutineLength: {
            seconds: elapsedSeconds,
            isAccurate: isElapsedSecondsCalculatedAccurate,
          },
        });
        elapsedSeconds += 1;
      }
    }
  }

  return routineArray;
}
