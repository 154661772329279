import * as React from "react";
import { connect } from "react-redux";
import { state } from "store/types";
import breakPoint from "store/computed/breakPoints";
import About from "./about";

const mapState = (state: state) => {
  return {
    breakPoint: breakPoint(state),
    breakPointSmall: state.width > 360,
  };
};

export default connect(mapState)(About);
