import * as React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSafeArea } from "react-native-safe-area-context";

import CONST from "components/CONST";
import CloseIcon from "components/global/components/icons/close-icon";

type Props = {
  children: React.ReactNode;
  breakPoint: boolean;
  close?: () => void;
  heading: string;
  height: number;
};

const ModalInner = ({
  heading,
  close,
  children,
  breakPoint,
  height,
}: Props) => {
  const safeArea = useSafeArea();

  const styles = StyleSheet.create({
    content: {
      padding: 20,
      paddingBottom: breakPoint ? safeArea.bottom + 20 : safeArea.bottom + 20,
      borderTopLeftRadius: 24,
      borderTopRightRadius: 24,
      borderBottomLeftRadius: breakPoint ? 24 : 0,
      borderBottomRightRadius: breakPoint ? 24 : 0,
      marginTop: "auto",
      marginBottom: breakPoint ? "auto" : 0,
      margin: breakPoint ? "auto" : 0,
      backgroundColor: "#F4F6F5",
      maxHeight: 0.9 * height,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 20,
      marginBottom: 20,
      borderBottomColor: "#E5E7E7",
      borderBottomWidth: 1,
    },
    heading: {
      color: CONST.colorFontGrey,
      fontFamily: CONST.fonts.latoBold,
      fontSize: 30,
      textAlign: "center",
      flex: 1,
      marginRight: 10,
    },
  });
  return (
    <View style={styles.content}>
      <ScrollView keyboardShouldPersistTaps="handled">
        <View style={styles.header}>
          <Text style={styles.heading}>{heading}</Text>
          {close && (
            <TouchableOpacity
              accessibilityLabel="Close modal"
              onPress={() => close()}
            >
              <CloseIcon />
            </TouchableOpacity>
          )}
        </View>
        {children}
      </ScrollView>
    </View>
  );
};

export default ModalInner;
