import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import { customSetDurationFormat, customSetDurationValue } from "store/actions";
import DurationInput from "./duration-input";
import breakPoint from "store/computed/breakPoints";

const mapState = (state: state) => {
  const { width } = state;
  return {
    width,
    breakPoint: breakPoint(state),
  };
};

const mapDispatch = {
  customSetDurationFormat,
  customSetDurationValue,
};

export default connect(mapState, mapDispatch)(DurationInput);
