import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";

interface Props {
  size: number;
}

const TwitterIcon = ({ size }: Props) => {
  return (
    <Svg
      data-name="Reddit logo"
      viewBox="0 0 100 100"
      {...preventTabbing}
      width={size}
      height={size}
    >
      <Circle cx={50} cy={50} r={49} fill="#ff4500" {...preventTabbing} />
      <Path
        d="M52.69 18.79a1.62 1.62 0 011.93-1.22l13.24 2.93a5.24 5.24 0 11-.55 2.93l-12-2.66-4.07 17.5A37.77 37.77 0 0173 45.47a7.71 7.71 0 0111.83.93 7.53 7.53 0 01.41.73 8 8 0 01-3.6 10.7 18.67 18.67 0 01-8 17.5A39.59 39.59 0 0150 82.46a39.57 39.57 0 01-23.64-7.13 18.67 18.67 0 01-8-17.5 7.53 7.53 0 01-.73-.41 8 8 0 01-2.46-11 8.27 8.27 0 01.9-1.06 7.74 7.74 0 0110.93.11 37.78 37.78 0 0121.19-7.19zm4.39 37.4a5.3 5.3 0 105.3-5.3 5.29 5.29 0 00-5.3 5.3zm6.31 14.61a1.22 1.22 0 00-1.3-2 20.66 20.66 0 01-24.18 0 1.22 1.22 0 00-1.3 2 22.66 22.66 0 0026.78 0zM32.32 56.19a5.3 5.3 0 105.3-5.3 5.29 5.29 0 00-5.3 5.3z"
        fill="#fff"
        {...preventTabbing}
      />
    </Svg>
  );
};

export default TwitterIcon;
