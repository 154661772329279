import { createTransform } from "redux-persist";
import { state } from "../types";

// Persisted email subscription state
// is missing the `isBeingSubscribed` attribute.
type PersistedEmailSubscriptionsState = {
  [emailAddress: string]: {
    hasBeenSubscribedSuccessfully: boolean;
  };
};

// This transform removes `isBeingSubscribed` from persisted state.
//
// First argument - function - goes through all the email subscriptions
// of the live state and rewrites the object so that it contains only
// `hasBeenSubscribedSuccessfully` parameter - it doesn't make sense
// and would actually cause problems to persist information about
// whether a subscription request is in progress or not.
// Second argument goes through all the persisted subscriptions
// and adds the necessary isBeingSubscribed argument,
// always false (since we're rehydrating the state
// no request could have been made before).
//
// If we persisted this information, the automatically resubscribing
// hook wouldn't ever pick a subscription up for resubscription,
// thinking that something is already handling the task. What would happen:
// 1. User subscribes.
// 2. Subscription request is began, `isBeingSubscribed: true` is saved.
// 3. The app crashes.
// 4. User reopens the app. State is rehydrated with `isBeingSubscribed: true`
//    which is not true.
const EmailIsBeingSubscribedTransform = createTransform<
  state["emailSubscriptions"],
  PersistedEmailSubscriptionsState
>(
  // Transform state on its way to being serialized and persisted
  (liveState: state["emailSubscriptions"]) => {
    const persistedSubscriptionsState: PersistedEmailSubscriptionsState = {};
    for (let emailAddress of Object.keys(liveState)) {
      const emailSubscription = liveState[emailAddress];
      if (emailSubscription) {
        const { hasBeenSubscribedSuccessfully } = emailSubscription;
        persistedSubscriptionsState[emailAddress] = {
          hasBeenSubscribedSuccessfully,
        };
      }
    }
    return persistedSubscriptionsState;
  },
  // Transform state being rehydrated
  (persistedSubscriptionsState: PersistedEmailSubscriptionsState) => {
    const rehydratedSubscriptionsState: state["emailSubscriptions"] = {};
    for (let emailAddress of Object.keys(persistedSubscriptionsState)) {
      const persistedEmailSubscription =
        persistedSubscriptionsState[emailAddress];
      if (persistedEmailSubscription) {
        const { hasBeenSubscribedSuccessfully } = persistedEmailSubscription;
        rehydratedSubscriptionsState[emailAddress] = {
          hasBeenSubscribedSuccessfully,
          isBeingSubscribed: false,
        };
      }
    }
    return rehydratedSubscriptionsState;
  },
  // Define which reducers this transform gets called for
  { whitelist: ["emailSubscriptions"] },
);

export default EmailIsBeingSubscribedTransform;
