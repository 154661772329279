import { state } from "store/types";
import { CalculatedRoutineMoment } from "application/types";
import * as React from "react";
import { connect } from "react-redux";
import { routineCurrent as routineCurrentMemo } from "store/computed/calculateRoutine";
import breakPoint from "store/computed/breakPoints";

import UpNext from "./up-next";

const mapState = (state: state) => {
  const { warmUpIsFinished, paused } = state;
  const routineCurrent: CalculatedRoutineMoment = routineCurrentMemo(state);

  const nextActivity = (() => {
    // If the routine hasn't started yet then return the first exercise
    if (!warmUpIsFinished) {
      return routineCurrent.activity;
    }
    return routineCurrent.nextActivity;
  })();

  return {
    breakPoint: breakPoint(state),
    nextActivity,
    paused,
    warmUpIsFinished,
    currentActivityDuration: routineCurrent.activityDuration,
  };
};

export default connect(mapState)(UpNext);
