import { StyleSheet } from "react-native";
import CONST from "components/CONST";

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 16,
    backgroundColor: "white",
    borderRadius: 12,
    padding: 15,
    maxWidth: 500,
  },
  row: {
    flexDirection: "row",
    marginBottom: 10,
    flexWrap: "wrap",
  },
  col: {
    width: "33.33%",
  },
  row_routine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    // marginTop: 5,
    marginBottom: 15,
  },
  row_last: {
    marginBottom: 0,
    borderTopWidth: 1,
    borderStyle: "solid",
    borderColor: "#F3F2F5",
    paddingTop: 10,
  },
  cogIconWrapper: {
    marginLeft: 5,
    marginTop: 3,
  },
  heading: {
    flex: 1,
    flexWrap: "wrap",
    fontSize: 26,
    lineHeight: 26,
    fontFamily: CONST.fonts.latoBold,
    color: CONST.colorFontGrey,
  },
  heading_row: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#F3F2F5",
    marginBottom: 10,
    paddingBottom: 10,
  },
  rowTimes: {
    flexDirection: "row",
    marginBottom: 15,
    marginTop: 5,
  },
  workoutType: {
    fontSize: 18,
    color: "#877F98",
    marginLeft: 5,
    transform: [
      {
        translateY: 3,
      },
    ],
  },
  label: {
    fontSize: 18,
    fontFamily: CONST.fonts.latoRegular,
    marginRight: 10,
    color: CONST.colorFontGrey,
  },
  labelHIIT: {
    fontSize: 13,
    fontFamily: CONST.fonts.latoRegular,
    // marginRight: 10,
    color: "#A099AD",
    marginTop: 5,
  },
  value: {
    fontSize: 18,
    fontFamily: CONST.fonts.latoRegular,
    color: CONST.colorFontGrey,
  },

  // value_routine: {
  //   display: "flex",
  //   alignItems: "center",
  //   fontSize: 16,
  //   fontFamily: CONST.fonts.latoRegular,
  //   color: CONST.colorFontGrey,
  // },
  valueRoutineWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  value_routine_item: {
    // marginRight: 10,
    fontSize: 16,
    fontFamily: CONST.fonts.latoRegular,
    color: CONST.colorFontGrey,
    marginBottom: 10,
  },
  routineItemSeparator: {
    display: "flex",
    backgroundColor: "#A6A0B2",
    width: 4,
    height: 4,
    borderRadius: 4,
    marginHorizontal: 10,
    marginBottom: 10,
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: CONST.buttonBorderRadius,
  },
  button__secondary: {
    backgroundColor: "#E8E6EB",
    minHeight: 47,
    minWidth: 47,
    marginRight: 10,
  },
  button__go: {
    backgroundColor: CONST.colorChooseCtaGreen,
    flexGrow: 1,
  },
  buttonText_go: {
    color: "white",
  },

  buttonText: {
    color: CONST.colorDarkBlueBackground,
    textTransform: "uppercase",
    fontFamily: CONST.fonts.latoBold,
    fontSize: 14,
    padding: 15,
    textAlign: "center",
  },
});

export default styles;
