import { StyleSheet } from "react-native";

const WIDTH_OF_HANDLE = 40;
export const VERTICAL_PADDING = 15;

const styles = StyleSheet.create({
  itemWrapper: {
    marginBottom: VERTICAL_PADDING,
  },
  invisibleHandle: {
    // backgroundColor:
    //   Platform.OS === "web" ? "rgba(100,0,0,.5)" : "rgba(50,50,0,.5)",
    position: "absolute",
    top: 0,
    // bottom: Platform.OS === "web" ? 0 : VERTICAL_PADDING,
    bottom: 0,
    left: 20,
    width: WIDTH_OF_HANDLE,
    elevation: 1,
    zIndex: 1,
  },
});

export default styles;
