import { Pages } from "application/types";

import React from "react";
import { useHistory } from "react-router-dom";

const useCurrentPage = () => {
  const history = useHistory();
  const pageUrl = history.location.pathname.replace(/^\//, "");
  if (pageUrl === "") {
    return Pages.choose;
  }
  return pageUrl;
};

export default useCurrentPage;
