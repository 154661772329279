import { setDimensionsArgs } from "store/types";
import * as React from "react";
import { Dimensions, Platform, ScaledSize } from "react-native";
import Constants from "expo-constants";
import throttle from "lodash/throttle";

type Props = {
  setDimensionsAction: (arg0: setDimensionsArgs) => void;
};

function getRootViewHeight(dimensions: {
  window: ScaledSize;
  screen: ScaledSize;
}) {
  // Hotfix for React Native problem with window height of Android devices with notches
  // https://github.com/facebook/react-native/issues/23693#issuecomment-571184297
  // Without this, height is a notch short and white space is shown beneath the footer.
  return Platform.OS !== "ios" &&
    dimensions.screen.height !== dimensions.window.height &&
    Constants.statusBarHeight > 24
    ? dimensions.screen.height - Constants.statusBarHeight + 1 // I hate this +1, but without it we still have 1 pixel of white space
    : dimensions.window.height;
}

const ScreenSize = ({ setDimensionsAction }: Props): null => {
  React.useEffect(() => {
    const dimensions = {
      screen: Dimensions.get("screen"),
      window: Dimensions.get("window"),
    };

    const height = getRootViewHeight(dimensions);

    setDimensionsAction({
      width: dimensions.window.width,
      height,
      isLandscape: dimensions.window.width > height,
    });

    const throttleSetDimensions = throttle(e => {
      const height = getRootViewHeight(e);
      setDimensionsAction({
        width: e.window.width,
        height,
        isLandscape: e.window.width > height,
      });
    }, 100);

    Dimensions.addEventListener("change", throttleSetDimensions);
  }, []);

  return null;
};

export default ScreenSize;
