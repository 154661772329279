import { state } from "application/store/types";
import {
  Activities,
  CalculatedRoutineMoment,
  CalculatedRoutineType,
} from "application/types";
import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";

import calculateRoutineUtil from "store/utils/calculateRoutineUtil";
import calculateRoutineLength from "store/utils/calculateRoutineUtil/routineLength";

const getRoutineTimeIndex = (state: state) => state.routineTimeIndex;

const calculateRoutine = (state: state): CalculatedRoutineType => {
  return calculateRoutineUtil(state);
};

export const hasFinishedRoutine = createSelector(
  [calculateRoutine, getRoutineTimeIndex],
  (routine, routineTimeIndex) => {
    if (routineTimeIndex + 1 > routine.length) return true;
    if (routineTimeIndex + 1 === routine.length) {
      return routine[routine.length - 1].activityDuration !== "at-will";
    }
    return false;
  },
);

export const routineLength = (state: state) =>
  calculateRoutineLength(
    state.workoutType === "Custom" ? state.inputsCustom : state.inputsHiit,
  );

export const isResting = createSelector(
  [calculateRoutine, getRoutineTimeIndex],
  (routine, routineTimeIndex): boolean => {
    const { activity } = routine[routineTimeIndex];
    return activity === Activities.REST;
  },
);

export const routineCurrent = createSelector(
  [calculateRoutine, getRoutineTimeIndex],
  (routine, routineTimeIndex): CalculatedRoutineMoment => {
    return routine[routineTimeIndex];
  },
);

export const indexNextRoutineItem = createSelector(
  [calculateRoutine, getRoutineTimeIndex],
  (routine, routineTimeIndex): number => {
    const currentActivity = routine[routineTimeIndex].activity;
    let indexNextRoutineItemRes = routineTimeIndex;
    const haveReachedNext = () => {
      const nextActivity = routine[indexNextRoutineItemRes]
        ? routine[indexNextRoutineItemRes].activity
        : Activities.FINISH;
      return !isEqual(nextActivity, currentActivity);
    };
    while (!haveReachedNext()) {
      indexNextRoutineItemRes++;
    }
    return indexNextRoutineItemRes;
  },
);

export const indexPrevRoutineItem = createSelector(
  [calculateRoutine, getRoutineTimeIndex],
  (routine, routineTimeIndex): number => {
    const currentActivity = routine[routineTimeIndex].activity;
    let indexPrevRoutineItemRes = routineTimeIndex;

    const haveReachedPrev = () => {
      const prevActivity = routine[indexPrevRoutineItemRes].activity;
      if (indexPrevRoutineItemRes === 0) {
        return true;
      }
      return !isEqual(prevActivity, currentActivity);
    };

    while (!haveReachedPrev()) {
      indexPrevRoutineItemRes--;
    }
    return indexPrevRoutineItemRes;
  },
);

export const totalNumberOfExercises = (state: state): number => {
  if (state.workoutType === "Custom") {
    const inputs = state.inputsCustom;
    return (
      inputs.rounds *
      inputs.routine.filter(
        item =>
          !item.isRest &&
          item.name.length > 0 &&
          (item.repeat.format === "max" || item.repeat.amount > 0),
      ).length
    );
  }
  const inputs = state.inputsHiit;
  const noExercises = inputs.exercises.filter(item => item.text).length;
  const { rounds } = inputs;
  return noExercises * rounds;
};

export default calculateRoutine;
