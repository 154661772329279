import { popularWorkoutsModalToggleArgs } from "store/types";

import * as React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import IconStar from "components/global/components/icons/star";

import CONST from "components/CONST";

type Props = {
  emphasise: boolean;
  popularWorkoutsModalToggle: (arg0: popularWorkoutsModalToggleArgs) => void;
};

const PopularWorkoutsButton = ({
  popularWorkoutsModalToggle,
  emphasise,
}: Props) => {
  const styles = StyleSheet.create({
    gradient: {
      borderRadius: CONST.buttonBorderRadius,
      flexDirection: emphasise ? "row-reverse" : "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: emphasise ? 24 : 16,
      paddingVertical: emphasise ? 16 : 16,
    },
    text: {
      color: "white",
      textTransform: "uppercase",
      fontFamily: CONST.fonts.latoBold,
      marginRight: emphasise ? 0 : 10,
      marginLeft: emphasise ? 10 : 0,
      fontSize: emphasise ? 15 : 12,
    },
  });

  return (
    <TouchableOpacity
      onPress={() => popularWorkoutsModalToggle({ isOpen: true })}
    >
      <LinearGradient
        style={styles.gradient}
        colors={
          emphasise
            ? ["#8673DF", "#865DC9"]
            : [CONST.colorBtnSecondary, CONST.colorBtnSecondary]
        }
        start={[0, 0]}
        end={[1, 0]}
      >
        <Text style={styles.text}>Popular workouts</Text>
        <IconStar />
      </LinearGradient>
    </TouchableOpacity>
  );
};

export default PopularWorkoutsButton;
