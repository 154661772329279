import { state } from "store/types";
import { InputNumericTypes } from "application/types";
import { connect } from "react-redux";
import InputNumeric from "../input-numeric/input-numeric";
import { setRounds } from "store/actions";
import breakPoint from "store/computed/breakPoints";

const mapState = (state: state) => {
  const { rounds } = state.inputsHiit;
  return {
    value: rounds,
    type: InputNumericTypes.ROUNDS,
    increment: 1,
    title: "Rounds",
    breakPoint: breakPoint(state),
  };
};

const mapDispatch = {
  set: setRounds,
};

export default connect(mapState, mapDispatch)(InputNumeric);
