import { connect } from "react-redux";
import WorkoutHIIT from "./WorkoutHIIT";
import {
  deleteWorkout,
  selectAWorkout,
  moveSavedWorkoutUp,
  moveSavedWorkoutDown,
} from "store/actions";

const mapDispatch = {
  selectAWorkout,
  deleteWorkout,
  moveSavedWorkoutUp,
  moveSavedWorkoutDown,
};

export default connect(null, mapDispatch)(WorkoutHIIT);
