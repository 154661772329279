import * as React from "react";
import { oneSecondInMilliseconds } from "store/initState";

type Props = {
  countdownTick: () => void;
  paused: boolean;
};

const CountdownTickInvisible = ({ countdownTick, paused }: Props) => {
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        countdownTick();
      }
    }, oneSecondInMilliseconds);
    return () => clearInterval(interval);
  }, [paused]);

  return null;
};

export default CountdownTickInvisible;
