import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import { saveWorkoutModalToggle } from "store/actions";
import SaveWorkout from "./saveWorkout";
import breakPoint from "store/computed/breakPoints";

const mapState = (state: state) => {
  return {
    breakPoint: breakPoint(state),
  };
};

const mapDispatch = {
  saveWorkoutModalToggle,
  // deleteCurrentWorkout,
};

export default connect(mapState, mapDispatch)(SaveWorkout);
