import React from "react";
import { Helmet } from "react-helmet"; // TODO Remove if using NextJS

const HelmetMetaTags = () => {
  const url = window.location.origin;
  return (
    <Helmet>
      <meta property="og:image" content={`${url}/og-image.png`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@love_hiit" />
      {/*<meta name="twitter:creator" content="@jchetters" />*/}
      <meta name="twitter:title" content="Love HIIT" />
      <meta name="twitter:description" content="Social HIIT timer" />
      <meta name="twitter:image" content={`${url}/og-image.png`} />
    </Helmet>
  );
};

export default HelmetMetaTags;
