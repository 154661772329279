import { state } from "store/types";
import { connect } from "react-redux";
import { reorderExercises } from "store/actions";
import DragContainer from "./drag-container";

const mapState = (state: state) => {
  const { exercises } = state.inputsHiit;
  return {
    exercises,
  };
};

const mapDispatch = {
  reorderExercises,
};

export default connect(mapState, mapDispatch, null, { forwardRef: true })(
  DragContainer,
);
