import { state } from "store/types";
import { InputNumericTypes } from "application/types";
import { connect } from "react-redux";
import InputNumeric from "../input-numeric/input-numeric";
import { setSecondsExercise } from "store/actions";
import breakPoint from "store/computed/breakPoints";
// import { Props } from "../input-numeric-container";

const mapState = (state: state) => {
  const { secondsExercise } = state.inputsHiit;
  return {
    value: secondsExercise,
    type: InputNumericTypes.EXERCISE,
    increment: 5,
    title: "Exercise",
    breakPoint: breakPoint(state),
  };
};

const mapDispatch = {
  set: setSecondsExercise,
};

export default connect(mapState, mapDispatch)(InputNumeric);
