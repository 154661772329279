import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { preventTabbing } from "application/components/CONST";
import CONST from "components/CONST";

const IconBin = () => {
  return (
    <Svg
      width={20}
      height={22}
      viewBox="0 0 20 22"
      fill="none"
      {...preventTabbing}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0h6a2 2 0 012 2v1h3a2 2 0 012 2v2a2 2 0 01-2 2h-.08L17 20a2 2 0 01-2 2H5c-1.105 0-2-.895-1.997-1.917L2.08 9H2a2 2 0 01-2-2V5a2 2 0 012-2h3V2a2 2 0 012-2zM2 5h16v2H2V5zm2.086 4h11.827l-.91 10.917L15 20H5L4.086 9zM13 2v1H7V2h6z"
        fill={CONST.colorDarkBlueBackground}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconBin;
