import { state } from "store/types";

import * as React from "react";
import { connect } from "react-redux";

import { isResting, routineCurrent } from "store/computed/calculateRoutine";
import { togglePause } from "store/actions";
import breakPoint from "store/computed/breakPoints";

import Timer from "./timer";

const mapState = (state: state) => {
  const {
    // width, height,
    warmUpIsFinished,
    warmUpTimeIndex,
    paused,
  } = state;
  return {
    // TODO Remove unnecessary fields?
    routineCurrent: routineCurrent(state),
    isResting: isResting(state),
    // width,
    // height,
    warmUpIsFinished,
    warmUpTimeIndex,
    breakPoint: breakPoint(state),
    paused,
  };
};

const mapDispatch = {
  togglePause,
};

export default connect(mapState, mapDispatch)(Timer);
