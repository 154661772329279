import { CalculatedRoutineMoment } from "application/types";
import { warmUpTimeStartIndex } from "store/initState";

import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Svg, { Circle } from "react-native-svg";
// @ts-ignore
import pointsOnCircle from "points-on-circle";
import interpolate from "color-interpolate";

import { preventTabbing } from "application/components/CONST";
import CONST from "components/CONST";

type Props = {
  routineCurrent: CalculatedRoutineMoment;
  togglePause: () => void;
  warmUpIsFinished: boolean;
  isResting: boolean;
  warmUpTimeIndex: number;
  breakPoint: boolean;
  paused: boolean;
};

const Timer = ({
  routineCurrent,
  togglePause,
  warmUpIsFinished,
  warmUpTimeIndex,
  isResting,
  breakPoint,
  paused,
}: Props) => {
  const DOT_RADIUS = breakPoint ? 10 : 5;
  const CONTAINER_SIZE = breakPoint ? 320 : 200;
  const NUMBER_POINTS = 400;

  const backgroundSizeIncrease = breakPoint ? 570 : 270;
  const { activity, activityDuration, activityDurationIndex } = routineCurrent;
  // Either
  // - current activity must not be an at-will or
  // - the warmup must not be finished
  // for the pause to be considered active.
  const actsAsIfCurrentActivityIsAtWill =
    activityDuration === "at-will" && warmUpIsFinished;
  const actsAsPaused = paused && !actsAsIfCurrentActivityIsAtWill;

  const countDownNumber = () => {
    if (!warmUpIsFinished) {
      return warmUpTimeIndex;
    }
    if (activityDuration === "at-will") {
      return null;
    }
    return activityDuration - activityDurationIndex;
  };

  const ratio = (() => {
    if (!warmUpIsFinished) {
      return (warmUpTimeStartIndex - warmUpTimeIndex) / warmUpTimeStartIndex;
    }
    if (activityDuration === "at-will") {
      return 0;
    }
    return activityDurationIndex / activityDuration;
  })();

  const CONT_RADIUS = CONTAINER_SIZE / 2;
  const SVG_SIZE = CONT_RADIUS * 2;
  const CONT_INNER_RADIUS = CONT_RADIUS - DOT_RADIUS;

  const points = pointsOnCircle(
    NUMBER_POINTS,
    CONT_INNER_RADIUS,
    CONT_INNER_RADIUS,
    CONT_INNER_RADIUS,
  );

  // TODO Make into animation
  const circularProgressColorMap = (() => {
    if (!warmUpIsFinished) {
      return interpolate(["#325DC5", "#50BEFD"]);
    } else if (!isResting) {
      return interpolate(["#0D9D8F", "#44CD98"]);
    } else {
      return interpolate(["#865DC9", "#A28FFF"]);
    }
  })();

  let centerOfCircleBackground;
  if (!warmUpIsFinished) {
    centerOfCircleBackground = CONST.workoutColors.backgroundWarmUp;
  } else if (!isResting) {
    centerOfCircleBackground = CONST.workoutColors.backgroundActive;
  } else {
    centerOfCircleBackground = CONST.workoutColors.backgroundRest;
  }

  let outerCircleBackground;
  if (!warmUpIsFinished) {
    outerCircleBackground = CONST.workoutColors.circleWarmUp;
  } else if (!isResting) {
    outerCircleBackground = CONST.workoutColors.circleActive;
  } else {
    outerCircleBackground = CONST.workoutColors.circleRest;
  }

  let backgroundProgressIndicatorBackground;
  if (!warmUpIsFinished) {
    backgroundProgressIndicatorBackground =
      CONST.workoutColors.backgroundProgressIndicatorWarmUp;
  } else if (!isResting) {
    backgroundProgressIndicatorBackground =
      CONST.workoutColors.backgroundProgressIndicatorActive;
  } else {
    backgroundProgressIndicatorBackground =
      CONST.workoutColors.backgroundProgressIndicatorRest;
  }

  const styles = StyleSheet.create({
    container: {
      opacity: actsAsPaused ? 0.5 : undefined,
    },
    main: {
      position: "relative",
      width: CONTAINER_SIZE,
      height: CONTAINER_SIZE,
      justifyContent: "center",
      alignItems: "center",
    },
    time: {
      color: "white",
      zIndex: 0,
      fontSize: breakPoint ? 116 : 72,
      lineHeight: breakPoint ? 116 : 72,
    },
    activity: {
      color: "white",
      zIndex: 0,
      textAlign: "center",
      paddingHorizontal: 10,
    },
    name: {
      fontSize: breakPoint ? 58 : 38,
      lineHeight: breakPoint ? 88 : 58,
    },
    // weight: {
    //   fontSize: breakPoint ? 40 : 26,
    //   lineHeight: breakPoint ? 40 : 26,
    //   marginBottom: breakPoint ? 10 : 5,
    // },
    repeat: {
      fontSize: breakPoint ? 34 : 22,
      lineHeight: breakPoint ? 34 : 22,
    },
    svg: {
      position: "absolute",
      left: 0,
      top: 0,
      height: SVG_SIZE,
      width: SVG_SIZE,
      transform: [{ rotate: "-90deg" }],
    },
    backgroundProgressIndicator: {
      position: "absolute",
      left: 0,
      top: 0,
      height: SVG_SIZE,
      width: SVG_SIZE,
      borderRadius: SVG_SIZE,
      borderWidth: DOT_RADIUS * 2,
      borderColor: backgroundProgressIndicatorBackground,
      backgroundColor: centerOfCircleBackground,
    },
    background: {
      position: "absolute",
      backgroundColor: outerCircleBackground, // TODO Make into animation
      width: CONTAINER_SIZE + backgroundSizeIncrease,
      height: CONTAINER_SIZE + backgroundSizeIncrease,
      zIndex: -1,
      top: -(0.5 * backgroundSizeIncrease),
      left: -(0.5 * backgroundSizeIncrease),
      borderRadius: CONTAINER_SIZE + backgroundSizeIncrease,
      overflow: "hidden",
    },
  });

  const activityName = React.useMemo(() => {
    if (activityDuration !== "at-will") {
      // Countdown number will be rendered
      return null;
    }
    if (typeof activity === "string") {
      return <Text style={[styles.activity, styles.name]}>{activity}</Text>;
    }

    const repeatTime =
      // We can only check for max/reps since if repeat.format is time
      // activityDuration wouldn't be "at-will".
      activity.repeat.format === "max"
        ? "Maximum"
        : `${activity.repeat.amount} ${activity.repeat.format}`;

    return (
      <View>
        {/*{activity.weight.amount > 0 ? (*/}
        {/*  <Text style={[styles.activity, styles.weight]}>*/}
        {/*    {activity.weight.amount}*/}
        {/*    {activity.weight.format}*/}
        {/*  </Text>*/}
        {/*) : null}*/}

        <Text style={[styles.activity, styles.repeat]}>{repeatTime}</Text>
      </View>
    );
  }, [activity]);

  return (
    <>
      <View style={styles.background} />
      <View style={styles.container}>
        <TouchableOpacity
          disabled={actsAsIfCurrentActivityIsAtWill}
          onPress={() => togglePause()}
          style={styles.main}
        >
          <View style={styles.backgroundProgressIndicator} />
          <Svg style={styles.svg} {...preventTabbing}>
            {points.map((item: any, i: number) => {
              const nonZeroIndex = i + 1;
              const fraction = nonZeroIndex / NUMBER_POINTS;
              const show = nonZeroIndex < NUMBER_POINTS * ratio;
              if (!show) {
                return null;
              }
              return (
                <Circle
                  key={i}
                  cx={item.x + DOT_RADIUS}
                  cy={item.y + DOT_RADIUS}
                  r={DOT_RADIUS}
                  fill={circularProgressColorMap(fraction)}
                  {...preventTabbing}
                />
              );
            })}
          </Svg>
          {actsAsIfCurrentActivityIsAtWill ? (
            activityName
          ) : (
            <Text style={styles.time}>{countDownNumber()}</Text>
          )}
        </TouchableOpacity>
      </View>
    </>
  );
};

export default Timer;
