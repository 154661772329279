import { state } from "store/types";
import { connect } from "react-redux";
import GoPage from "./go-page";

const mapDispatch = (state: state) => {
  const { routineIsFinished } = state;
  return {
    routineIsFinished,
  };
};

export default connect(mapDispatch)(GoPage);
