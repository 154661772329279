import * as React from "react";
import Modal from "components/global/components/modal";
import {
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  View,
} from "react-native";
import breakPointMemo from "store/computed/breakPoints";
import { useDispatch, useSelector } from "react-redux";
import { renameSavedWorkout } from "store/actions";
import CONST from "components/CONST";

interface Props {
  close: () => void;
  name: string;
  index: number;
}

const EditNameModal = ({ close, name, index }: Props) => {
  const dispatch = useDispatch();
  const [newName, setNewName] = React.useState(name);
  const breakPoint = useSelector(breakPointMemo);

  const handleSave = () => {
    if (newName.length === 0) return alert("A saved workout must have a name");
    dispatch(renameSavedWorkout({ index, name: newName }));
    close();
  };

  const styles = StyleSheet.create({
    row: {
      flexDirection: "row",
    },
    textInput: {
      borderColor: "#B0A9BA",
      borderWidth: 1,
      borderStyle: "solid",
      padding: 10,
      borderRadius: CONST.buttonBorderRadius,
      marginRight: 8,
      minWidth: breakPoint ? 240 : undefined,
      flexGrow: breakPoint ? undefined : 1,
      backgroundColor: "#E4E1E7",
    },
    saveNewBtn: {
      backgroundColor: CONST.colorChooseCtaGreen,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: CONST.buttonBorderRadius,
      paddingHorizontal: 16,
    },
    saveNewBtnTxt: {
      color: "white",
      textTransform: "uppercase",
      fontFamily: CONST.fonts.latoBold,
    },
  });

  return (
    <Modal heading="Rename workout" isOpen close={close}>
      <View style={styles.row}>
        <TextInput
          style={styles.textInput}
          value={newName}
          onChangeText={e => setNewName(e)}
        />
        <TouchableOpacity style={styles.saveNewBtn} onPress={handleSave}>
          <Text style={styles.saveNewBtnTxt}>Save</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

export default EditNameModal;
