import React from "react";
import ProviderComponent from "components/provider";

// TODO! Code split pages + eagerly load bundles
// Real links for SEO - use React Router?
// Test

const App: React.FC = () => {
  return <ProviderComponent />;
};

export default App;
