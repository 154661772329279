import * as React from "react";
import range from "lodash/range";

function numberToTimeUnitItem(value: number) {
  const timeUnit = `${value}`.padStart(2, "0");
  return { label: timeUnit, value: timeUnit };
}

export function useAllowedTimeUnitItems(min: number, max: number) {
  return React.useMemo(() => {
    return range(min, max + 1).map(numberToTimeUnitItem);
  }, [min, max]);
}

export function useSelectedDuration(
  selectedDuration: string,
): [string, string] {
  const [minute, second] = selectedDuration.split(":", 2);
  return [minute, second];
}
