import { state } from "store/types";
import { saveNewWorkout } from "store/actions";
import * as React from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";
import CONST from "components/CONST";
import { useDispatch, useSelector } from "react-redux";
import breakPointMemo from "store/computed/breakPoints";
import Existing from "./Existing";

const ModalContent = () => {
  const dispatch = useDispatch();

  const [newWorkoutName, setNewWorkoutName] = React.useState("");
  const breakPoint = useSelector(breakPointMemo);
  const savedWorkoutsNot0 = useSelector(
    (state: state) => state.savedWorkouts.length > 0,
  );

  const handleSaveNew = () => {
    if (newWorkoutName.length === 0)
      return alert("You must give a new workout a name");
    dispatch(saveNewWorkout({ name: newWorkoutName }));
  };

  const styles = StyleSheet.create({
    heading: {
      fontFamily: CONST.fonts.latoBold,
      textTransform: "uppercase",
      color: CONST.colorFontGrey,
      marginBottom: 10,
    },
    sectionTop: {
      marginBottom: 10,
    },
    sectionBottom: {
      marginTop: 30,
    },
    textInput: {
      borderColor: "#B0A9BA",
      borderWidth: 1,
      borderStyle: "solid",
      padding: 10,
      borderRadius: CONST.buttonBorderRadius,
      marginRight: 8,
      minWidth: breakPoint ? 240 : undefined,
      flexGrow: breakPoint ? undefined : 1,
      backgroundColor: "#E4E1E7",
    },
    saveNewRow: {
      flexDirection: "row",
    },
    saveNewBtn: {
      backgroundColor: CONST.colorChooseCtaGreen,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: CONST.buttonBorderRadius,
      paddingHorizontal: 16,
    },
    saveNewBtnTxt: {
      color: "white",
      textTransform: "uppercase",
      fontFamily: CONST.fonts.latoBold,
    },
  });

  return (
    <View>
      <View style={styles.sectionTop}>
        <Text style={styles.heading}>Save new</Text>
        <View style={styles.saveNewRow}>
          <TextInput
            style={styles.textInput}
            value={newWorkoutName}
            onChangeText={e => setNewWorkoutName(e)}
          />
          <TouchableOpacity style={styles.saveNewBtn} onPress={handleSaveNew}>
            <Text style={styles.saveNewBtnTxt}>Save</Text>
          </TouchableOpacity>
        </View>
      </View>
      {savedWorkoutsNot0 && (
        <View style={styles.sectionBottom}>
          <Text style={styles.heading}>Overwrite existing</Text>
          <Existing />
        </View>
      )}
    </View>
  );
};

export default ModalContent;
