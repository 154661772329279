import {
  action,
  state,
  InputCustomExercise,
  InputCustomRest,
  SavedHIITWorkout,
  SavedCustomWorkout,
} from "./types";

import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createStore, applyMiddleware } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { AsyncStorage } from "react-native";
import { v4 as uuid } from "uuid";
import arrayMove from "array-move";
import thunk from "redux-thunk";

import initState, { warmUpTimeStartIndex } from "store/initState";
// Reducers
import moveExerciseUp from "store/reducer-cases/move-exercise-up";
import moveExerciseDown from "store/reducer-cases/move-exercise-down";
import setLegalConsent from "store/reducer-cases/setLegalConsent";
import toggleSound from "store/reducer-cases/toggleSound";
import deleteWorkout from "store/reducer-cases/deleteWorkout";

// Utils
import addEmptyExercise from "store/utils/addEmptyExercise";
import {
  indexNextRoutineItem as indexNextRoutineItemMemo,
  indexPrevRoutineItem as indexPrevRoutineItemMemo,
  hasFinishedRoutine,
} from "store/computed/calculateRoutine";
import { convertEntitiesToChars } from "./utils/stringCharConversion";
import resetWorkout from "./utils/resetWorkout";
import EmailIsBeingSubscribedTransform from "./utils/EmailIsBeingSubscribedTransform";

function reducer(state: state = initState, action: action): state {
  switch (action.type) {
    // Hack
    case "soundHackActivatedToggle":
      return {
        ...state,
        soundHackActivated: action.value,
      };
    // Saving
    case "saveWorkoutModalToggle":
      return {
        ...state,
        saveModalIsOpen: action.value,
      };
    case "deleteWorkout":
      return deleteWorkout({ state, action });
    case "saveNewWorkout":
      const newCustomWorkout: SavedCustomWorkout = {
        workoutType: "Custom",
        name: action.name,
        workout: state.inputsCustom,
      };
      const newHiitWorkout: SavedHIITWorkout = {
        workoutType: "HIIT",
        name: action.name,
        workout: {
          ...state.inputsHiit,
          exercises: state.inputsHiit.exercises
            .map(item => item.text)
            .filter(item => item),
        },
      };
      return {
        ...state,
        saveModalIsOpen: false,
        savedWorkouts: [
          ...state.savedWorkouts,
          state.workoutType === "HIIT" ? newHiitWorkout : newCustomWorkout,
        ],
      };
    case "overwriteSavedWorkout":
      return {
        ...state,
        saveModalIsOpen: false,
        savedWorkouts: state.savedWorkouts.map((item, index) => {
          if (action.index !== index) return item;

          const { workoutType } = state;

          const newHIIT = (): SavedHIITWorkout => ({
            workoutType: "HIIT",
            name: item.name,
            workout: {
              ...state.inputsHiit,
              exercises: state.inputsHiit.exercises
                .map(item => item.text)
                .filter(item => item),
            },
          });

          const newCustom = (): SavedCustomWorkout => ({
            workoutType: "Custom",
            name: item.name,
            workout: state.inputsCustom,
          });

          return workoutType === "HIIT" ? newHIIT() : newCustom();
        }),
      };
    case "renameSavedWorkout":
      return {
        ...state,
        savedWorkouts: state.savedWorkouts.map((item, index) => {
          if (index !== action.index) return item;
          return {
            ...item,
            name: action.name,
          };
        }),
      };
    case "moveSavedWorkoutUp":
      return {
        ...state,
        savedWorkouts: arrayMove(
          state.savedWorkouts,
          action.index,
          action.index - 1,
        ),
      };
    case "moveSavedWorkoutDown":
      const isLastItem = state.savedWorkouts.length === action.index + 1;
      if (isLastItem) {
        return {
          ...state,
          savedWorkouts: arrayMove(state.savedWorkouts, action.index, 0),
        };
      }
      return {
        ...state,
        savedWorkouts: arrayMove(
          state.savedWorkouts,
          action.index,
          action.index + 1,
        ),
      };

    // MISC
    // Resets choices eg is paused
    case "resetWorkout":
      return {
        ...resetWorkout(state),
      };
    case "setLegalConsent": {
      return setLegalConsent({ state, action });
    }
    case "setEmailSignupWasClosed": {
      return {
        ...state,
        showEmailSignup: false,
        emailSignupWasClosed: new Date().toISOString(),
      };
    }
    case "toggleMobileMenu":
      return {
        ...state,
        paused: true,
        mobileMenuIsOpen: !state.mobileMenuIsOpen,
      };
    case "socialShareModalToggle":
      return {
        ...state,
        paused: true,
        shareModalIsOpen: action.isOpen,
      };
    case "popularWorkoutsModalToggle":
      return {
        ...state,
        popularWorkoutsModalIsOpen: action.isOpen,
      };
    case "setDimensions":
      return {
        ...state,
        width: action.width,
        height: action.height,
        isLandscape: action.isLandscape,
      };

    case "toggleWorkoutType":
      return {
        ...state,
        workoutType: action.workoutType,
      };

    // SET WORKOUT - CUSTOM
    case "customSetRounds": {
      if (action.value <= 0) {
        return state;
      }
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          rounds: action.value,
        },
      };
    }
    case "customSetSecondsRest":
      if (action.value <= 0) {
        return {
          ...state,
          inputsCustom: {
            ...state.inputsCustom,
            rest: 0,
          },
        };
      }
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          rest: action.value,
        },
      };
    case "customReorderExercises":
      const reorderedRoutine = arrayMove(
        state.inputsCustom.routine,
        action.oldIndex,
        action.newIndex,
      );
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          routine: reorderedRoutine,
        },
      };

    case "removeExerciseCustom":
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          routine: state.inputsCustom.routine.filter(
            (_, index) => index !== action.index,
          ),
        },
      };

    case "customSetExerciseText":
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          routine: state.inputsCustom.routine.map((item, index) => {
            if (index !== action.index) {
              return item;
            }
            return {
              ...item,
              name: action.text,
            };
          }),
        },
      };

    case "customAddExercise":
      const newItemExercise: InputCustomExercise = {
        name: "",
        weight: {
          amount: 0,
          format: state.usesMetricSystem ? "kg" : "lbs",
        },
        repeat: {
          amount: state.lastSetCustomExerciseDuration,
          format: state.lastSetCustomExerciseDurationFormat,
        },
        isRest: false,
      };
      const newRoutineWithExercise = [
        ...state.inputsCustom.routine,
        newItemExercise,
      ];
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          routine: newRoutineWithExercise,
        },
      };

    case "customAddRest":
      const newItemRest: InputCustomRest = {
        isRest: true,
        time: state.lastSetCustomRestDuration,
      };
      const newRoutineWithRest = [...state.inputsCustom.routine, newItemRest];
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          routine: newRoutineWithRest,
        },
      };

    case "customSetWeightFormat":
      return {
        ...state,
        // At the same time set the "usesMetricSystem"
        // based on new value.
        usesMetricSystem: action.format === "kg",
        inputsCustom: {
          ...state.inputsCustom,
          routine: state.inputsCustom.routine.map((item, index) => {
            // item.isRest should never occur but the test keeps TypeScript happy
            if (index !== action.index || item.isRest) {
              return item;
            }
            return {
              ...item,
              weight: {
                ...item.weight,
                format: action.format,
              },
            };
          }),
        },
      };

    case "customSetWeightValue":
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          routine: state.inputsCustom.routine.map((item, index) => {
            // item.isRest should never occur but the test keeps TypeScript happy
            if (index !== action.index || item.isRest) {
              return item;
            }
            return {
              ...item,
              weight: {
                ...item.weight,
                amount: action.value,
              },
            };
          }),
        },
      };

    case "customSetDurationFormat":
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          routine: state.inputsCustom.routine.map((item, index) => {
            // item.isRest should never occur but the test keeps TypeScript happy
            if (index !== action.index || item.isRest) {
              return item;
            }
            return {
              ...item,
              repeat: {
                ...item.repeat,
                format: action.format,
              },
            };
          }),
        },
        lastSetCustomExerciseDurationFormat: action.format,
      };

    case "customSetDurationValue":
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          routine: state.inputsCustom.routine.map((item, index) => {
            // item.isRest should never occur but the test keeps TypeScript happy
            if (index !== action.index || item.isRest) {
              return item;
            }
            return {
              ...item,
              repeat: {
                ...item.repeat,
                amount: action.value,
              },
            };
          }),
        },
        lastSetCustomExerciseDuration: action.value,
      };

    case "customSetRestDuration":
      return {
        ...state,
        inputsCustom: {
          ...state.inputsCustom,
          routine: state.inputsCustom.routine.map((item, index) => {
            if (index !== action.index || !item.isRest) {
              return item;
            }
            return {
              ...item,
              time: action.value,
            };
          }),
        },
        lastSetCustomRestDuration: action.value,
      };

    // SET WORKOUT
    case "selectAWorkout":
      return {
        ...state,
        popularWorkoutsModalIsOpen: false,
        workoutType: "HIIT",
        inputsHiit: {
          ...state.inputsHiit,
          secondsExercise: action.secondsExercise,
          secondsRest: action.secondsRest,
          rounds: action.rounds,
          exercises: addEmptyExercise(
            action.exercises.map(item => ({ text: item, id: uuid() })),
          ),
        },
      };
    case "setHiitWorkoutInputs":
      const exercisesAll = action.exercises.map(text => {
        return {
          text: convertEntitiesToChars(text),
          id: uuid(),
        };
      });
      return {
        ...state,
        workoutType: "HIIT",
        inputsHiit: {
          ...state.inputsHiit,
          secondsExercise: action.secondsExercise,
          secondsRest: action.secondsRest,
          rounds: action.rounds,
          exercises: addEmptyExercise(exercisesAll),
        },
      };
    case "setCustomWorkoutInputs":
      return {
        ...state,
        workoutType: "Custom",
        inputsCustom: {
          routine: action.routine,
          rest: action.rest,
          rounds: action.rounds,
        },
      };
    case "setSecondsExercise":
      if (action.value <= 0) {
        return {
          ...state,
          inputsHiit: {
            ...state.inputsHiit,
            secondsExercise: 5,
          },
        };
      }
      return {
        ...state,
        inputsHiit: {
          ...state.inputsHiit,
          secondsExercise: action.value,
        },
      };
    case "setSecondsRest":
      if (action.value <= 0) {
        return {
          ...state,
          inputsHiit: {
            ...state.inputsHiit,
            secondsRest: 0,
          },
        };
      }
      return {
        ...state,
        inputsHiit: {
          ...state.inputsHiit,
          secondsRest: action.value,
        },
      };
    case "setRounds":
      if (action.value <= 0) {
        return {
          ...state,
          inputsHiit: {
            ...state.inputsHiit,
            rounds: 1,
          },
        };
      }
      return {
        ...state,
        inputsHiit: {
          ...state.inputsHiit,
          rounds: action.value,
        },
      };
    case "setExercise":
      const newSetExercises = state.inputsHiit.exercises.map(
        (exercise, exerciseIndex) => {
          if (action.index !== exerciseIndex) {
            return exercise;
          }
          return {
            text: action.text,
            id: exercise.id,
          };
        },
      );
      return {
        ...state,
        inputsHiit: {
          ...state.inputsHiit,
          exercises: addEmptyExercise(newSetExercises),
        },
      };
    case "reorderExercises":
      const reorderedExercises = arrayMove(
        state.inputsHiit.exercises,
        action.oldIndex,
        action.newIndex,
      );

      return {
        ...state,
        inputsHiit: {
          ...state.inputsHiit,
          exercises: addEmptyExercise(reorderedExercises),
        },
      };
    case "removeExercise":
      if (state.inputsHiit.exercises.length === 1) {
        return {
          ...state,
          inputsHiit: {
            ...state.inputsHiit,
            exercises: [
              {
                text: "",
                id: uuid(),
              },
            ],
          },
        };
      }
      const newRemovedExercises = state.inputsHiit.exercises.filter(
        (_, exerciseIndex) => {
          return exerciseIndex !== action.index;
        },
      );
      return {
        ...state,
        inputsHiit: {
          ...state.inputsHiit,
          exercises: addEmptyExercise(newRemovedExercises),
        },
      };
    case "moveExerciseUp":
      return moveExerciseUp({ state, action });
    case "moveExerciseDown":
      return moveExerciseDown({ state, action });

    // GO PAGE
    case "countdownTick":
      if (!state.warmUpIsFinished) {
        const nextWarmUpTimeIndex = state.warmUpTimeIndex - 1;
        const haveReachedEnd = nextWarmUpTimeIndex === 0;
        if (haveReachedEnd) {
          return {
            ...state,
            warmUpIsFinished: true,
            warmUpTimeIndex: warmUpTimeStartIndex,
          };
        } else {
          return {
            ...state,
            warmUpTimeIndex: state.warmUpTimeIndex - 1,
          };
        }
      }

      const nextRoutineTimeIndex = state.routineTimeIndex + 1;
      const haveReachedEnd = hasFinishedRoutine(state);
      if (haveReachedEnd) {
        return {
          ...state,
          routineIsFinished: true,
          warmUpIsFinished: false,
          routineTimeIndex: 0,
        };
      }
      return {
        ...state,
        routineTimeIndex: nextRoutineTimeIndex,
      };
    case "prevActivity":
      const indexPrevRoutineItem = indexPrevRoutineItemMemo(state);
      if (state.routineTimeIndex === 0) {
        return {
          ...state,
          warmUpTimeIndex: warmUpTimeStartIndex,
          warmUpIsFinished: false,
        };
      }
      if (!state.warmUpIsFinished) {
        return {
          ...state,
          warmUpTimeIndex: warmUpTimeStartIndex,
        };
      }
      return {
        ...state,
        routineTimeIndex: indexPrevRoutineItem,
      };
    case "skipCurrent":
      if (!state.warmUpIsFinished) {
        return {
          ...state,
          warmUpIsFinished: true,
        };
      }
      const indexNextRoutineItem = indexNextRoutineItemMemo(state);
      const stateWithNextRoutineItemIndex = {
        ...state,
        routineTimeIndex: indexNextRoutineItem,
      };
      if (!hasFinishedRoutine(stateWithNextRoutineItemIndex)) {
        return stateWithNextRoutineItemIndex;
      }
      return {
        ...state,
        routineIsFinished: true,
        warmUpIsFinished: false,
        routineTimeIndex: 0,
      };
    case "togglePause":
      return {
        ...state,
        paused: !state.paused,
      };
    case "toggleSound":
      return toggleSound({ state, action });
    case "setEmailAddress":
      return {
        ...state,
        userEmailAddress: action.emailAddress,
      };
    case "setEmailSubscriptionStatus":
      return {
        ...state,
        emailSubscriptions: {
          ...state.emailSubscriptions,
          [action.emailAddress]: {
            isBeingSubscribed: action.isBeingSubscribed,
            hasBeenSubscribedSuccessfully: action.hasSubscribedSuccessfully,
          },
        },
      };
    case "showEmailSignup":
      return {
        ...state,
        showEmailSignup: true,
      };
    default:
      return state;
  }
}

const persistedReducer = persistReducer(
  {
    // We use an app-specific key so that stores
    // of different applications served from
    // the same domain (eg. `localhost`)
    // do not collide with each other.
    key: "hiit1",
    storage: AsyncStorage,
    whitelist: [
      "emailSignupWasClosed",
      "savedWorkouts",
      "sound",
      "legalConsentGranted",
      "workoutType",
      "inputsHiit",
      "inputsCustom",
      "usesMetricSystem",
      "lastSetCustomRestDuration",
      "lastSetCustomExerciseDuration",
      "lastSetCustomExerciseDurationFormat",
      "userEmailAddress",
      "emailSubscriptions",
    ],
    transforms: [
      // We don't want to persist that a subscription request is in progress
      // so we filter it from the persisted state.
      EmailIsBeingSubscribedTransform,
    ],
  },
  reducer,
);

export default () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk)),
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
