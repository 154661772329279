import { Pages } from "application/types";

import * as React from "react";

import Link from "components/global/components/link";

import Logo from "../logo";

const DesktopLogoLink = () => {
  return (
    <Link goTo={Pages.choose} accessibilityLabel="Love HIIT">
      <Logo isDark={false} />
    </Link>
  );
};

export default DesktopLogoLink;
