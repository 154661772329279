import {
  deleteWorkoutArgs,
  inputsWithoutIds,
  moveSavedWorkoutArgs,
} from "store/types";
import * as React from "react";
import useCustomNav from "components/global/hooks/useCustomNav";
import { Text, TouchableOpacity, View } from "react-native";
import TimeFormat from "hh-mm-ss";
import routineLength from "store/utils/calculateRoutineUtil/routineLength";
import { formatRoutineLength } from "application/components/utils/formatSeconds";
import { Pages } from "application/types";
import EditNameModal from "../EditNameModal";
import IconBin from "components/global/components/icons/bin";
import IconCog from "../IconCog";
import IconSelectChevron from "components/global/components/icons/select-chevron";
import styles from "../STYLES";
import CONST from "components/CONST";

type Props = inputsWithoutIds & {
  index: number;
  name: string;
  deleteWorkout: (arg0: deleteWorkoutArgs) => void;
  selectAWorkout: (arg0: inputsWithoutIds) => void;
  moveSavedWorkoutUp: (arg: moveSavedWorkoutArgs) => void;
  moveSavedWorkoutDown: (arg: moveSavedWorkoutArgs) => void;
};

const WorkoutHIIT = ({
  secondsExercise,
  secondsRest,
  rounds,
  exercises,
  deleteWorkout,
  index,
  selectAWorkout,
  name,
  moveSavedWorkoutUp,
  moveSavedWorkoutDown,
}: Props) => {
  const navigate = useCustomNav();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const routineLengthVal = routineLength({
    secondsExercise,
    secondsRest,
    rounds,
    exercises,
  });

  const timeFormatted = formatRoutineLength(routineLengthVal);

  const handleSelectAWorkout = () => {
    selectAWorkout({ secondsExercise, secondsRest, rounds, exercises });
    navigate.goTo(Pages.choose);
  };

  return (
    <>
      {modalIsOpen && (
        <EditNameModal
          close={() => setModalIsOpen(false)}
          name={name}
          index={index}
        />
      )}
      <View style={styles.wrapper}>
        <TouchableOpacity onPress={() => setModalIsOpen(true)}>
          <View style={styles.heading_row}>
            <Text style={styles.heading}>
              {name}
              <Text style={styles.workoutType}>(HIIT)</Text>
            </Text>
            <View style={styles.cogIconWrapper}>
              <IconCog />
            </View>
          </View>
        </TouchableOpacity>

        <View style={styles.rowTimes}>
          <View style={styles.col}>
            <Text style={styles.value}>
              {TimeFormat.fromS(secondsExercise)}
            </Text>
            <Text style={styles.labelHIIT}>Exercise</Text>
          </View>
          <View style={styles.col}>
            <Text style={styles.value}>{TimeFormat.fromS(secondsRest)}</Text>
            <Text style={styles.labelHIIT}>Rest</Text>
          </View>
          <View style={styles.col}>
            <Text style={styles.value}>{rounds}</Text>
            <Text style={styles.labelHIIT}>Rounds</Text>
          </View>
        </View>

        <View style={styles.row_routine}>
          {exercises.map((item, index) => {
            return (
              <View key={index} style={styles.valueRoutineWrapper}>
                <Text style={styles.value_routine_item}>{item}</Text>
                {exercises.length !== index + 1 && (
                  <View style={styles.routineItemSeparator} />
                )}
              </View>
            );
          })}
        </View>

        <View style={[styles.row, styles.row_last]}>
          <TouchableOpacity
            onPress={() => deleteWorkout({ index })}
            style={[styles.button, styles.button__secondary]}
          >
            <IconBin />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => moveSavedWorkoutUp({ index })}
            style={[styles.button, styles.button__secondary]}
          >
            <IconSelectChevron
              color={CONST.colorDarkBlueBackground}
              size={18}
            />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => moveSavedWorkoutDown({ index })}
            style={[styles.button, styles.button__secondary]}
          >
            <IconSelectChevron
              color={CONST.colorDarkBlueBackground}
              size={18}
              direction={"down"}
            />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={handleSelectAWorkout}
            style={[styles.button, styles.button__go]}
          >
            <Text style={[styles.buttonText, styles.buttonText_go]}>
              {timeFormatted} Select
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

export default WorkoutHIIT;
