import { Pages } from "application/types";

import React from "react";
import { Text } from "react-native";

import Legal from "../index";
import { H1, P } from "../HTMLComponents";

import styles from "components/pages/info/legal/STYLES";

import Link from "components/global/components/link";
import SetMetaTags from "components/global/invisible/setMetaTags";

const DisclaimerCopy = () => {
  return (
    <Legal>
      <>
        <SetMetaTags title="Love HIIT - Legal Disclaimer" />
        <H1>Legal Disclaimer</H1>

        <P>
          Users of this app are responsible for ensuring that they are capable
          of undergoing the routine of exercises that is self determined and /
          or suggested by this app.
        </P>

        <P>
          Users accept the risk of injury from performing exercises are are
          advices to consult their doctor prior to using this app
        </P>

        <Link goTo={Pages.choose}>
          <Text style={styles.a}>Go to workout</Text>
        </Link>
      </>
    </Legal>
  );
};

export default DisclaimerCopy;
