import * as React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import Constants from "expo-constants";
import CONST from "components/CONST";

import GoAgain from "./buttons/go-again";
import ShareWorkout from "./buttons/share-workout";
import MobileImage from "./mobile-image";
import DesktopImage from "./desktop-image";
import Header from "components/global/components/header";
import SetMetaTags from "components/global/invisible/setMetaTags";
import ScrollTop from "components/global/invisible/scrollTop";
import MakeSoundInvisible from "./make-sound-invisible";

// TODO Code split MobileImage / do responsive images some other way

type Props = {
  width: number;
  breakPoint: boolean;
};

// TODO Load the right image sizes based on screen width
// TODO Remove width
const FinishedPage = ({ width, breakPoint }: Props) => {
  const desktopIllustrationWidth = width * 0.66;

  const styles = StyleSheet.create({
    container: {
      backgroundColor: CONST.colorDarkBlueBackground,
      flex: 1,
    },
    container2: {
      flex: 1,
      paddingTop: Constants.statusBarHeight + CONST.sizes.viewportTopPadding,
    },
    container3: {
      flex: 1,
      paddingTop: breakPoint ? undefined : 20,
      zIndex: -1,
    },
    buttonContainer: {
      // Use safe area method to increase bottom padding? - same as choose page?
      flexDirection: breakPoint ? "row" : "column",
      padding: breakPoint ? undefined : 20,
      paddingLeft: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
    },
    heading: {
      fontFamily: CONST.fonts.latoBold,
      textAlign: breakPoint ? "left" : "center",
      color: "white",
      fontSize: breakPoint ? 60 : 30,
      marginTop: breakPoint ? 0 : 30,
      marginBottom: breakPoint ? 40 : 20,
      paddingLeft: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
    },
    imageContainerDesktop: {
      position: "absolute",
      right: 0,
      bottom: 70,
      width: desktopIllustrationWidth,
    },
    imageContainerMobile: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    gradientDesktop: {
      position: "absolute",
      right: 0,
      bottom: 0,
      left: 0,
      height: 100,
    },
    gradientMobile: {
      position: "absolute",
      right: 0,
      top: 0,
      left: 0,
      height: 100,
      zIndex: -1,
    },
    spacer1: {
      display: breakPoint ? undefined : "none",
      flex: 1,
    },
    spacer2: {
      display: breakPoint ? undefined : "none",
      flex: 3,
    },
  });

  return (
    <>
      <ScrollTop />
      <MakeSoundInvisible />
      <View style={styles.container}>
        <SetMetaTags title="Love HIIT - Workout finished" />
        <ScrollView
          keyboardShouldPersistTaps="always"
          scrollEventThrottle={16}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View style={styles.container2}>
            <Header backgroundColor={CONST.colorDarkBlueBackground} />
            <View style={styles.container3}>
              {breakPoint && (
                <LinearGradient
                  style={styles.gradientDesktop}
                  colors={["#3B2E57", "#261745"]}
                />
              )}
              <View
                style={
                  breakPoint
                    ? styles.imageContainerDesktop
                    : styles.imageContainerMobile
                }
              >
                {breakPoint ? (
                  <DesktopImage width={desktopIllustrationWidth} />
                ) : (
                  <MobileImage />
                )}
              </View>

              <View style={styles.spacer1} />

              <Text style={styles.heading}>Good job!</Text>
              <View style={styles.buttonContainer}>
                <ShareWorkout breakPoint={breakPoint} />
                <GoAgain />
              </View>

              <View style={styles.spacer2} />
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default FinishedPage;
