import * as React from "react";
import Button from "./button";

type Props = {
  sharedStyles: any;
};

const SocialShareButtonIndex = ({ sharedStyles }: Props) => {
  return <Button sharedStyles={sharedStyles} />;
};

export default SocialShareButtonIndex;
