import { toggleMobileMenuArgs } from "store/types";
import * as React from "react";
import { TouchableOpacity } from "react-native";
import HamburgerIcon from "./hamburger-icon";

type Props = {
  toggleMobileMenu: (arg0: toggleMobileMenuArgs) => void;
};

const HamburgerLink = ({ toggleMobileMenu }: Props) => {
  return (
    <TouchableOpacity onPress={() => toggleMobileMenu({ isOpen: true })}>
      <HamburgerIcon />
    </TouchableOpacity>
  );
};

export default HamburgerLink;
