import * as React from "react";
import { Platform, StyleSheet, View } from "react-native";

import Constants from "expo-constants";
import CONST from "components/CONST";

import DesktopLogoLink from "./desktop-logo-link";
import HamburgerLink from "./hamburger-link";
import LinksDesktop from "./links-desktop";

type Props = {
  breakPoint: boolean;
  backgroundColor?: string;
};

const Header = ({ breakPoint, backgroundColor }: Props) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor,
      zIndex: Platform.OS == "web" ? 1 : undefined, // Used by download app flyout menu
    },
    containerSmallB: {
      zIndex: 1,
      elevation: 1,
      position: "absolute",
      top: Constants.statusBarHeight + CONST.sizes.viewportTopPadding,
      right: CONST.sizes.viewportHorizontalPaddingMobile,
    },
    containerLargeB: {
      paddingHorizontal: CONST.sizes.viewportHorizontalPaddingDesktop,
    },
  });
  return (
    <View
      style={[
        styles.container,
        breakPoint ? styles.containerLargeB : styles.containerSmallB,
      ]}
    >
      {breakPoint && <DesktopLogoLink />}
      {breakPoint && <LinksDesktop />}

      {!breakPoint && <HamburgerLink />}
    </View>
  );
};

export default Header;
