import {
  customSetWeightValueArgs,
  customSetWeightFormatArgs,
} from "store/types";
import * as React from "react";
import { View, StyleSheet, TextInput, Text, Platform } from "react-native";
import CONST from "components/CONST";
import CustomPicker from "application/components/global/components/custom-picker";

interface Props {
  value: number;
  format: "kg" | "lbs";
  index: number;
  customSetWeightValue: (arg0: customSetWeightValueArgs) => void;
  customSetWeightFormat: (arg0: customSetWeightFormatArgs) => void;
}

// TODO Remove inputWrapper styles if not using chevrons

export const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#D1CED8",
    backgroundColor: "#F6F5F7",
    paddingHorizontal: 6,
    paddingVertical: 3,
    borderRadius: 8,
  },
  inputWrapper: {
    position: "relative",
  },
  textContainer: {
    width: 50,
  },
  text: {
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 15,
    color: "#564B6E",
    fontFamily: CONST.fonts.latoBold,
  },
  textInput: {
    justifyContent: "center",
    height: 28, // Different platforms have different default heights for text inputs
  },
  select: {
    backgroundColor: "transparent",
    padding: 0,
    margin: 0,
    borderWidth: 0,
  },
  divider: {
    backgroundColor: "#D1CED8",
    width: 1,
    height: 16,
    marginHorizontal: 4,
  },
});

// TODO Is numeric the best keyboard type?
//      Use keyboard aware View?

const WeightInput = ({
  value,
  format,
  index,
  customSetWeightValue,
  customSetWeightFormat,
}: Props) => {
  return (
    <View style={styles.root}>
      <View style={[styles.inputWrapper]}>
        <TextInput
          style={[styles.textContainer, styles.text, styles.textInput]}
          keyboardType="numeric"
          value={`${value}`}
          onChangeText={newValue =>
            customSetWeightValue({ index, value: +newValue })
          }
        />
      </View>

      <View style={styles.divider} />

      <View style={styles.inputWrapper}>
        <CustomPicker
          prompt="Weight unit"
          style={styles.select}
          indicatorStyle={[styles.textContainer, styles.text]}
          selectedValue={format}
          onValueChange={newFormat => {
            customSetWeightFormat({ index, format: newFormat });
          }}
          items={[
            { label: "kg", value: "kg" },
            { label: "lbs", value: "lbs" },
          ]}
        />
      </View>
    </View>
  );
};

export default WeightInput;
