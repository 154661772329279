import { state } from "store/types";
import { InputNumericTypes } from "application/types";
import { connect } from "react-redux";
import InputNumeric from "../input-numeric/input-numeric";
import { setSecondsRest } from "store/actions";
import breakPoint from "store/computed/breakPoints";

const mapState = (state: state) => {
  const { secondsRest } = state.inputsHiit;
  return {
    value: secondsRest,
    type: InputNumericTypes.REST,
    increment: 5,
    title: "Rest",
    breakPoint: breakPoint(state),
  };
};

const mapDispatch = {
  set: setSecondsRest,
};

export default connect(mapState, mapDispatch)(InputNumeric);
