// import { toggleMobileMenuArgs } from "store/types";
import * as React from "react";
import MenuContent from "./menu-content";
import OffCanvasMenu from "./offCanvasMenu";
import PlatformSpecifics from "./platform-specifics";

type Props = {
  mobileMenuIsOpen: boolean;
  breakPoint: boolean;
};

const MobileOffCanvasMenu = ({ mobileMenuIsOpen, breakPoint }: Props) => {
  const showOffCanvasMenu = mobileMenuIsOpen && !breakPoint;

  return (
    <PlatformSpecifics showOffCanvasMenu={showOffCanvasMenu}>
      <OffCanvasMenu>
        <MenuContent />
      </OffCanvasMenu>
    </PlatformSpecifics>
  );
};

export default MobileOffCanvasMenu;
