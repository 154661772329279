import { InputNumericTypes } from "application/types";
import { setRoundsArgs, setSecondsRestArgs } from "store/types";

import * as React from "react";
import { View, StyleSheet, Platform } from "react-native";
import AddExerciseOrRest from "./add-exercise-or-rest";
import InputNumeric from "../input-numeric";
import CONST from "components/CONST";
import Routine from "./routine";

type Props = {
  breakPoint: boolean;
  rounds: number;
  rest: number;
  customSetRounds: (arg0: setRoundsArgs) => void;
  customSetSecondsRest: (arg0: setSecondsRestArgs) => void;
};

const WorkoutCustom = ({
  breakPoint,
  rounds,
  rest,
  customSetRounds,
  customSetSecondsRest,
}: Props) => {
  const styles = StyleSheet.create({
    root: {
      flexDirection: breakPoint ? "row" : "column",
    },
    section1: {
      width: breakPoint ? "50%" : undefined,
      paddingLeft: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile / 2,
      paddingRight: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop / 2
        : CONST.sizes.viewportHorizontalPaddingMobile / 2,
    },
    section2: {
      width: breakPoint ? "50%" : undefined,
      paddingTop: breakPoint ? undefined : 48,
      paddingLeft: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop / 2
        : CONST.sizes.viewportHorizontalPaddingMobile,
      paddingRight: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
    },
    addExerciseOrRestWrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 10,
      paddingHorizontal: breakPoint
        ? undefined
        : CONST.sizes.viewportHorizontalPaddingMobile / 2,
    },
    addExerciseOrRestWrapperCol: {
      width: "50%",
    },
    addExerciseOrRestWrapperCol_1: {
      paddingRight: 10,
    },
    addExerciseOrRestWrapperCol_2: {
      paddingLeft: 10,
    },
  });

  const restBtwRoundsModalPrompt = React.useCallback(
    () => "Rest between rounds",
    [],
  );

  return (
    <View style={styles.root}>
      <View style={styles.section1}>
        <Routine />

        <View style={styles.addExerciseOrRestWrapper}>
          <View
            style={[
              styles.addExerciseOrRestWrapperCol,
              styles.addExerciseOrRestWrapperCol_1,
            ]}
          >
            <AddExerciseOrRest isExercise />
          </View>
          <View
            style={[
              styles.addExerciseOrRestWrapperCol,
              styles.addExerciseOrRestWrapperCol_2,
            ]}
          >
            <AddExerciseOrRest />
          </View>
        </View>
      </View>

      <View style={styles.section2}>
        <InputNumeric
          type={InputNumericTypes.ROUNDS}
          value={rounds}
          set={customSetRounds}
          increment={1}
          isLast={false}
          title={"Rounds"}
        />

        {rounds > 1 && (
          <InputNumeric
            type={InputNumericTypes.REST}
            value={rest}
            set={customSetSecondsRest}
            increment={5}
            isLast={true}
            title={"Rest btw. rounds"}
            modalPrompt={Platform.select<string | (() => string)>({
              ios: restBtwRoundsModalPrompt,
              default: "Rest between rounds",
            })}
          />
        )}
      </View>
    </View>
  );
};

export default WorkoutCustom;
