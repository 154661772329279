import * as React from "react";
import { useDispatch } from "react-redux";
import calculateFromUrl from "./calculateFromUrl";

const GetWorkoutFromUrl = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const { search } = window.location;
    const action = calculateFromUrl(search);
    if (action) {
      dispatch(action);
    }
  }, [dispatch]);

  return null;
};

export default GetWorkoutFromUrl;
