// import { InputNumericTypes } from "application/types";
import { setSecondsExerciseArgs } from "store/types";

import * as React from "react";
import { getTrackBackground, Range } from "react-range";

export type Props = {
  step: number;
  min: number;
  max: number;
  value: number;
  containerHeight: number;
  set: (args: setSecondsExerciseArgs) => void;
};

const SliderComponent = ({
  step,
  min,
  max: initialMax,
  value,
  containerHeight,
  set,
}: Props) => {
  // This prevents the "value is out of bounds" fatal error
  // happening on web if you use duration picker to select
  // some big duration and refresh.
  const max = React.useMemo(() => Math.max(initialMax, value), []);
  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={[value]}
      onChange={values => set({ value: values[0] })}
      renderTrack={({ props, children }) => {
        return (
          <div
            {...props}
            style={{
              ...props.style,
              height: containerHeight,
              width: "100%",
              background: getTrackBackground({
                min,
                max: max,
                values: [value],
                colors: ["transparent", "#3C325D"],
              }),
              borderRadius: 20,
            }}
          >
            {children}
          </div>
        );
      }}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: containerHeight,
            width: containerHeight,
            backgroundColor: "white",
            borderRadius: containerHeight / 2,
          }}
        />
      )}
    />
  );
};

export default SliderComponent;
