import { StyleSheet } from "react-native";
import CONST from "components/CONST";

const styles = StyleSheet.create({
  text: {
    color: "white",
    fontFamily: CONST.fonts.latoRegular,
    fontSize: 18,
  },
  marginDefault: {
    marginBottom: 16,
  },
  marginLarge: {
    marginBottom: 32,
  },
});

export default styles;
