import * as React from "react";
import { connect } from "react-redux";
import { state } from "store/types";
import breakPoint from "store/computed/breakPoints";
import { resetWorkout } from "store/actions";
import Back from "./back";

const mapState = (state: state) => {
  return {
    breakPoint: breakPoint(state),
  };
};

const mapDispatch = {
  resetWorkout,
};

export default connect(mapState, mapDispatch)(Back);
