import * as React from "react";
import { Path, Svg } from "react-native-svg";
import { preventTabbing } from "components/CONST";
import CONST from "./CONST";

type Props = {
  isDark?: boolean;
};

const SaveIcon = ({ isDark }: Props) => {
  const { darkColor } = CONST;
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 20 20"
      fill="none"
      {...preventTabbing}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0h14.422L20 3.677V18a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm13.578 2H15v6H5V2H2v16h2v-8h12v8h2V4.49L15.578 2zM7 6V2h6v4H7zm-1 6v6h8v-6H6zm6-9h-2v2h2V3z"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default SaveIcon;
