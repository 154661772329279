import * as React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import CONST from "components/CONST";

import SetMetaTags from "components/global/invisible/setMetaTags";
import ScrollTop from "components/global/invisible/scrollTop";

import Header from "application/components/global/components/header";
import Workouts from "./workouts";
import Constants from "expo-constants";
// import Constants from "expo-constants";

type Props = {
  breakPoint: boolean;
};

const SavedPage = ({ breakPoint }: Props) => {
  const styles = StyleSheet.create({
    // Page stuff
    container: {
      flex: 1,
      backgroundColor: CONST.colorDarkBlueBackground,
    },
    container2: {
      flex: 1,
      paddingTop: Constants.statusBarHeight + CONST.sizes.viewportTopPadding,
    },
    main: {
      flex: 1,
      paddingBottom: 38,
      paddingTop: breakPoint ? 60 : undefined,
    },
    mainInner: {
      paddingHorizontal: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
    },
    headingContainer: {
      paddingRight: 40, // Offset for mobile menu
      marginBottom: breakPoint ? 36 : 24,
    },
    // Components
    heading: {
      fontSize: breakPoint ? 48 : 36,
      color: "white",
      fontFamily: CONST.fonts.latoBold,
    },
  });

  return (
    <>
      <ScrollTop />
      <View style={styles.container}>
        <SetMetaTags title="Love HIIT - Saved workouts" />
        <ScrollView
          keyboardShouldPersistTaps="handled"
          scrollEventThrottle={16}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View style={styles.container2}>
            <Header />
            <View style={styles.main}>
              <View style={styles.mainInner}>
                <View style={styles.headingContainer}>
                  <Text style={styles.heading}>Saved workouts</Text>
                </View>
                <Workouts />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default SavedPage;
