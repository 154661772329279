import * as React from "react";
import { useFocus } from "react-native-web-hooks";
import { StyleSheet, Text, View } from "react-native";

import CONST from "application/components/CONST";
import { CustomPickerProps } from "./custom-picker";
import { useSelectedLabel, CustomPickerItem, usePrompt } from "./utils";

export default function CustomPicker<T extends string | number>({
  items,
  prompt,
  style,
  indicatorStyle,
  ...props
}: CustomPickerProps<T>) {
  const ref = React.createRef<HTMLSelectElement>();
  const hasFocus = useFocus(ref);

  const selectedLabel = useSelectedLabel(items, props.selectedValue);

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newSelectedValue = e.target.value;
      const newSelectedValueIndex =
        items?.findIndex(item => item.value === newSelectedValue) ?? -1;
      props.onValueChange?.(newSelectedValue, newSelectedValueIndex);
    },
    [props.onValueChange, items],
  );

  const title = usePrompt(prompt);

  return (
    <View
      style={[styles.container, hasFocus && styles.focusedContainer, style]}
    >
      <select
        ref={ref}
        title={title}
        onChange={onChange}
        style={selectStyles}
        value={props.selectedValue}
      >
        {items?.map(CustomPickerItem)}
      </select>
      <Text
        // @ts-ignore
        pointerEvents="none"
        aria-hidden="true"
        style={indicatorStyle}
      >
        {selectedLabel}
      </Text>
    </View>
  );
}

const selectStyles: React.CSSProperties = {
  border: "none",
  height: "100%",
  padding: 0,
  margin: 0,
  width: "100%",
  opacity: 0,
  position: "absolute",
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  focusedContainer: {
    // @ts-ignore
    boxShadow: CONST.focusedBoxShadow,
  },
});
