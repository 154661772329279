export enum level {
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
}

export type WorkoutInput = {
  title: string;
  secondsExercise: number;
  secondsRest: number;
  rounds: number;
  exercises: string[];
  level: level;
};

const beginnerWorkouts = [
  {
    title: "Cardio and Toning",
    exercises: ["Vertical jump", "Squats", "Kneeling Push Ups"],
  },
  {
    title: "Low Impact",
    exercises: ["Star jumps", "Squats", "Plank"],
  },
];

const intermediateWorkouts = [
  {
    title: "Cardio",
    exercises: ["Burpees", "High knees", "Vertical jump", "Shadow Boxing"],
  },
  {
    title: "Strength",
    exercises: ["Burpees", "Squat Jumps", "Push Ups", "Bicycle Crunches"],
  },
];

const advancedWorkouts = [
  {
    title: "Cardio",
    exercises: ["Burpees", "High knees", "Vertical jump", "Shadow Boxing"],
  },
  {
    title: "Strength",
    exercises: ["Burpees", "Squat Jumps", "Push Ups", "Bicycle Crunches"],
  },
];

export const beginner: WorkoutInput[] = beginnerWorkouts.map(item => ({
  ...item,
  secondsExercise: 30,
  secondsRest: 20,
  rounds: 4,
  level: level.BEGINNER,
}));

export const intermediate: WorkoutInput[] = intermediateWorkouts.map(item => ({
  ...item,
  secondsExercise: 35,
  secondsRest: 20,
  rounds: 4,
  level: level.INTERMEDIATE,
}));

export const advanced: WorkoutInput[] = advancedWorkouts.map(item => ({
  ...item,
  secondsExercise: 40,
  secondsRest: 15,
  rounds: 5,
  level: level.ADVANCED,
}));
