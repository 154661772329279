import { Platform } from "react-native";
import Constants from "expo-constants";
import URI from "urijs";

const NETLIFY_LAMBDA_SERVE_DEV_PORT = 9000;

/**
 * Generates URL to which we should submit
 * e-mail subscriptions. In dev it uses heuristic
 * approach of using either logUrl (which should
 * point to the running packager URL in Expo apps)
 * or window.location (which is where the app
 * is run from in Web). We then modify the URL
 * with specific port under which netlify-lambda
 * serves functions and path to subscriptions function.
 * In production mode we use either URL passed in via
 * app.json#expo.extra or a fallback URL.
 */
function getApiUrl(): string {
  if (__DEV__) {
    return URI(Constants.manifest.logUrl ?? window.location)
      .port(`${NETLIFY_LAMBDA_SERVE_DEV_PORT}`)
      .path(".netlify/functions/subscriptions")
      .toString();
  }

  if (Platform.OS === "web") {
    return "/.netlify/functions/subscriptions";
  }

  return URI(Constants.manifest.extra.baseUrl)
    .path(".netlify/functions/subscriptions")
    .toString();
}

export async function subscribe(emailAddress: string): Promise<boolean> {
  try {
    const response = await fetch(getApiUrl(), {
      method: "post",
      body: emailAddress,
    });
    return response.ok;
  } catch (error) {
    // Using `.warn` for the error to show up in dev mode.
    console.warn(error);
    return false;
  }
}
