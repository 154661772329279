import { state } from "store/types";

import * as React from "react";
import { useSelector } from "react-redux";
import { StyleSheet, Text, View } from "react-native";
import CONST from "components/CONST";
import WorkoutHIIT from "./workoutHIIT";
import WorkoutCustom from "./WorkoutCustom";
import breakPointMemo from "store/computed/breakPoints";

const Workouts = () => {
  const savedWorkouts = useSelector((state: state) => state.savedWorkouts);
  const breakPoint = useSelector(breakPointMemo);

  const styles = StyleSheet.create({
    root: {
      marginLeft: breakPoint ? undefined : -12,
      marginRight: breakPoint ? undefined : -12,
    },
    noWorkoutsText: {
      color: "white",
      fontSize: 18,
      fontFamily: CONST.fonts.latoRegular,
    },
  });

  if (savedWorkouts.length === 0) {
    return (
      <View>
        <Text style={styles.noWorkoutsText}>No workouts saved.</Text>
      </View>
    );
  }

  return (
    <View style={styles.root}>
      {savedWorkouts.map((workoutParent, index) => {
        if (workoutParent.workoutType === "HIIT") {
          const { name, workout } = workoutParent;
          const { secondsExercise, secondsRest, rounds, exercises } = workout;
          return (
            <WorkoutHIIT
              key={index}
              index={index}
              name={name}
              secondsExercise={+secondsExercise}
              secondsRest={+secondsRest}
              rounds={+rounds}
              exercises={exercises}
            />
          );
        }
        return <WorkoutCustom key={index} index={index} {...workoutParent} />;
      })}
    </View>
  );
};

export default Workouts;
