import { InputNumericTypes } from "application/types";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { setSecondsExerciseArgs } from "store/types";
import { LinearGradient } from "expo-linear-gradient";
// import CONST from "components/CONST";
import SliderComponent from "./slider-component";

type Props = {
  type: InputNumericTypes;
  value: number;
  set: (args: setSecondsExerciseArgs) => void;
};

const containerHeight = 20;

const gradientColors = (type: InputNumericTypes) => {
  switch (type) {
    case InputNumericTypes.EXERCISE:
      return ["#00918E", "#4DD599"];
    case InputNumericTypes.REST:
      return ["#865DC9", "#8673DF"];
    case InputNumericTypes.ROUNDS:
      return ["#325AC4", "#51C0FF"];
    default:
      throw Error("Case missed");
  }
};

const maximumValue = (type: InputNumericTypes) => {
  switch (type) {
    case InputNumericTypes.EXERCISE:
      return 120;
    case InputNumericTypes.REST:
      return 120;
    case InputNumericTypes.ROUNDS:
      return 20;
    default:
      throw Error("Case missed");
  }
};

const SliderContainerComponent = ({ type, value, set }: Props) => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    background: {
      borderRadius: containerHeight / 2,
    },
  });

  const step = type === InputNumericTypes.ROUNDS ? 1 : 5;
  let min = 0;
  if (type === InputNumericTypes.ROUNDS) {
    min = 1;
  } else if (type === InputNumericTypes.EXERCISE) {
    min = 5;
  }

  return (
    <View style={[styles.container]}>
      <LinearGradient
        colors={gradientColors(type)}
        start={[0, 0]}
        end={[1, 0]}
        style={styles.background}
      >
        <SliderComponent
          step={step}
          min={min}
          max={maximumValue(type)}
          value={value}
          containerHeight={containerHeight}
          set={set}
        />
      </LinearGradient>
    </View>
  );
};

export default SliderContainerComponent;
