import * as React from "react";
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import Modal from "react-modal";

import CONST from "components/CONST";

import ModalInner from "./modal-inner";

export type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  close?: () => void;
  heading: string;
  breakPoint: boolean;
};

const styles = StyleSheet.create({
  overlayClose: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});

const ModalComponent = ({
  heading,
  close,
  isOpen,
  children,
  breakPoint,
}: Props) => {
  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          display: "flex",
          backgroundColor: CONST.colorOverlay,
          justifyContent: breakPoint ? "center" : "flex-end",
          alignItems: breakPoint ? "center" : "flex-end",
        },
        content: {
          position: "static",
          backgroundColor: "transparent",
          borderWidth: 0,
          paddingLeft: breakPoint ? 20 : 0,
          paddingTop: breakPoint ? 20 : 0,
          paddingBottom: breakPoint ? 20 : 0,
          paddingRight: breakPoint ? 20 : 0,
          bottom: "auto",
          width: breakPoint ? undefined : "100%",
        },
      }}
      contentLabel="Popular workouts"
    >
      {close && (
        <TouchableWithoutFeedback onPress={() => close()}>
          <View style={styles.overlayClose} />
        </TouchableWithoutFeedback>
      )}
      <ModalInner close={close} heading={heading}>
        {children}
      </ModalInner>
    </Modal>
  );
};

export default ModalComponent;
