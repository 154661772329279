import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import breakPoint from "store/computed/breakPoints";
import { toggleWorkoutType } from "store/actions";
import WorkoutTypeToggle from "./WorkoutTypeToggle";

// TODO Remove if not used
const mapState = (state: state) => {
  const { workoutType } = state;
  return {
    breakPoint: breakPoint(state),
    workoutType,
  };
};

const mapDispatch = {
  toggleWorkoutType,
};

export default connect(mapState, mapDispatch)(WorkoutTypeToggle);
