import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import { prevActivity, skipCurrent } from "store/actions";
import SkipPrev from "./skip-prev";
import breakPoint from "store/computed/breakPoints";

const mapState = (state: state) => {
  return {
    breakPoint: breakPoint(state),
  };
};

const mapDispatch = {
  skipCurrent,
  prevActivity,
};

export default connect(mapState, mapDispatch)(SkipPrev);
