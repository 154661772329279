import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import EmailSignUp from "./email-sign-up";
import breakPoint from "store/computed/breakPoints";
import {
  setEmailAddress,
  sendSubscriptionRequest,
  setEmailSignupWasClosed,
} from "store/actions";

const mapState = (state: state) => {
  const { userEmailAddress, showEmailSignup, emailSubscriptions } = state;

  const oneHasBeenSubscribedSuccessfully =
    Object.values(emailSubscriptions).filter(
      status => status?.hasBeenSubscribedSuccessfully,
    ).length > 0;

  return {
    userEmailAddress,
    showEmailSignup,
    breakPoint: breakPoint(state),
    oneHasBeenSubscribedSuccessfully,
  };
};

const mapDispatch = {
  setEmailAddress,
  sendSubscriptionRequest,
  setEmailSignupWasClosed,
};

export default connect(mapState, mapDispatch)(EmailSignUp);
