import * as React from "react";

import { StyleSheet, View } from "react-native";

import Container from "../container";

import CONST from "components/CONST";

type Props = {
  breakPoint: boolean;
  breakPointSmall: boolean;
  children: React.ReactNode;
};

const Legal = ({ breakPoint, breakPointSmall, children }: Props) => {
  const headingFontSize = () => {
    if (!breakPointSmall) {
      return 30;
    }
    if (breakPoint) {
      return 48;
    }
    return 36;
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: breakPoint ? "row" : "column",
    },
    content: {
      width: breakPoint ? "50%" : undefined,
      flex: breakPoint ? undefined : 1,
      paddingBottom: 60,
    },
    headerContainer: {
      flexDirection: breakPoint ? undefined : "row",
      marginRight: breakPoint ? undefined : -20,
    },
    heading: {
      color: "white",
      fontFamily: CONST.fonts.latoBold,
      marginBottom: 30,
      fontSize: headingFontSize(),
      width: breakPoint ? undefined : "50%",
    },
  });

  return (
    <Container>
      <View style={styles.container}>
        <View style={styles.content}>{children}</View>
      </View>
    </Container>
  );
};

export default Legal;
