import { InputCustomRest, removeExerciseArgs } from "store/types";
import * as React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ViewProps,
} from "react-native";
import IconDragHandle from "components/global/components/icons/dragHandle";
import IconClose from "components/global/components/icons/close-icon";
import Input from "../input/rest-input";

const lightPurple = "#5F5576";

type Props = {
  removeExerciseCustom: (arg0: removeExerciseArgs) => void;
  time: InputCustomRest["time"];
  index: number;
};

const Rest = ({ time, removeExerciseCustom, index }: Props) => {
  return (
    <View style={styles.root}>
      <View style={styles.dragIconWrapper}>
        <IconDragHandle color={lightPurple} />
      </View>
      <Text style={styles.name}>Rest</Text>
      <Input first={time} isRest={true} index={index} />
      <View style={styles.closeIconWrapper}>
        <TouchableOpacity onPress={() => removeExerciseCustom({ index })}>
          <IconClose color={lightPurple} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#372A54",
    borderRadius: 12,
    padding: 20,
  },
  dragIconWrapper: {
    paddingLeft: 15,
    paddingRight: 22,
    marginRight: 2,
  },
  closeIconWrapper: {
    paddingLeft: 42,
  },
  name: {
    color: "white",
    marginRight: "auto",
    fontSize: 16,
    width: 123, // Used by the mins / secs input to ensure consistent sizes
  },
});

export default Rest;
