import * as React from "react";
import { connect } from "react-redux";

import { socialShareModalToggle } from "store/actions";

import ShareWorkoutBtn from "./share-workout-btn";

const mapDispatch = {
  socialShareModalToggle,
};

export default connect(null, mapDispatch)(ShareWorkoutBtn);
