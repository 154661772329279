import * as React from "react";
import * as Speech from "expo-speech";

type Props = {
  sound: boolean;
};

const MakeSoundInvisible = ({ sound }: Props) => {
  React.useEffect(() => {
    if (sound) {
      Speech.speak("Finished");
    }
  }, []);

  return null;
};

export default MakeSoundInvisible;
