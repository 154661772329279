import { Pages } from "application/types";

import * as React from "react";
import { StyleSheet, View } from "react-native";

import DesktopLink from "./link";
import IconWorkout from "components/global/components/icons/workout";
import IconAbout from "components/global/components/icons/about";
import IconStar from "components/global/components/icons/star";
import DownloadAppLink from "./downloadAppLink";
import useCurrentPage from "components/global/hooks/useCurrentPage";

const styles = StyleSheet.create({
  container: {
    marginLeft: "auto",
    flexDirection: "row",
  },
});

const LinksDesktop = () => {
  const links = [
    {
      text: "Choose",
      Icon: IconWorkout,
      goTo: Pages.choose,
    },
    {
      text: "Saved",
      Icon: IconStar,
      goTo: Pages.saved,
    },
    {
      text: "About",
      Icon: IconAbout,
      goTo: Pages.about,
    },
  ];

  const currentPage = useCurrentPage();

  return (
    <View style={styles.container}>
      {links.map((item, index) => {
        return (
          <DesktopLink
            key={index}
            text={item.text}
            Icon={item.Icon}
            goTo={item.goTo}
            isCurrentPage={currentPage === item.goTo}
          />
        );
      })}
      <DownloadAppLink />
    </View>
  );
};

export default LinksDesktop;
