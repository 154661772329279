import { Platform } from "react-native";

export default {
  // Sizes
  sizes: {
    viewportHorizontalPaddingMobile: 24,
    viewportHorizontalPaddingDesktop: 48,

    viewportTopPadding: 36,
  },

  buttonBorderRadius: 8,

  colorChooseCtaGreen: "#34A873",
  colorDarkBlueBackground: "#110133",
  colorFontGrey: "#232127",
  colorBtnSecondary: "#281A47",
  colorOverlay: "rgba(0,0,0,0.5)",
  colorBlueInputBackground: "#241543",
  colorHeaderMessageBackground: "#160142",

  colorActive: "#17163D",
  colorChill: "#171447",
  colorPlaceholderText: "#C3BFCC",

  workoutColors: {
    backgroundWarmUp: "rgb(23, 20, 71)",
    backgroundWarmUpTransparent: "rgba(23, 20, 71,0)",
    circleWarmUp: "#1D2559",
    backgroundProgressIndicatorWarmUp: "rgb(37, 62, 111)",
    backgroundActive: "rgb(23, 22, 61)",
    backgroundActiveTransparent: "rgba(23, 22, 61,0)",
    circleActive: "#1C2946",
    backgroundProgressIndicatorActive: "#234353",
    backgroundRest: "rgb(27, 9, 70)",
    backgroundRestTransparent: "rgba(27, 9, 70,0)",
    circleRest: "#241057",
    backgroundProgressIndicatorRest: "#342869",
  },

  focusedBoxShadow: "0 0 3px 3px #0641AB",

  // Fonts
  fonts: {
    latoRegular: "LatoRegular",
    latoBold: "LatoBold",
  },
};

export const preventTabbing = Platform.OS === "web" && { tabIndex: "-1" };
