import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import breakPoint from "store/computed/breakPoints";
import ModalInner from "./modal-inner";

const mapState = (state: state) => {
  const { height } = state;
  return {
    height,
    breakPoint: breakPoint(state),
  };
};

export default connect(mapState)(ModalInner);
