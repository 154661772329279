import * as React from "react";
import { connect } from "react-redux";
import { state } from "store/types";
import breakPoint from "store/computed/breakPoints";
import ChoosePage from "./choose-page";

const mapState = (state: state) => {
  const { workoutType } = state;
  return {
    workoutType,
    breakPoint: breakPoint(state),
  };
};

export default connect(mapState)(ChoosePage);
