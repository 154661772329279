import { inputsWithoutIds, state, RoutineLength } from "store/types";

const hiitRoutineLength = ({
  secondsExercise,
  secondsRest,
  rounds,
  exercises,
}: state["inputsHiit"] | inputsWithoutIds): RoutineLength => {
  const set = secondsExercise + secondsRest;
  // @ts-ignore
  const exercisesClean = exercises.filter(item => {
    // If the type is an object then the text property may be empty.
    if (typeof item === "object") {
      return item.text;
    }
    // If it's a string then just check the string has a value
    if (typeof item === "string") {
      return item;
    }
    // This condition should never be met.
    console.error("A condition was missed");
    return item;
  });

  return {
    isAccurate: true,
    seconds: set * exercisesClean.length * rounds - secondsRest,
  };
};

function customRoutineLength({
  routine,
  rest,
  rounds,
}: state["inputsCustom"]): RoutineLength {
  const singleRoundDuration = routine.reduce<{
    isAccurate: boolean;
    seconds: number;
  }>(
    (acc, input) => {
      if (
        !input.isRest &&
        (input.name.length === 0 ||
          (input.repeat.format !== "max" && input.repeat.amount === 0))
      ) {
        return acc;
      }
      if (input.isRest) {
        return {
          seconds: acc.seconds + input.time,
          isAccurate: acc.isAccurate,
        };
      } else if (input.repeat.format === "time") {
        return {
          ...acc,
          seconds: acc.seconds + input.repeat.amount,
        };
      }

      return {
        ...acc,
        isAccurate: false,
      };
    },
    {
      seconds: 0,
      isAccurate: true,
    },
  );
  return {
    isAccurate: singleRoundDuration.isAccurate,
    seconds:
      singleRoundDuration.seconds > 0
        ? singleRoundDuration.seconds * rounds + (rounds - 1) * rest
        : 0,
  };
}

/**
 * Routine length calculator - it accepts a specific
 * routine input in order to be reusable (eg. to calculate
 * length of a popular workout in the popular workouts modal).
 *
 * @param inputs A specific routine to calculate the length of
 */
export default function routineLength(
  inputs: state["inputsHiit"] | state["inputsCustom"] | inputsWithoutIds,
): RoutineLength {
  if ("routine" in inputs) {
    return customRoutineLength(inputs);
  }
  return hiitRoutineLength(inputs);
}
