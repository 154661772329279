import { Pages } from "application/types";

import * as React from "react";

import {
  LayoutChangeEvent,
  Linking,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useSafeArea } from "react-native-safe-area-context";

import CONST from "components/CONST";
import VARS from "application/VARS";
import STYLES from "../STYLES";

import Link from "components/global/components/link";
import Container from "../container";
import ContactIcon from "components/global/components/icons/contact";
import TwitterIcon from "components/global/components/icons/twitter";
import RedditIcon from "components/global/components/icons/reddit";
import AboutIllustration from "./about-illustration";
import SetMetaTags from "components/global/invisible/setMetaTags";

type Props = {
  breakPoint: boolean;
  breakPointSmall: boolean;
};

const ICON_WIDTH = 22;

const LegalLink = ({ breakPoint }: { breakPoint: boolean }) => {
  const safeArea = useSafeArea();
  const url = Platform.OS === "web" ? Pages.legalWeb : Pages.legalApp;
  const paddingBottom = safeArea.bottom ? safeArea.bottom + 12 : 26;
  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      paddingHorizontal: 12,
      paddingBottom: paddingBottom,
      backgroundColor: CONST.colorDarkBlueBackground,
    },
    containerBreakPoint: {
      position: "absolute",
      // bottom: 0,
      left: 0,
      right: 0,
      top: "100%",
      backgroundColor: "transparent",
      // paddingBottom: 34,
      paddingBottom: 0,
      paddingTop: 20,
    },
    text: {
      color: "white",
      fontFamily: CONST.fonts.latoRegular,
      fontSize: 18,
      textDecorationLine: "underline",
      textDecorationStyle: "solid",
      textDecorationColor: "white",
    },
  });
  return (
    <View style={[styles.container, breakPoint && styles.containerBreakPoint]}>
      <Link goTo={url}>
        <Text style={styles.text}>Privacy Policy</Text>
      </Link>
      {Platform.OS === "web" && (
        <Link goTo={Pages.legalDisclaimer}>
          <Text style={[styles.text, { marginLeft: 20 }]}>
            Legal disclaimer
          </Text>
        </Link>
      )}
    </View>
  );
};

const About = ({ breakPoint, breakPointSmall }: Props) => {
  const [illustrationWidth, setIllustrationWidth] = React.useState<
    undefined | number
  >(undefined);
  const nativeLayout =
    Platform.OS === "web"
      ? null
      : {
          onLayout: (e: LayoutChangeEvent) =>
            setIllustrationWidth(e.nativeEvent.layout.width),
        };

  const headingFontSize = () => {
    if (!breakPointSmall) {
      return 30;
    }
    if (breakPoint) {
      return 48;
    }
    return 36;
  };

  const styles = StyleSheet.create({
    row: {
      flexDirection: "row",
      alignItems: "center",
    },
    iconWrap: {
      width: 22,
      marginRight: 12,
    },
    container: {
      flex: 1,
      flexDirection: breakPoint ? "row" : "column",
      // backgroundColor: "tomato",
    },
    content: {
      width: breakPoint ? "50%" : undefined,
      flex: breakPoint ? undefined : 1,
    },
    headerContainer: {
      flexDirection: breakPoint ? undefined : "row",
      marginRight: breakPoint ? undefined : -20,
    },
    headingFirstWord: {
      display: breakPoint ? undefined : "flex",
    },
    button: {
      borderRadius: CONST.buttonBorderRadius,
      backgroundColor: CONST.colorChooseCtaGreen,
      justifyContent: "center",
      paddingVertical: 14,
      paddingHorizontal: 28,
      marginRight: breakPoint ? "auto" : undefined,
    },
    buttonText: {
      color: "white",
      fontFamily: CONST.fonts.latoBold,
      fontSize: 16,
      textTransform: "uppercase",
      textAlign: "center",
    },
    heading: {
      color: "white",
      fontFamily: CONST.fonts.latoBold,
      marginBottom: 30,
      fontSize: headingFontSize(),
      width: breakPoint ? undefined : "50%",
    },
    lastContentItem: {
      marginBottom: breakPoint ? 0 : 48,
      marginTop: breakPoint ? undefined : "auto",
    },
  });

  return (
    <Container>
      <>
        <SetMetaTags title="Love HIIT - About" />
        <View style={styles.container}>
          <View style={styles.content}>
            <View style={styles.headerContainer}>
              <Text style={styles.heading}>
                <Text style={styles.headingFirstWord}>About </Text>Love HIIT
              </Text>
              {!breakPoint && (
                <View
                  {...nativeLayout}
                  style={{
                    width: "50%",
                    justifyContent: "flex-end",
                  }}
                >
                  <AboutIllustration width={illustrationWidth} />
                </View>
              )}
            </View>

            {!breakPoint && (
              <LinearGradient
                style={{
                  height: 100,
                  marginTop: -20,
                  zIndex: -1,
                  marginLeft: -20,
                  marginRight: -20,
                  marginBottom: -50,
                }}
                colors={["#261845", "#110133"]}
              />
            )}

            <Text style={[STYLES.text, STYLES.marginDefault]}>
              Love HIIT is exercise timer that let's you share&nbsp;workouts.
            </Text>
            <Text style={[STYLES.text, STYLES.marginDefault]}>
              I built that app to stay motivated to keep fit during
              lock&nbsp;down.
            </Text>
            <Text style={[STYLES.text, STYLES.marginLarge]}>
              It's a work in progress so if you have any feedback please
              say&nbsp;hi!
            </Text>

            <TouchableOpacity
              onPress={() => Linking.openURL(`mailto:${VARS.email}`)}
              style={[styles.row, STYLES.marginDefault]}
            >
              <View style={styles.iconWrap}>
                <ContactIcon isDark={false} size={ICON_WIDTH} />
              </View>
              <Text style={STYLES.text}>{VARS.email}</Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => Linking.openURL(`https://twitter.com/jchetters`)}
              style={[styles.row, STYLES.marginDefault]}
            >
              <View style={styles.iconWrap}>
                <TwitterIcon size={ICON_WIDTH} />
              </View>
              <Text style={STYLES.text}>twitter.com/jchetters</Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() =>
                Linking.openURL(`https://www.reddit.com/r/LoveHIIT/`)
              }
              style={[styles.row, { marginBottom: 48 }]}
            >
              <View style={styles.iconWrap}>
                <RedditIcon size={ICON_WIDTH} />
              </View>
              <Text style={STYLES.text}>reddit.com/r/LoveHIIT</Text>
            </TouchableOpacity>

            <View style={styles.lastContentItem}>
              <Link goTo={Pages.choose}>
                <View style={styles.button}>
                  <Text style={styles.buttonText}>Choose Workout</Text>
                </View>
              </Link>
            </View>
          </View>

          {breakPoint && (
            <View
              {...nativeLayout}
              style={{
                marginTop: "auto",
                width: "50%",
                marginLeft: "auto",
                marginRight: breakPoint
                  ? -CONST.sizes.viewportHorizontalPaddingDesktop
                  : -CONST.sizes.viewportHorizontalPaddingMobile,
              }}
            >
              <AboutIllustration width={illustrationWidth} />
            </View>
          )}
        </View>
        <LegalLink breakPoint={breakPoint} />
      </>
    </Container>
  );
};

export default About;
