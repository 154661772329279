import * as React from "react";
import Svg, { Path } from "react-native-svg";

const HamburgerIcon = () => {
  return (
    <Svg width={26} height={18} viewBox="0 0 26 18" fill="none">
      <Path
        d="M25.5 2.75V.25H.5v2.5h25zm0 5v2.5H.5v-2.5h25zm0 7.5v2.5H.5v-2.5h25z"
        fill="#fff"
      />
    </Svg>
  );
};

export default HamburgerIcon;
