import React from "react";
import Constants from "expo-constants";
import NoSleep from "nosleep.js";

const NoSleepComponent = () => {
  if (Constants.deviceName === "Chrome") {
    return null;
  }

  React.useEffect(() => {
    const noSleep = new NoSleep();
    document.addEventListener(
      "click",
      function enableNoSleep() {
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.enable();
      },
      false,
    );
  }, []);

  return null;
};

export default NoSleepComponent;
