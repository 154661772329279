import { saveWorkoutModalToggle } from "store/actions";
import * as React from "react";
import ModalComponent from "components/global/components/modal";
import { useDispatch } from "react-redux";
import ModalContent from "./ModalContent";

const Modal = () => {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(saveWorkoutModalToggle({ value: false }));
  };

  return (
    <ModalComponent isOpen close={close} heading="Save workout">
      <ModalContent />
    </ModalComponent>
  );
};

export default Modal;
