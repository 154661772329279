import { Pages } from "application/types";

import * as React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { Path, Svg } from "react-native-svg";
import useCustomNav from "components/global/hooks/useCustomNav";
import CONST from "components/CONST";
import { preventTabbing } from "application/components/CONST";

type Props = {
  breakPoint: boolean;
  resetWorkout: () => void;
};

const Back = ({ breakPoint, resetWorkout }: Props) => {
  const navigation = useCustomNav();

  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
    },
    text: {
      color: "white",
      fontSize: 16,
      lineHeight: 16,
      fontFamily: CONST.fonts.latoBold,
      marginLeft: 12,
      display: breakPoint ? undefined : "none",
    },
  });

  return (
    <TouchableOpacity
      accessibilityLabel="Go back to choose your workout"
      onPress={() => {
        resetWorkout();
        navigation.goTo(Pages.choose);
      }}
      style={styles.container}
      {...preventTabbing}
    >
      <Svg width={20} height={20} {...preventTabbing}>
        <Path
          fill="white"
          d="M.44 2.56A1.5 1.5 0 1 1 2.56.44L9 6.878l6.44-6.44a1.5 1.5 0 0 1 2.12 2.122L11.122 9l6.44 6.44a1.5 1.5 0 0 1-2.122 2.12L9 11.122l-6.44 6.44a1.5 1.5 0 1 1-2.12-2.122L6.878 9 .439 2.56z"
          {...preventTabbing}
        />
      </Svg>
      <Text style={styles.text} accessible={false}>
        Stop workout
      </Text>
    </TouchableOpacity>
  );
};

export default Back;
