import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";

const BackIcon = () => {
  return (
    <Svg
      width={24}
      height={22}
      viewBox="0 0 24 22"
      fill="none"
      {...preventTabbing}
    >
      <Path
        {...preventTabbing}
        d="M5.018 12.25l7.866 7.866-1.768 1.768L.232 11 11.116.116l1.768 1.768L5.018 9.75H23.25v2.5H5.018z"
        fill="#fff"
      />
    </Svg>
  );
};

export default BackIcon;
