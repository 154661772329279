import { connect } from "react-redux";
import { state } from "store/types";
import InputNumericContainer from "./input-numeric-container";

// TODO Remove this as the prop isn't used.
const mapState = ({ isLandscape }: state) => {
  return {
    isLandscape,
  };
};

export default connect(mapState)(InputNumericContainer);
