import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";
import CONST from "./CONST";

type Props = {
  isDark: boolean;
};

const IconContact = ({ isDark }: Props) => {
  const { darkColor } = CONST;
  return (
    <Svg
      width={19}
      height={23}
      viewBox="0 0 19 23"
      fill="none"
      {...preventTabbing}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.507 7.413a4.034 4.034 0 00-1.92-.48h-.046c-.582 0-1.156.19-1.806.443l-.198.079c-.252.1-.526.208-.78.292-.328.108-.718.21-1.126.21-.74 0-1.547-.299-2.14-.519l-.078-.028c-.704-.261-1.167-.42-1.497-.426a4.505 4.505 0 00-2.01.666m11.6-.237c-.82.932-1.623 2.319-1.623 4.274 0 2.862 1.477 4.457 2.582 5.295a15.53 15.53 0 01-.852 1.582c-.469.754-.917 1.289-1.31 1.625-.403.345-.66.405-.767.405-.32 0-.537-.096-1.029-.314l-.15-.067c-.592-.26-1.349-.55-2.483-.55-.814 0-1.578.285-2.153.5-.023.01-.046.018-.068.026-.654.244-1.1.397-1.522.395-.09 0-.419-.09-.945-.622-.492-.497-1.03-1.256-1.542-2.242C2.626 15.756 2 13.477 2 11.97c0-2.202.71-3.56 1.907-4.32m9.65-2.718c.467-.008 2.633.038 4.192 1.73l.709.77-.8.673c-.79.666-1.775 1.766-1.775 3.582 0 2.51 1.541 3.584 2.355 4.084l.728.445-.326.789a18.419 18.419 0 01-1.33 2.615M13.558 4.932h-.016c-.991 0-1.873.323-2.534.582l-.242.095c-.24.095-.442.175-.637.24-.254.083-.406.109-.497.109-.337 0-.809-.16-1.523-.424l-.089-.033c-.589-.218-1.388-.515-2.116-.517H5.874c-.123.003-1.59.058-3.04.978C.887 7.199 0 9.316 0 11.969c0 1.92.752 4.518 1.868 6.67.574 1.108 1.223 2.05 1.897 2.73.638.645 1.453 1.21 2.355 1.215.825.004 1.597-.284 2.177-.5.018-.008.037-.014.055-.021.658-.246 1.102-.4 1.522-.4.743 0 1.21.175 1.676.38l.157.072c.447.202 1.058.48 1.829.48.775 0 1.48-.384 2.068-.886.599-.512 1.172-1.228 1.707-2.089"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.462.1c1.05-.219 1.97.595 1.97 1.614 0 2.83-2.137 5.246-4.93 5.286l-.868.013-.134-.858a5.617 5.617 0 01-.067-.869c0-2.522 1.687-4.7 4.03-5.187zm-1.996 4.698C10.63 3.572 11.41 2.6 12.4 2.2c-.164 1.23-.941 2.196-1.935 2.598z"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconContact;
