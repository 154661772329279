import * as React from "react";
import { connect } from "react-redux";
import { state } from "store/types";
import { countdownTick } from "store/actions";
import CountdownTickInvisible from "./countdown-tick-invisible";

const mapState = (state: state) => {
  const { paused } = state;
  return {
    paused,
  };
};

const mapDispatch = {
  countdownTick,
};

export default connect(mapState, mapDispatch)(CountdownTickInvisible);
