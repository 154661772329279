import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useSafeArea } from "react-native-safe-area-context";

import CONST from "components/CONST";
import SocialShareButton from "./social-share-button";
import SaveWorkout from "./saveWorkout";
import GoButton from "./go";

type Props = {
  mediaQuery: boolean[];
  breakPoint: boolean;
};

const Footer = ({ mediaQuery, breakPoint }: Props) => {
  const safeArea = useSafeArea();

  const {
    viewportHorizontalPaddingMobile,
    viewportHorizontalPaddingDesktop,
  } = CONST.sizes;

  const widthMediaQuery = (styles: any[]) => {
    if (mediaQuery[0]) {
      return styles[0];
    }
    if (mediaQuery[1]) {
      return styles[1] || styles[0];
    }
    if (mediaQuery[2]) {
      return styles[2] || styles[1] || styles[0];
    }
    return styles[3] || styles[2] || styles[1] || styles[0];
  };

  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      paddingHorizontal: widthMediaQuery([
        viewportHorizontalPaddingMobile / 2,
        viewportHorizontalPaddingMobile,
        viewportHorizontalPaddingMobile,
        viewportHorizontalPaddingDesktop,
      ]),
      paddingTop: 15,
      paddingBottom: safeArea.bottom + 15,
    },
    col1: {
      flexDirection: "row",
      paddingRight: breakPoint ? 20 : 10,
    },
    col2: {
      flexDirection: "row",
      paddingRight: breakPoint ? 20 : 10,
    },
    col3: {
      flexGrow: 1,
      flexDirection: "row",
      maxWidth: breakPoint ? "50%" : undefined,
      marginLeft: "auto",
    },
  });

  const sharedStyles = StyleSheet.create({
    button: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: CONST.colorBtnSecondary,
      borderRadius: CONST.buttonBorderRadius,
      overflow: "hidden",
      padding: 15,
      paddingHorizontal: breakPoint ? 40 : undefined,
      minWidth: 60,
    },
    buttonText: {
      color: "white",
      textTransform: "uppercase",
      fontFamily: CONST.fonts.latoBold,
      fontSize: 17,
    },
    buttonText__withIcon: {
      marginLeft: 10,
      display: breakPoint ? undefined : "none",
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.col1}>
        <SaveWorkout sharedStyles={sharedStyles} />
      </View>
      <View style={styles.col2}>
        <SocialShareButton sharedStyles={sharedStyles} />
      </View>
      <View style={styles.col3}>
        <GoButton sharedStyles={sharedStyles} />
      </View>
    </View>
  );
};

export default Footer;
