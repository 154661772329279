import { state } from "store/types";
import { Pages } from "application/types";
import { useDispatch } from "react-redux";
import * as React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from "./STYLES";
import {
  deleteWorkout,
  setCustomWorkoutInputs,
  moveSavedWorkoutUp,
  moveSavedWorkoutDown,
} from "store/actions";
import useCustomNav from "components/global/hooks/useCustomNav";
import TimeFormat from "hh-mm-ss";
import routineLength from "store/utils/calculateRoutineUtil/routineLength";
import { formatRoutineLength } from "application/components/utils/formatSeconds";
import EditNameModal from "./EditNameModal";
import IconBin from "components/global/components/icons/bin";
import IconCog from "./IconCog";
import IconSelectChevron from "components/global/components/icons/select-chevron";
import CONST from "components/CONST";

interface Props {
  name: string;
  index: number;
  workout: state["inputsCustom"];
}
const WorkoutCustom = ({ name, index, ...props }: Props) => {
  const { workout } = props;

  const navigate = useCustomNav();
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const handleSubmit = () => {
    dispatch(setCustomWorkoutInputs(workout));
    navigate.goTo(Pages.choose);
  };

  const routine = workout.routine.map(item => {
    if (item.isRest) {
      return `Rest ${TimeFormat.fromS(item.time)}`;
    }

    const name =
      item.weight.amount === 0
        ? item.name
        : `${item.name} ${item.weight.amount}${item.weight.format}`;
    const repeat =
      item.repeat.format === "max"
        ? "maximum"
        : `${
            item.repeat.format === "time"
              ? TimeFormat.fromS(item.repeat.amount)
              : item.repeat.amount
          }${item.repeat.format === "reps" ? " reps" : ""}`;
    return `${name} ${repeat}`;
  });

  const timeToDisplay = formatRoutineLength(routineLength(workout));

  return (
    <>
      {modalIsOpen && (
        <EditNameModal
          close={() => setModalIsOpen(false)}
          name={name}
          index={index}
        />
      )}
      <View style={styles.wrapper}>
        <TouchableOpacity onPress={() => setModalIsOpen(true)}>
          <View style={styles.heading_row}>
            <Text style={styles.heading}>
              {name}
              <Text style={styles.workoutType}>(Custom)</Text>
            </Text>
            <View style={styles.cogIconWrapper}>
              <IconCog />
            </View>
          </View>
        </TouchableOpacity>

        <View style={styles.rowTimes}>
          <View style={styles.col}>
            <Text style={styles.value}>{workout.rounds}</Text>
            <Text style={styles.labelHIIT}>Rounds</Text>
          </View>

          {workout.rounds > 0 && (
            <View style={styles.col}>
              <Text style={styles.value}>{TimeFormat.fromS(workout.rest)}</Text>
              <Text style={styles.labelHIIT}>Rest btw. rounds</Text>
            </View>
          )}
        </View>

        <View style={styles.row_routine}>
          {routine.map((item, index) => {
            return (
              <View key={index} style={styles.valueRoutineWrapper}>
                <Text style={styles.value_routine_item}>{item}</Text>
                {routine.length !== index + 1 && (
                  <View style={styles.routineItemSeparator} />
                )}
              </View>
            );
          })}
        </View>

        <View style={[styles.row, styles.row_last]}>
          <TouchableOpacity
            onPress={() => dispatch(deleteWorkout({ index }))}
            style={[styles.button, styles.button__secondary]}
          >
            <IconBin />
            {/*<Text style={styles.buttonText}>Delete</Text>*/}
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => dispatch(moveSavedWorkoutUp({ index }))}
            style={[styles.button, styles.button__secondary]}
          >
            <IconSelectChevron
              color={CONST.colorDarkBlueBackground}
              size={18}
            />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => dispatch(moveSavedWorkoutDown({ index }))}
            style={[styles.button, styles.button__secondary]}
          >
            <IconSelectChevron
              color={CONST.colorDarkBlueBackground}
              size={18}
              direction={"down"}
            />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={handleSubmit}
            style={[styles.button, styles.button__go]}
          >
            <Text style={[styles.buttonText, styles.buttonText_go]}>
              {timeToDisplay} Select
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

export default WorkoutCustom;
