import { state } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import { overwriteSavedWorkout } from "store/actions";
import * as React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import CONST from "components/CONST";

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#382954",
    borderRadius: CONST.buttonBorderRadius,
    marginBottom: 6,
  },
  button_last: {
    marginBottom: 0,
  },
  buttonText: {
    color: "white",
    fontFamily: CONST.fonts.latoRegular,
    fontSize: 17,
    padding: 10,
  },
  buttonTextType: {
    fontSize: 11,
    marginLeft: 2,
  },
});

const Existing = () => {
  const dispatch = useDispatch();
  const savedWorkouts = useSelector((state: state) => state.savedWorkouts);

  return (
    <View>
      {savedWorkouts.map((item, index) => (
        <TouchableOpacity
          onPress={() => dispatch(overwriteSavedWorkout({ index }))}
          key={index}
          style={[
            styles.button,
            index === savedWorkouts.length - 1 && styles.button_last,
          ]}
        >
          <Text style={styles.buttonText}>
            {item.name}{" "}
            <Text style={styles.buttonTextType}>({item.workoutType})</Text>
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default Existing;
