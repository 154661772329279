import * as React from "react";
import { connect } from "react-redux";
import { customAddExercise, customAddRest } from "store/actions";
import AddExerciseOrRest from "./add-exercise-or-rest";

const mapDispatch = {
  customAddExercise,
  customAddRest,
};

export default connect(null, mapDispatch)(AddExerciseOrRest);
