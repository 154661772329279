import * as React from "react";
import { StyleSheet } from "react-native";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";

type Props = {
  color?: string;
  direction?: "down";
  size?: number;
};

const IconSelectChevron = ({ color, direction, size }: Props) => {
  const color2 = color || "#BDB8C6";

  const width = size ?? 8;
  const height = size ? (8 / 5) * size : 5;

  const styles = StyleSheet.create({
    root_down: {
      transform: [
        {
          scaleY: -1,
        },
      ],
    },
  });

  return (
    <Svg
      style={direction === "down" && styles.root_down}
      width={width}
      height={height}
      viewBox="0 0 8 5"
      {...preventTabbing}
    >
      <Path
        d="M.943 4.748L0 3.805 3.805 0l3.804 3.805-.942.943-2.862-2.862z"
        fill={color2}
        fillRule="evenodd"
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconSelectChevron;
