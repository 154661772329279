import * as React from "react";
import { View } from "react-native";

import Pages from "components/pages";

const Page = () => {
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <Pages />
    </View>
  );
};

export default Page;
