import * as React from "react";
import isEqual from "lodash/isEqual";
import { useSelector, useDispatch } from "react-redux";

import { sendSubscriptionRequest } from "application/store/actions";
import { state, EmailSubscriptionState } from "application/store/types";

const SUBSCRIPTION_RETRY_INTERVAL_MS = 15000; // 15 seconds

/**
 * This hook ensures that all email subscriptions that were not successful
 * are resubscribed at some point in time.
 */
export default function ensureEmailSubscriptionsWereSuccessful() {
  const dispatch = useDispatch();
  const emailSubscriptions = useSelector<
    state,
    { [emailAddress: string]: EmailSubscriptionState | undefined }
  >(state => state.emailSubscriptions, isEqual); // isEqual prevents unnecessary rerenders

  React.useEffect(() => {
    const pendingEmailAddresses = Object.keys(emailSubscriptions).filter(
      emailAddress => {
        const subscriptionState = emailSubscriptions[emailAddress];
        if (!subscriptionState) {
          // No entry may mean that email address has been unsubscribed/deleted.
          return false;
        }

        const {
          hasBeenSubscribedSuccessfully,
          isBeingSubscribed,
        } = subscriptionState;
        // The hook should try to subscribe an email address that
        // is neither being already subscribed (by eg. request triggered
        // by button press) nor already has been subscribed.
        return !hasBeenSubscribedSuccessfully && !isBeingSubscribed;
      },
    );

    if (pendingEmailAddresses.length > 0) {
      // If there are email addresses to subscribe
      // try to subscribe in an interval (ensures
      // that on an unstable connection we will
      // subscribe the email eventually).
      let interval = setInterval(() => {
        pendingEmailAddresses.forEach(emailAddress => {
          dispatch(sendSubscriptionRequest({ emailAddress }));
        });
      }, SUBSCRIPTION_RETRY_INTERVAL_MS);
      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, emailSubscriptions]);
}
