import { setLegalConsentArgs, state } from "store/types";

type Args = {
  state: state;
  action: setLegalConsentArgs;
};

const setLegalConsent = ({ state, action }: Args): state => {
  return {
    ...state,
    legalConsentGranted: action.value,
  };
};

export default setLegalConsent;
