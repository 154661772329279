import * as React from "react";
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import Constants from "expo-constants";

import CONST from "components/CONST";
import { toggleMobileMenuArgs } from "store/types";

type Props = {
  children: React.ReactNode;
  toggleMobileMenu: (arg0: toggleMobileMenuArgs) => void;
};

const styles = StyleSheet.create({
  overlay: {
    position: "absolute",
    zIndex: 2,
    elevation: 2,
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backgroundColor: CONST.colorOverlay,
    flexDirection: "column",
  },
  closeOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  content: {
    flex: 1,
    marginTop: Constants.statusBarHeight + 12,
    marginLeft: "auto",
    flexDirection: "column",
    backgroundColor: "white",
    width: 250,
    padding: 24,
    borderTopLeftRadius: 20,
  },
});

const WebOffCanvasMenu = ({ children, toggleMobileMenu }: Props) => {
  return (
    <View style={styles.overlay}>
      <TouchableWithoutFeedback
        onPress={() => toggleMobileMenu({ isOpen: false })}
      >
        <View style={styles.closeOverlay} />
      </TouchableWithoutFeedback>
      <View style={styles.content}>{children}</View>
    </View>
  );
};

export default WebOffCanvasMenu;
