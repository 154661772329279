import * as React from "react";
import { connect } from "react-redux";
import { state } from "store/types";
import breakPoint from "store/computed/breakPoints";
import WorkoutHIIT from "./WorkoutHIIT";

const mapState = (state: state) => {
  const { width } = state;
  return {
    width,
    breakPoint: breakPoint(state),
  };
};

export default connect(mapState)(WorkoutHIIT);
