import * as React from "react";
import { connect } from "react-redux";
import { state } from "store/types";
import { isResting as isRestingMemo, routineCurrent as routineCurrentMemo } from "store/computed/calculateRoutine";
import breakPoint from "store/computed/breakPoints";
import InProgressPage from "./in-progress-page";

const mapState = (state: state) => {
  const { warmUpIsFinished } = state;
  const routineCurrent = routineCurrentMemo(state);
  const isResting = isRestingMemo(state);
  return {
    warmUpIsFinished,
    isResting,
    routineCurrent,
    breakPoint: breakPoint(state),
    breakPointSmall: state.width < 470,
  };
};

export default connect(mapState)(InProgressPage);
