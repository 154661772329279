// import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import { resetWorkout } from "store/actions";
import GoAgain from "./go-again";

const mapDispatch = {
  resetWorkout,
};

export default connect(null, mapDispatch)(GoAgain);
