import * as React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import Constants from "expo-constants";

import CONST from "components/CONST";
import useCustomNav from "components/global/hooks/useCustomNav";
import ScrollTop from "components/global/invisible/scrollTop";

import Header from "components/global/components/header";
import BackIcon from "components/global/components/icons/back";

type Props = {
  children: React.ReactElement;
  // heading: string;
  breakPoint: boolean;
};

// TODO Make heading semantic

const Container = ({ children, breakPoint }: Props) => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: CONST.colorDarkBlueBackground,
    },
    container2: {
      flex: 1,
      paddingTop: Constants.statusBarHeight + CONST.sizes.viewportTopPadding,
    },
    container3: {
      paddingHorizontal: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
      paddingTop: breakPoint ? 48 : undefined,
      flex: 1,
    },
    backLink: {
      flexDirection: "row",
      marginBottom: 48,
    },
    backLinkText: {
      fontFamily: CONST.fonts.latoBold,
      fontSize: 16,
      color: "white",
      marginLeft: 10,
      display: breakPoint ? undefined : "none",
    },
  });

  const navigate = useCustomNav();

  return (
    <>
      <ScrollTop />
      <View style={styles.container}>
        <ScrollView
          keyboardShouldPersistTaps="always"
          scrollEventThrottle={16}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View style={styles.container2}>
            <Header />
            <View style={styles.container3}>
              {!breakPoint && (
                <TouchableOpacity
                  onPress={() => {
                    navigate.goBack();
                  }}
                  style={styles.backLink}
                  accessibilityLabel="Back"
                >
                  <BackIcon />
                  <Text style={styles.backLinkText} accessible={false}>
                    Back
                  </Text>
                </TouchableOpacity>
              )}

              {/*<Text style={styles.heading}>{heading}</Text>*/}
              {children}
            </View>
            {breakPoint && (
              <LinearGradient
                style={{
                  height: 100,
                  width: "100%",
                  marginTop: -20,
                  zIndex: -1,
                }}
                colors={["#3B2E57", "#261745"]}
              />
            )}
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default Container;
