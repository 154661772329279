import { state } from "application/store/types";
import * as React from "react";
import { useSelector } from "react-redux";
import Modal from "./Modal";

const SaveWorkoutModal = () => {
  const saveModalIsOpen = useSelector((state: state) => state.saveModalIsOpen);
  if (!saveModalIsOpen) return null;

  return <Modal />;
};

export default SaveWorkoutModal;
