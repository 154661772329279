import { socialShareModalToggleArgs } from "store/types";

import * as React from "react";
import {
  Clipboard,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
// import { preventTabbing } from "application/components/CONST";
import CONST from "components/CONST";
// import TwitterIcon from "components/global/components/icons/twitter";
import Modal from "components/global/components/modal";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
// import { Linking } from "expo";
// import { Svg, Path, Rect } from "react-native-svg";

type Props = {
  socialShareModalToggle: (arg0: socialShareModalToggleArgs) => void;
  sharableUrl: string;
  shareModalIsOpen: boolean;
};

// TODO Make heading semantic
// TODO Test WhatsApp link, maybe use https://sharingbuttons.io/ instead
const SharableUrlModal = ({
  sharableUrl,
  socialShareModalToggle,
  shareModalIsOpen,
}: Props) => {
  const styles = StyleSheet.create({
    body: {
      flexDirection: "column",
    },
    subHeading: {
      fontFamily: CONST.fonts.latoRegular,
      textTransform: "uppercase",
      marginBottom: 20,
    },
    socialContainer: {
      borderBottomColor: "#E5E7E7",
      borderBottomWidth: 1,
      width: "auto",
      marginBottom: 20,
      paddingBottom: 20,
    },
    socialRow: {
      flexDirection: "row",
      flexWrap: "wrap",
      marginRight: -10,
    },
    socialItem: {
      width: "16.66%",
      paddingRight: 10,
    },
    socialLink: {
      marginRight: 10,
    },
    text: {
      color: CONST.colorFontGrey,
      fontFamily: CONST.fonts.latoRegular,
    },
    copyContainer: {
      width: "auto",
    },
    copyBtn: {
      borderRadius: 8,
      backgroundColor: CONST.colorChooseCtaGreen,
      padding: 20,
    },
    copyBtnTxt: {
      color: "white",
      textTransform: "uppercase",
      fontFamily: CONST.fonts.latoBold,
      textAlign: "center",
    },
    copyText: {
      backgroundColor: "white",
      borderColor: "#E8E8E8",
      borderWidth: 1,
      padding: 20,
      borderRadius: 8,
      marginBottom: 20,
    },
  });

  const copyToClipboard = () => {
    Clipboard.setString(sharableUrl);
  };

  const shareButtonProps = {
    url: sharableUrl,
    style: {
      display: "flex",
    },
  };
  const shareIconProps = {
    round: false,
    borderRadius: 12,
    // maxWidth: "100%",
    height: "auto",
  };

  const socials = [
    <TwitterShareButton {...shareButtonProps}>
      <TwitterIcon {...shareIconProps} />
    </TwitterShareButton>,
    <FacebookShareButton {...shareButtonProps}>
      <FacebookIcon {...shareIconProps} />
    </FacebookShareButton>,
    <RedditShareButton {...shareButtonProps}>
      <RedditIcon {...shareIconProps} />
    </RedditShareButton>,
    <EmailShareButton {...shareButtonProps}>
      <EmailIcon {...shareIconProps} />
    </EmailShareButton>,
    <WhatsappShareButton {...shareButtonProps}>
      <WhatsappIcon {...shareIconProps} />
    </WhatsappShareButton>,
    <TelegramShareButton {...shareButtonProps}>
      <TelegramIcon {...shareIconProps} />
    </TelegramShareButton>,
  ];

  return (
    <Modal
      isOpen={shareModalIsOpen}
      close={() => socialShareModalToggle({ isOpen: false })}
      heading="Share workout"
    >
      <View style={styles.body}>
        <View style={styles.socialContainer}>
          <Text style={styles.subHeading}>Socials</Text>
          <View style={styles.socialRow}>
            {socials.map((item, i) => {
              const isLast = i + 1 === socials.length;
              return (
                <View
                  key={i}
                  style={[
                    styles.socialItem,
                    // isLast && styles.socialItemIsLast
                  ]}
                >
                  {item}
                </View>
              );
            })}
          </View>
        </View>

        <View style={styles.copyContainer}>
          <Text style={styles.subHeading}>Share link</Text>

          <TouchableOpacity onPress={() => copyToClipboard()}>
            <Text numberOfLines={1} style={[styles.text, styles.copyText]}>
              {sharableUrl}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.copyBtn}
            onPress={() => copyToClipboard()}
          >
            <Text style={[styles.text, styles.copyBtnTxt]}>
              Copy to clipboard
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default SharableUrlModal;
