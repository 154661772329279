import { state, RoutineLength } from "application/store/types";
import { Pages } from "application/types";
import * as React from "react";
import { Platform, StyleSheet, Text, TouchableOpacity } from "react-native";
import { Audio } from "expo-av";
import useCustomNav from "components/global/hooks/useCustomNav";
import { formatRoutineLength } from "components/utils/formatSeconds";
import CONST from "components/CONST";

type Props = {
  resetWorkout: () => void;
  soundHackActivatedToggle: (arg0: { value: boolean }) => void;
  atLeastOneExercise: boolean;
  routineLength: RoutineLength;
  sharedStyles: any;
  soundHackActivated: boolean;
  workoutType: state["workoutType"];
  breakPoint: boolean;
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: CONST.colorChooseCtaGreen,
    flexGrow: 1,
  },
});

// Sound hack needed
// https://stackoverflow.com/questions/61949934/expo-speech-not-working-on-some-ios-devices
// https://github.com/expo/expo/issues/8235

const Footer = ({
  resetWorkout,
  atLeastOneExercise,
  routineLength,
  sharedStyles,
  soundHackActivated,
  soundHackActivatedToggle,
  workoutType,
  breakPoint,
}: Props) => {
  const navigate = useCustomNav();
  const timeFormatted = formatRoutineLength(routineLength);

  const soundObject = new Audio.Sound();
  const enableSound = async () => {
    if (!soundHackActivated && Platform.OS === "ios") {
      soundHackActivatedToggle({ value: true });
      // This seems to break expo-speech on Chrome and Safari on iOS
      await Audio.setAudioModeAsync({
        playsInSilentModeIOS: true,
      });
      await soundObject.loadAsync(require("./noSound.mp3"));
      await soundObject.playAsync().catch(error => console.error(error));
    }
  };

  const buttonText =
    workoutType === "HIIT"
      ? `${timeFormatted} HIIT Me`
      : breakPoint
      ? `${timeFormatted} Start workout`
      : `${timeFormatted} Start`;

  return (
    <TouchableOpacity
      onPress={() => {
        enableSound();
        if (!atLeastOneExercise) {
          alert("You must enter at least one exercise");
          return;
        }
        resetWorkout();
        navigate.goTo(Pages.go);
      }}
      style={[sharedStyles.button, styles.button]}
    >
      <Text style={[sharedStyles.buttonText]}>{buttonText}</Text>
    </TouchableOpacity>
  );
};

export default Footer;
