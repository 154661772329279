import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { StyleProp, ViewStyle } from "react-native";
import { preventTabbing } from "application/components/CONST";

type Props = {
  style: StyleProp<ViewStyle>;
};

const IconPhone = ({ style }: Props) => {
  return (
    <Svg
      width={12}
      height={20}
      viewBox="0 0 12 20"
      fill="none"
      {...preventTabbing}
      style={style}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.167.833H1.833C.913.833.167 1.58.167 2.5v15c0 .92.746 1.667 1.666 1.667h8.334c.92 0 1.666-.747 1.666-1.667v-15c0-.92-.746-1.667-1.666-1.667zM1.833 2.5h2.5c0 .46.373.833.834.833h1.666c.46 0 .834-.373.834-.833h2.5v15H1.833v-15z"
        fill="white"
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconPhone;
