import { setEmailAddressArgs, sendSubscriptionRequestArgs } from "store/types";
import * as React from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import * as EmailValidator from "email-validator";
import CONST from "components/CONST";
import CloseIcon from "components/global/components/icons/close-icon";
import { useSafeArea } from "react-native-safe-area-context"; // TODO Is this hacky?

/*
TODO Test this doesn't mess up the fixed position footer.
*/

interface Props {
  breakPoint: boolean;
  showEmailSignup: boolean;
  userEmailAddress: string | null;
  setEmailAddress: (args: setEmailAddressArgs) => void;
  sendSubscriptionRequest: (args: sendSubscriptionRequestArgs) => void;
  setEmailSignupWasClosed: () => void;
  oneHasBeenSubscribedSuccessfully: boolean;
}

const paddingVertical = 15;

const EmailSignUp = ({
  breakPoint,
  userEmailAddress,
  setEmailAddress,
  sendSubscriptionRequest,
  setEmailSignupWasClosed,
  showEmailSignup,
  oneHasBeenSubscribedSuccessfully,
}: Props) => {
  const safeArea = useSafeArea();

  const styles = StyleSheet.create({
    root: {
      paddingTop: paddingVertical + safeArea.top,
      paddingHorizontal: CONST.sizes.viewportHorizontalPaddingMobile,
      paddingBottom: paddingVertical,
      backgroundColor: CONST.colorHeaderMessageBackground,
      borderBottomColor: "white",
      borderBottomWidth: 1,
    },
    root_breakpoint: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingLeft: CONST.sizes.viewportHorizontalPaddingDesktop,
      paddingRight: CONST.sizes.viewportHorizontalPaddingDesktop + 60,
    },
    text: {
      color: "white",
      fontFamily: CONST.fonts.latoRegular,
    },
    heading: {
      fontSize: 18,
      marginBottom: 4,
      fontFamily: CONST.fonts.latoBold,
    },
    heading2: {
      fontSize: 16,
      opacity: 0.7,
      marginBottom: breakPoint ? 0 : 18,
    },
    ctaWrapper: {
      marginLeft: 12,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: CONST.colorChooseCtaGreen,
      paddingHorizontal: 18,
      borderRadius: CONST.buttonBorderRadius,
    },
    cta: {
      fontFamily: CONST.fonts.latoBold,
      textTransform: "uppercase",
    },
    input: {
      flexGrow: 1,
      borderWidth: 1,
      borderColor: "#473A61",
      backgroundColor: "#241543",
      borderRadius: CONST.buttonBorderRadius,
      padding: 8,
      color: "white",
      fontFamily: CONST.fonts.latoRegular,
      width: breakPoint ? 200 : undefined,
    },
    close: {
      position: "absolute",
      top: paddingVertical + 2 + safeArea.top,
      right: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
      zIndex: 1,
      elevation: 1, // This doesnt seem to be needed but Im adding it to be safe!
    },
  });

  const [typedEmailAddress, setTypedEmailAddress] = React.useState(
    userEmailAddress ?? "",
  );

  const submit = () => {
    if (!EmailValidator.validate(typedEmailAddress)) {
      alert("Your email address isn't valid");
    } else {
      setEmailAddress({ emailAddress: typedEmailAddress });
      sendSubscriptionRequest({ emailAddress: typedEmailAddress });
    }
  };

  if (!showEmailSignup || oneHasBeenSubscribedSuccessfully) return null;

  return (
    <View style={[styles.root, breakPoint && styles.root_breakpoint]}>
      <TouchableOpacity
        style={styles.close}
        onPress={() => setEmailSignupWasClosed()}
      >
        <Text style={[styles.text]}>
          <CloseIcon />
        </Text>
      </TouchableOpacity>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View style={{ flexGrow: 1 }}>
          {/*<Text style={[styles.text, styles.heading]}>*/}
          {/*  Big things are coming!*/}
          {/*</Text>*/}
          <Text style={[styles.text, styles.heading2]}>
            Sign up to stay in touch
          </Text>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
        }}
      >
        <TextInput
          placeholder={"Enter your email"}
          style={[styles.input]}
          value={typedEmailAddress}
          onChangeText={setTypedEmailAddress}
          onSubmitEditing={submit}
          returnKeyType="send"
          returnKeyLabel="Sign up"
          placeholderTextColor={"#918AA1"}
          keyboardType={"email-address"} /* TODO Check this on devices */
        />
        <TouchableOpacity style={styles.ctaWrapper} onPress={submit}>
          <Text style={[styles.text, styles.cta]}>Sign up</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default EmailSignUp;
