import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";
import CONST from "./CONST";

type Props = {
  isDark: boolean;
  size: number;
};

const IconContact = ({ isDark, size }: Props) => {
  const { darkColor } = CONST;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      {...preventTabbing}
    >
      <Path
        d="M2.5 2.5h15c.92 0 1.667.746 1.667 1.667v11.666c0 .92-.747 1.667-1.667 1.667h-15c-.92 0-1.667-.746-1.667-1.667V4.167c0-.92.747-1.667 1.667-1.667zm0 5.515v7.818h15V8.015l-7.5 3.75-7.5-3.75zm0-1.863l7.5 3.75 7.5-3.75V4.167h-15v1.985z"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconContact;
