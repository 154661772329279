import { state } from "application/store/types";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { showEmailSignup } from "store/actions";

// Note that the UI for the email signup isn't shown on native.

const ONE_WEEK_IN_MS = 604800000;

const CheckIfEmailSignupIsShown = () => {
  const dispatch = useDispatch();
  const legalConsentGranted = useSelector(
    (state: state) => state.legalConsentGranted,
  );
  const emailSignupWasClosedInMs = useSelector((state: state) =>
    state.emailSignupWasClosed
      ? new Date(state.emailSignupWasClosed).getTime()
      : null,
  );

  // TODO Check this works
  const anEmailSubWasSuccessful = useSelector((state: state) =>
    Object.values(state.emailSubscriptions).some(
      item => item?.hasBeenSubscribedSuccessfully,
    ),
  );

  React.useEffect(() => {
    // Dont show if legal consent hasn't been granted as there would be too much in the header.
    if (!legalConsentGranted) return;

    // Dont show if the email sign up was closed less than 2 weeks ago.
    if (emailSignupWasClosedInMs) {
      const timeAgoEmailSignupWasClosedInMs =
        Date.now() - emailSignupWasClosedInMs;
      const weeksAgo = timeAgoEmailSignupWasClosedInMs / ONE_WEEK_IN_MS;
      if (weeksAgo < 2) return;
    }

    // has the user already submitted this?
    if (anEmailSubWasSuccessful) return;

    dispatch(showEmailSignup());
  }, []);

  return null;
};

export default CheckIfEmailSignupIsShown;
