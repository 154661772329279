import * as React from "react";
import { Platform, View } from "react-native";
import { StatusBar } from "expo-status-bar";
import CONST from "../../CONST";
import { useFonts } from "@use-expo/font";

import Loader from "./loader";
import Page from "./page";
// Invisible
import SetScreenSizeInvisible from "components/global/invisible/set-screen-size-invisible";
import GetWorkoutFromUrlInvisible from "components/global/invisible/get-workout-from-url-invisible";
import RedirectOnPageLoadInvisible from "components/global/invisible/redirect-on-page-load-invisible";
import CheckIfEmailSignupIsShown from "components/global/invisible/check-if-email-signup-is-shown";
import NoSleep from "components/global/invisible/noSleep";
import HelmetMetaTags from "components/provider/root-component/helmetMetaTags";
// Visible
import SharableUrlModal from "components/global/components/sharable-url-modal";
import PopularWorkoutsModal from "components/global/components/popular-workouts-modal";
import SaveWorkoutModal from "components/global/components/save-workout-modal";
import LegalMessage from "components/global/components/legalMessage";
import EmailSignUp from "components/global/components/email-sign-up";

import MobileOffCanvasMenu from "components/global/components/mobile-off-canvas-menu";
import ensureEmailSubscriptionsWereSuccessful from "application/components/global/hooks/ensureEmailSubscriptionsWereSuccessful";
import StatusBarUnderlay from "./status-bar-underlay";

type Props = {
  width: number;
  height: number;
};

const RootComponent = ({ width, height }: Props) => {
  ensureEmailSubscriptionsWereSuccessful();

  const [isLoaded] = useFonts({
    [CONST.fonts.latoRegular]: require("application/font/Lato-Regular.ttf"),
    [CONST.fonts.latoBold]: require("application/font/Lato-Bold.ttf"),
  });

  const widthHasBeenMeasured = width !== 0;

  const appHadLoaded =
    Platform.OS === "web"
      ? widthHasBeenMeasured
      : isLoaded && widthHasBeenMeasured;

  // TODO Proper titles and description meta tags
  // Add  og:image:width and og:image:height

  return (
    <View
      // On Web we don't need to set the constant dimensions.
      // 1. This is mostly a workaround to be able to have the footer
      //    fixed at the bottom of the screen and this is handled on web
      //    with the "fixed" position.
      // 2. Screen dimensions tend to change more often on web (the navigation
      //    bars are constantly collapsing and extending).
      style={Platform.select({ web: { flex: 1 }, default: { width, height } })}
    >
      <HelmetMetaTags />
      <RedirectOnPageLoadInvisible />
      <SetScreenSizeInvisible />
      <GetWorkoutFromUrlInvisible />
      <CheckIfEmailSignupIsShown />
      <NoSleep />
      <StatusBar
        style="light"
        // Android-only, StatusBarUnderlay component
        // takes care of other platforms
        backgroundColor={CONST.colorBlueInputBackground}
      />
      {!appHadLoaded ? (
        <Loader />
      ) : (
        <>
          <LegalMessage />
          <EmailSignUp />
          <Page />
          <SharableUrlModal />
          <PopularWorkoutsModal />
          <SaveWorkoutModal />
          <MobileOffCanvasMenu />
        </>
      )}
      <StatusBarUnderlay />
    </View>
  );
};

export default RootComponent;
