import { socialShareModalToggleArgs } from "store/types";

import * as React from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import CONST from "components/CONST";
import buttonStylesGen from "../STYLES";

import ShareIcon from "components/global/components/icons/share-icon";

type Props = {
  shareNative?: () => void;
  shareWeb?: (arg0: socialShareModalToggleArgs) => void;
  breakPoint: boolean;
};

const ShareWorkout = ({ breakPoint, shareWeb, shareNative }: Props) => {
  const buttonStyles = buttonStylesGen();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: CONST.colorChooseCtaGreen,
      marginBottom: breakPoint ? 0 : 20,
      marginRight: breakPoint ? 10 : 0,
    },
  });

  const handleShare = () => {
    if (Platform.OS === "web" && shareWeb) {
      shareWeb({ isOpen: true });
      return;
    }
    if (shareNative) {
      shareNative();
    }
  };

  return (
    <View style={[buttonStyles.container, styles.container]}>
      <TouchableOpacity style={buttonStyles.link} onPress={handleShare}>
        <ShareIcon />
        <Text style={buttonStyles.text}>Share workout</Text>
      </TouchableOpacity>
    </View>
  );
};

export default ShareWorkout;
