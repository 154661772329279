import * as React from "react";
import { connect } from "react-redux";
import { toggleMobileMenu } from "store/actions";
import MenuContent from "./menu-content";

const mapDispatch = {
  toggleMobileMenu,
};

export default connect(null, mapDispatch)(MenuContent);
