import { Pages } from "application/types";

import * as React from "react";
import { useHistory } from "react-router-dom";

const useCustomNav = () => {
  let history = useHistory();
  const goTo = (to: Pages) => {
    if (to === Pages.choose) {
      history.push(`/`);
    } else {
      history.push(`/${to}`);
    }
  };

  return {
    goTo,
    goBack: history.goBack,
  };
};

export default useCustomNav;
