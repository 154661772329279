import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import {
  isResting as isRestingMemo,
  routineLength as routineLengthMemo,
  routineCurrent,
} from "store/computed/calculateRoutine";
import { togglePause } from "store/actions";
import ProgressBar from "./progress-bar";

const mapState = (state: state) => {
  const { warmUpIsFinished } = state;
  const routineLength = routineLengthMemo(state);
  const elapsedRoutineLength = routineCurrent(state).elapsedRoutineLength;

  return {
    warmUpIsFinished,
    progress: elapsedRoutineLength.seconds / routineLength.seconds,
    isResting: isRestingMemo(state),
  };
};

const mapDispatch = {
  togglePause,
};

export default connect(mapState, mapDispatch)(ProgressBar);
