import React from "react";
import { Text, View } from "react-native";

import styles from "./STYLES";

import { Linking } from "expo";

type HtmlCompProps = {
  children: any;
};

export const H1 = ({ children }: HtmlCompProps) => {
  return <Text style={styles.h1}>{children}</Text>;
};

export const H2 = ({ children }: HtmlCompProps) => {
  return <Text style={styles.h2}>{children}</Text>;
};

export const H3 = ({ children }: HtmlCompProps) => {
  return <Text style={styles.h3}>{children}</Text>;
};

export const P = ({ children }: HtmlCompProps) => {
  return <Text style={styles.p}>{children}</Text>;
};

export const Ul = ({ children }: HtmlCompProps) => {
  return <View>{children}</View>;
};

export const Li = ({ children }: HtmlCompProps) => {
  return <Text style={styles.li}>{children}</Text>;
};

export const Strong = ({ children }: HtmlCompProps) => {
  return <Text style={styles.strong}>{children}</Text>;
};

type AProps = {
  children: any;
  href: string;
};

export const A = ({ href, children }: AProps) => {
  return (
    <Text style={styles.a} onPress={() => Linking.openURL(href)}>
      {children}
    </Text>
  );
};
