import { Pages } from "application/types";
import { toggleMobileMenuArgs } from "store/types";

import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import CONST from "components/CONST";

import CloseIcon from "components/global/components/icons/close-icon";
import Logo from "components/global/components/header/logo";
import Link from "components/global/components/link";

import links from "./linksData";

import useCurrentPage from "components/global/hooks/useCurrentPage";

type Props = {
  toggleMobileMenu: (arg0: toggleMobileMenuArgs) => void;
};

const MenuContent = ({ toggleMobileMenu }: Props) => {
  const styles = StyleSheet.create({
    closeLink: {
      marginLeft: "auto",
      marginBottom: 24,
      width: 48,
      height: 48,
      alignItems: "flex-end",
    },
    logoLink: {
      borderBottomWidth: 1,
      borderBottomColor: "#E2E2E4",
      paddingBottom: 36,
      marginBottom: 36,
    },
  });

  const linkStyles = StyleSheet.create({
    link: {
      flexDirection: "row",
      marginBottom: 36,
      alignItems: "center",
    },
    underline: {
      flexDirection: "row",
      borderBottomWidth: 2,
      paddingBottom: 10,
      borderBottomColor: "transparent",
      alignItems: "center",
    },
    underlineIsActive: {
      borderBottomColor: "#4DD599",
    },
    iconWrap: {
      width: 30,
    },
    text: {
      fontSize: 13,
      fontFamily: CONST.fonts.latoBold,
      textTransform: "uppercase",
      color: CONST.colorFontGrey,
    },
  });

  const currentPage = useCurrentPage();

  return (
    <>
      <TouchableOpacity
        style={styles.closeLink}
        onPress={() => toggleMobileMenu({ isOpen: false })}
      >
        <CloseIcon size={24} />
      </TouchableOpacity>

      <Link
        goTo={Pages.choose}
        onPress={() => {
          toggleMobileMenu({ isOpen: false });
        }}
      >
        <View style={styles.logoLink}>
          <Logo isDark={true} />
        </View>
      </Link>

      {links.map((link, index) => {
        const isCurrentPage = currentPage === link.goTo;
        return (
          <Link
            key={index}
            goTo={link.goTo}
            onPress={() => {
              toggleMobileMenu({ isOpen: false });
            }}
          >
            <View style={linkStyles.link}>
              <View
                style={[
                  linkStyles.underline,
                  isCurrentPage && linkStyles.underlineIsActive,
                ]}
              >
                <View style={linkStyles.iconWrap}>
                  <link.Icon isDark={true} />
                </View>
                <Text style={linkStyles.text}>{link.text}</Text>
              </View>
            </View>
          </Link>
        );
      })}
    </>
  );
};

export default MenuContent;
