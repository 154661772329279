import { Pages } from "application/types";

import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import Svg, { Path } from "react-native-svg";
import { useHover } from "react-native-web-hooks";

import Link from "components/global/components/link";
import IconPhone from "components/global/components/icons/phone";
import IconGoogle from "components/global/components/icons/googlePlayStore";
import IconApple from "components/global/components/icons/appleAppStore";

import CONST from "components/CONST";
import { preventTabbing } from "application/components/CONST";

const paddingHorizontal = 16;
const opacity = 0.6;
const backgroundColor = "#28174C";
const borderColor = "#3D2E5E";

// TODO A11y
//  Clicking outside should close flyout
// Tab should open flyout?

const DownloadAllLink = () => {
  const [isOpen, isOpenSet] = React.useState(false);
  const styles = StyleSheet.create({
    container: {
      marginLeft: 40 - paddingHorizontal,
    },
    link: {
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center",
      paddingHorizontal,
      borderBottomWidth: 2,
      paddingBottom: 10,
      paddingTop: 12,
      borderBottomColor: isOpen ? borderColor : "transparent",
      backgroundColor: isOpen ? backgroundColor : undefined,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: isOpen ? 0 : 8,
      borderBottomRightRadius: isOpen ? 0 : 8,
      // borderTopLeftRadius: 8,
    },
    text: {
      color: "white",
      marginHorizontal: 8,
      fontFamily: CONST.fonts.latoRegular,
      fontSize: 16,
      lineHeight: 16,
      opacity: isOpen ? opacity : undefined,
    },
  });
  const ref = React.useRef(null);
  const isHovered = useHover(ref);
  return (
    <View style={styles.container}>
      <TouchableOpacity
        ref={ref}
        style={[
          styles.link,
          isHovered && { backgroundColor: CONST.colorChooseCtaGreen },
        ]}
        onPress={() => isOpenSet(!isOpen)}
      >
        <IconPhone style={{ opacity: isOpen ? opacity : 1 }} />
        <Text style={styles.text}>Download App</Text>
        {isOpen ? <IconUp /> : <IconDown />}
      </TouchableOpacity>
      {isOpen && <FlyOutContent />}
    </View>
  );
};

const FlyOutContent = () => {
  const styles = StyleSheet.create({
    flyOutContent: {
      position: "absolute",
      top: "100%",
      left: 0,
      right: 0,
      backgroundColor,
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,
    },
    flyOutText: {
      color: "white",
      fontFamily: CONST.fonts.latoRegular,
      fontSize: 13,
      textTransform: "uppercase",
      marginLeft: 10,
    },
    flyOutRow: {
      flexDirection: "row",
      flex: 1,
      alignItems: "center",
      paddingHorizontal,
      paddingVertical: 12,
    },
    flyOutRowLast: {
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,
    },
    flyOutBorder: {
      backgroundColor: borderColor,
      height: 1,
      position: "absolute",
      left: 10,
      right: 10,
      bottom: 0,
    },
  });
  const links = [
    {
      text: "Android App",
      goTo: Pages.appAndroid,
      Icon: IconGoogle,
    },
    {
      text: "iOS App",
      goTo: Pages.appIOs,
      Icon: IconApple,
    },
  ];
  return (
    <View style={styles.flyOutContent}>
      {links.map((item, i) => {
        const ref = React.useRef(null);
        const isHovered = useHover(ref);
        const isLast = links.length === i + 1;
        return (
          <Link goTo={item.goTo} key={i}>
            <View
              style={[
                isHovered && { backgroundColor: CONST.colorChooseCtaGreen },
                styles.flyOutRow,
                isLast && styles.flyOutRowLast,
              ]}
              ref={ref}
            >
              {!isLast && !isHovered && <View style={styles.flyOutBorder} />}
              <item.Icon isDark={false} />
              <Text style={styles.flyOutText}>{item.text}</Text>
            </View>
          </Link>
        );
      })}
    </View>
  );
};

const IconDown = () => {
  return (
    <Svg
      width={12}
      height={7}
      viewBox="0 0 12 7"
      fill="none"
      {...preventTabbing}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.254.254l.825.825L6 6.16.92 1.078l.826-.825L6 4.508 10.254.254z"
        fill="white"
        {...preventTabbing}
      />
    </Svg>
  );
};

const IconUp = () => {
  return (
    <Svg
      width={12}
      height={7}
      viewBox="0 0 12 7"
      fill="none"
      {...preventTabbing}
      style={{ opacity }}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.492L1.746 6.746.92 5.92 6 .84l5.08 5.08-.826.825L6 2.492z"
        fill="#fff"
        {...preventTabbing}
      />
    </Svg>
  );
};

export default DownloadAllLink;
