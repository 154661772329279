import * as React from "react";
import { Path, Svg } from "react-native-svg";
import { preventTabbing } from "components/CONST";

type Props = {
  size?: number;
  color?: string;
};

const CloseIcon = ({ size, color }: Props) => {
  const setSize = size ? size : 16;
  const color2 = color || "#CFCCD6";
  return (
    <Svg
      width={setSize}
      height={setSize}
      viewBox="0 0 16 16"
      {...preventTabbing}
    >
      <Path
        d="M8.27736 9.41423L1.98447 15.7071L0.570251 14.2929L6.86314 8.00001L0.570251 1.70712L1.98447 0.292908L8.27736 6.5858L14.5703 0.292908L15.9845 1.70712L9.69157 8.00001L15.9845 14.2929L14.5703 15.7071L8.27736 9.41423Z"
        fill={color2}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default CloseIcon;
