import { toggleSoundArgs } from "store/types";
import * as React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import Svg, { Path } from "react-native-svg";
import CONST from "components/CONST";
import { preventTabbing } from "application/components/CONST";

type Props = {
  toggleSound: (arg0: toggleSoundArgs) => void;
  sound: boolean;
  breakPoint: boolean;
};

const ToggleSound = ({ toggleSound, sound, breakPoint }: Props) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
    },
    text: {
      color: "white",
      fontSize: 16,
      lineHeight: 16,
      fontFamily: CONST.fonts.latoBold,
      marginLeft: 12,
      display: breakPoint ? undefined : "none",
    },
  });

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => toggleSound({ isPlaying: !sound })}
    >
      {sound ? <SoundIcon /> : <MuteIcon />}
      <Text style={styles.text}>{sound ? "Sound is on" : "Sound is off"}</Text>
    </TouchableOpacity>
  );
};

function SoundIcon() {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...preventTabbing}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.618l4-2v10.764l-4-2V15H3V9h5v-.382zM6.764 7L14 3.382v17.236L6.764 17H3a2 2 0 01-2-2V9a2 2 0 012-2h3.764zm12.915-3.543C21.714 5.48 23 8.568 23 12c0 3.432-1.286 6.52-3.32 8.543l-1.577-1.26C19.85 17.652 21 15.012 21 12c0-3.012-1.151-5.654-2.897-7.283l1.576-1.26zM19 12c0-2.41-.935-4.588-2.425-6.06l-1.578 1.262C16.207 8.292 17 10.026 17 12c0 1.974-.793 3.708-2.003 4.798l1.578 1.262C18.065 16.588 19 14.41 19 12z"
        fill="#fff"
        {...preventTabbing}
      />
    </Svg>
  );
}

function MuteIcon() {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...preventTabbing}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.707 1.293L1.293 2.707 5.586 7H3a2 2 0 00-2 2v6a2 2 0 002 2h3.764L14 20.618v-5.204l7.293 7.293 1.414-1.414-20-20zM12 13.414L7.586 9H3v6h5v.382l4 2v-3.968zm0-6.796v1.168l2 2V3.382L9.73 5.517l1.492 1.49.778-.389zm4.962 6.13l1.703 1.703A9.062 9.062 0 0019 12c0-2.41-.935-4.588-2.425-6.06l-1.578 1.262C16.207 8.292 17 10.026 17 12c0 .253-.013.503-.038.747zm3.292 3.292l1.518 1.518C22.554 15.922 23 14.02 23 12c0-3.432-1.286-6.52-3.32-8.543l-1.577 1.26C19.85 6.347 21 8.987 21 12c0 1.458-.27 2.829-.746 4.04z"
        fill="#fff"
        {...preventTabbing}
      />
    </Svg>
  );
}

export default ToggleSound;
