import { Pages } from "application/types";
import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import buttonStylesGen from "..//STYLES";
import CONST from "components/CONST";
import useCustomNav from "components/global/hooks/useCustomNav";

type Props = {
  resetWorkout: () => void;
};

const GoAgain = ({ resetWorkout }: Props) => {
  const buttonStyles = buttonStylesGen();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: CONST.colorBtnSecondary,
    },
  });

  const navigation = useCustomNav();

  return (
    <View style={[buttonStyles.container, styles.container]}>
      <TouchableOpacity
        style={buttonStyles.link}
        onPress={() => {
          resetWorkout();
          navigation.goTo(Pages.choose);
        }}
      >
        <Text style={buttonStyles.text}>Choose another workout</Text>
      </TouchableOpacity>
    </View>
  );
};

export default GoAgain;
