import { state } from "application/store/types";
import { createSelector } from "reselect";

const getWidth = (state: state) => state.width;

const breakPoint = createSelector([getWidth], (width): boolean => {
  return width > 700;
});

export default breakPoint;
