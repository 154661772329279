import { state } from "store/types";
import { connect } from "react-redux";
import breakPoint from "store/computed/breakPoints";
import MobileOffCanvasMenu from "./mobile-off-canvas-menu";

const mapState = (state: state) => {
  const { mobileMenuIsOpen } = state;
  return {
    mobileMenuIsOpen,
    breakPoint: breakPoint(state),
  };
};

export default connect(mapState)(MobileOffCanvasMenu);
