import React from "react";
import { Text } from "react-native";
import STYLES from "./STYLES";
import { Linking } from "expo";
import VARS from "application/VARS";

const ContactLink = () => {
  return (
    <Text
      style={STYLES.a}
      onPress={() => Linking.openURL(`mailto:${VARS.email}`)}
    >
      {VARS.email}
    </Text>
  );
};

export default ContactLink;
