import { state } from "store/types";
import * as React from "react";
import { useState, createContext } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Constants from "expo-constants";

import CONST from "components/CONST";

import SetMetaTags from "components/global/invisible/setMetaTags";
import ScrollTop from "components/global/invisible/scrollTop";

import Header from "application/components/global/components/header";
import PopularWorkoutsButton from "./popular-workouts-button";
import FooterComponent from "./footer";
import WorkoutTypeToggle from "./workout-type-toggle";
import WorkoutHIIT from "./workout-hiit";
import WorkoutCustom from "./workout-custom";

import { ScrollContext } from "./choose-page-scroll-content";

type Props = {
  breakPoint: boolean;
  workoutType: state["workoutType"];
};

const ChoosePage = ({ breakPoint, workoutType }: Props) => {
  // useMemo may be an overkill here,
  // but I didn't like recalculating toLowerCase on every render
  const shouldFooterBeFixed = React.useMemo(() => {
    return (
      Platform.OS === "web" &&
      // https://davidwalsh.name/detect-android
      !navigator.userAgent.toLowerCase().includes("android")
    );
  }, []);

  // Does not get updated if the footer should not be fixed
  const [footerHeight, setFooterHeight] = React.useState(0);

  const {
    viewportHorizontalPaddingMobile,
    viewportHorizontalPaddingDesktop,
  } = CONST.sizes;

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: CONST.colorDarkBlueBackground,
      // marginTop: -Constants.statusBarHeight,
      // This is messy. Normally we need top margin to account for the
      // statusBarHeight, but when the email sign up form is shown in the header
      // when we need remove it.
      // TODO Use a hook to apply the logic for marginTop so it's not repeated.
    },
    container2: {
      flex: 1,
      paddingTop: Constants.statusBarHeight + CONST.sizes.viewportTopPadding,
    },
    main: {
      flex: 1,
      paddingBottom: shouldFooterBeFixed ? footerHeight + 38 : 38,
      paddingTop: breakPoint ? 60 : undefined,
    },
    header: {
      flexDirection: breakPoint ? "row" : "column",
      marginBottom: breakPoint ? 36 : 24,
      justifyContent: "space-between",
      alignItems: breakPoint ? "center" : undefined,
      paddingHorizontal: breakPoint
        ? viewportHorizontalPaddingDesktop
        : viewportHorizontalPaddingMobile,
    },
    heading: {
      fontSize: breakPoint ? 48 : 36,
      color: "white",
      fontFamily: CONST.fonts.latoBold,
    },
    headingBreak: {
      display: breakPoint ? "none" : "flex",
    },
    popularWorkoutsWrapper: {
      display: breakPoint ? "flex" : "none",
    },
    workoutTypeToggleWrap: {
      paddingLeft: breakPoint
        ? viewportHorizontalPaddingDesktop
        : viewportHorizontalPaddingMobile,
      paddingRight: breakPoint
        ? viewportHorizontalPaddingDesktop / 2
        : viewportHorizontalPaddingMobile,
      marginBottom: 36,
      width: breakPoint ? "50%" : undefined,
    },
    footer: {
      borderTopColor: "#291A47",
      borderTopWidth: 1,
      backgroundColor: CONST.colorDarkBlueBackground,
      // @ts-ignore
      position: shouldFooterBeFixed ? "fixed" : undefined,
      left: 0,
      bottom: 0,
      width: "100%",
    },
  });

  const footerComponent = () => {
    return (
      <View
        style={styles.footer}
        onLayout={
          // Do not pass in the function if we won't ever use the value.
          // Spares a render cycle.
          shouldFooterBeFixed
            ? e => setFooterHeight(e.nativeEvent.layout.height)
            : undefined
        }
      >
        <FooterComponent />
      </View>
    );
  };

  const [canScroll, setCanScroll] = useState(true);

  return (
    <>
      <ScrollTop />
      <View style={styles.container}>
        <SetMetaTags title="Love HIIT - High Intensity Interval Timer" />
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.select({
            ios: "height",
            android: "padding",
          })}
        >
          <ScrollView
            // makes it possible to hide the keyboard
            // without going through all the text inputs
            // in the list
            keyboardDismissMode="interactive"
            keyboardShouldPersistTaps="handled"
            scrollEventThrottle={16}
            contentContainerStyle={{ flexGrow: 1 }}
            scrollEnabled={Platform.OS === "web" ? true : canScroll}
            // scrollEnabled={false}
          >
            <View style={styles.container2}>
              <Header />

              <View style={styles.main}>
                <View style={styles.header}>
                  <Text style={styles.heading}>
                    <Text>Choose </Text>
                    <Text style={styles.headingBreak}>{"\n"}</Text>
                    <Text>your workout</Text>
                  </Text>
                  <View style={styles.popularWorkoutsWrapper}>
                    <PopularWorkoutsButton emphasise={breakPoint} />
                  </View>
                </View>
                <View style={styles.workoutTypeToggleWrap}>
                  <WorkoutTypeToggle />
                </View>

                <ScrollContext.Provider value={{ setCanScroll }}>
                  {workoutType === "HIIT" ? <WorkoutHIIT /> : <WorkoutCustom />}
                </ScrollContext.Provider>
              </View>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
        {footerComponent()}
      </View>
    </>
  );
};

export default ChoosePage;
