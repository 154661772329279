import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import SharableUrlModal from "./sharable-url-modal";
import calculateSharableUrl from "store/computed/calculateSharableUrl";
import { socialShareModalToggle } from "store/actions";

const mapState = (state: state) => {
  const { shareModalIsOpen } = state;
  return {
    shareModalIsOpen,
    sharableUrl: calculateSharableUrl(state),
  };
};

const mapDispatch = {
  socialShareModalToggle,
};

export default connect(mapState, mapDispatch)(SharableUrlModal);
