import { Pages } from "application/types";
import * as React from "react";
import { Route, Switch } from "react-router-dom";

import Choose from "components/pages/choose";
import Go from "components/pages/go";
import Saved from "components/pages/saved";
import About from "components/pages/info/about";
import LegalWeb from "components/pages/info/legal/pages/web";
import LegalApp from "components/pages/info/legal/pages/app";
import LegalDisclaimer from "components/pages/info/legal/pages/disclaimer";

const PagesComponent = () => {
  return (
    <Switch>
      <Route path={`/${Pages.go}`}>
        <Go />
      </Route>

      <Route path={`/${Pages.about}`}>
        <About />
      </Route>

      <Route path={`/${Pages.saved}`}>
        <Saved />
      </Route>

      <Route path={`/${Pages.legalWeb}`}>
        <LegalWeb />
      </Route>

      <Route path={`/${Pages.legalApp}`}>
        <LegalApp />
      </Route>

      <Route path={`/${Pages.legalDisclaimer}`}>
        <LegalDisclaimer />
      </Route>

      <Route path="/">
        <Choose />
      </Route>
    </Switch>
  );
};

export default PagesComponent;
