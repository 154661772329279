import { deleteWorkoutArgs, state } from "store/types";

type Args = {
  state: state;
  action: deleteWorkoutArgs;
};

const deleteWorkout = ({ state, action }: Args): state => {
  const newSavedWorkouts = state.savedWorkouts.filter(
    (_, index) => index !== action.index,
  );

  return {
    ...state,
    savedWorkouts: newSavedWorkouts,
  };
};

export default deleteWorkout;
