import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "components/CONST";
import CONST from "components/CONST";

const SIZE = 18;

const IconCog = () => {
  return (
    <Svg
      width={SIZE}
      height={SIZE}
      viewBox="0 0 22 22"
      fill="none"
      {...preventTabbing}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.656 19.897l2.217-2.217-.78-2.837.34-.813L22 12.582V9.446l-2.56-1.454-.335-.811.791-2.839-2.219-2.215-2.837.78-.81-.34L12.58 0H9.446L7.991 2.56l-.811.334-2.838-.791-2.215 2.215.78 2.838-.34.81L0 9.416v3.134l2.558 1.458.335.811-.791 2.838 2.216 2.216 2.838-.78.81.34 1.45 2.566h3.134l1.457-2.558.811-.334 2.838.79zm.197-7.271l-.872 2.082.655 2.38-.568.569-2.376-.662-2.083.86-1.222 2.144h-.804L9.37 17.853l-2.08-.872-2.382.655-.567-.567.662-2.376-.86-2.083L2 11.388v-.805l2.147-1.212.872-2.08-.655-2.382.566-.566 2.376.663 2.084-.86L10.61 2h.803l1.211 2.147 2.08.872 2.383-.655.569.568-.662 2.375.86 2.084L20 10.61v.803l-2.147 1.212zM11 15a4 4 0 110-8 4 4 0 010 8zm2-4a2 2 0 11-4 0 2 2 0 014 0z"
        fill={CONST.colorDarkBlueBackground}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconCog;
