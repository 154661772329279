import { state } from "store/types";

import * as React from "react";
import { connect } from "react-redux";

import breakPoint from "store/computed/breakPoints";

import Footer from "./footer";

const mapState = (state: state) => {
  const { width } = state;
  const mediaQuery = [width < 400, width < 500, !breakPoint(state)];

  return {
    mediaQuery,
    breakPoint: breakPoint(state),
  };
};

export default connect(mapState)(Footer);
