import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Path, Svg } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";

type Props = {
  skipCurrent: () => void;
  prevActivity: () => void;
  breakPoint: boolean;
  type: "prev" | "skip" | "next";
};

const SkipPrev = ({ type, skipCurrent, prevActivity, breakPoint }: Props) => {
  const iconSize = breakPoint ? 40 : 20;

  const styles = StyleSheet.create({
    container: {
      alignItems: type === "prev" ? "flex-end" : "flex-start",
    },
    text: {
      color: "white",
      textTransform: "uppercase",
      marginTop: 10,
      fontSize: breakPoint ? 18 : 9,
    },
    svgContainer: {
      width: iconSize,
      height: iconSize,
    },
    svg: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  });

  const text = type === "prev" ? "Prev." : type === "next" ? "Next" : "Skip";

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => (type === "prev" ? prevActivity() : skipCurrent())}
    >
      <View style={styles.svgContainer}>
        {type === "prev" ? <PrevIcon /> : <SkipIcon />}
      </View>
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );
};

const SkipIcon = () => {
  return (
    <Svg
      width={41}
      height={34}
      viewBox="0 0 41 34"
      fill="none"
      {...preventTabbing}
      style={{
        maxWidth: "100%",
        height: "auto",
      }}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.774 3v28c0 1.718 2.024 2.637 3.317 1.505l16-14a2 2 0 000-3.01l-16-14C2.798.363.774 1.282.774 3zm14.963 14L4.774 26.592V7.407L15.737 17zm5.037 14V3c0-1.718 2.024-2.637 3.317-1.505l16 14a2 2 0 010 3.01l-16 14c-1.293 1.132-3.317.213-3.317-1.505zm4-23.593L35.737 17l-10.963 9.592V7.407z"
        fill="#fff"
        {...preventTabbing}
      />
    </Svg>
  );
};

const PrevIcon = () => {
  return (
    <Svg
      width={40}
      height={34}
      viewBox="0 0 40 34"
      fill="none"
      {...preventTabbing}
      style={{
        maxWidth: "100%",
        height: "auto",
      }}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.683 18.505l16 14C17.976 33.637 20 32.718 20 31V3c0-1.718-2.024-2.637-3.317-1.505l-16 14a2 2 0 000 3.01zm20 0l16 14C37.976 33.637 40 32.718 40 31V3c0-1.718-2.024-2.637-3.317-1.505l-16 14a2 2 0 000 3.01zM16 7.408L5.037 17 16 26.593V7.408zm20 0v19.184L25.037 17 36 7.408z"
        fill="#fff"
        {...preventTabbing}
      />
    </Svg>
  );
};

export default SkipPrev;
