import * as React from "react";
import Constants from "expo-constants";

const VideoKeepScreenOn = () => {
  if (Constants.deviceName !== "Chrome") {
    return null;
  }
  return (
    <video
      muted={true}
      width="100"
      height="100"
      autoPlay={true}
      controls={true}
      loop={true}
      playsInline={true}
    >
      <source src={require("./vid.mp4")} type="video/mp4" />
      <source src={require("./vid.webm")} type="video/webm" />
    </video>
  );
};

export default VideoKeepScreenOn;
