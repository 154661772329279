import * as React from "react";
import { Text, TouchableOpacity, StyleSheet } from "react-native";
import CONST from "components/CONST";
import Plus from "components/global/components/icons/plus";

type Props = {
  isExercise?: boolean;
  customAddExercise: () => void;
  customAddRest: () => void;
};
const AddExerciseOrRest = ({
  isExercise,
  customAddExercise,
  customAddRest,
}: Props) => {
  const title = isExercise ? "Add exercise" : "Add rest";
  const color = isExercise ? CONST.colorChooseCtaGreen : "#9475E3";

  const styles = StyleSheet.create({
    root: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 1,
      borderColor: color,
      borderRadius: CONST.buttonBorderRadius,
      padding: 12,
    },
    text: {
      color,
      textTransform: "uppercase",
      marginLeft: 8,
      fontFamily: CONST.fonts.latoBold,
    },
  });

  const handleTap = () => {
    if (isExercise) {
      customAddExercise();
      return;
    }
    customAddRest();
  };

  return (
    <TouchableOpacity style={styles.root} onPress={handleTap}>
      <Plus color={color} />
      <Text style={styles.text}>{title}</Text>
    </TouchableOpacity>
  );
};

export default AddExerciseOrRest;
