import { InputsHiit } from "store/types";
import uuid from "uuid";

const addEmptyExercises = (
  exercises: InputsHiit["exercises"],
): InputsHiit["exercises"] => {
  // If the last exercise isn't empty then add one
  const lastExercise = exercises[exercises.length - 1];

  if (lastExercise?.text !== "") {
    exercises.push({
      text: "",
      id: uuid(),
    });
  }

  return exercises;
};

export default addEmptyExercises;
