import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import breakPoint from "store/computed/breakPoints";
import { customSetRounds, customSetSecondsRest } from "store/actions";
import WorkoutCustom from "./WorkoutCustom";

const mapState = (state: state) => {
  const { rest, rounds } = state.inputsCustom;
  return {
    breakPoint: breakPoint(state),
    rest,
    rounds,
  };
};

const mapDispatch = {
  customSetRounds: customSetRounds,
  customSetSecondsRest: customSetSecondsRest,
};

export default connect(mapState, mapDispatch)(WorkoutCustom);
