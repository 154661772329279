import {
  moveExerciseArgs,
  removeExerciseArgs,
  setExerciseArgs,
} from "store/types";
import * as React from "react";
import {
  Platform,
  StyleSheet,
  TextInputProps,
  TouchableOpacity,
  View,
} from "react-native";
import CloseIcon from "components/global/components/icons/close-icon";
import CONST from "components/CONST";
import INPUT_TEXT_CONT from "../INPUT_TEXT_CONST";

import ListAwareTextInput from "./list-aware-text-input";

type Props = {
  index: number;
  text: string;
  setExercise: (args: setExerciseArgs) => void;
  removeExercise: (args: removeExerciseArgs) => void;
  moveExerciseUp: (args: moveExerciseArgs) => void;
  moveExerciseDown: (args: moveExerciseArgs) => void;
  isLandscape: boolean;
  breakPoint: boolean;
  isLast: boolean;
  onSubmitEditing?: TextInputProps["onSubmitEditing"];
};

const InputText = ({
  breakPoint,
  text,
  index,
  setExercise,
  removeExercise,
  isLandscape,
  moveExerciseUp,
  moveExerciseDown,
  isLast,
  onSubmitEditing,
}: Props) => {
  // const buttonTextSize = 25;

  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      flex: 1,
      marginLeft: 12,
    },
    input: {
      fontFamily: CONST.fonts.latoRegular,
      color: CONST.colorFontGrey,
      padding: 10,
      paddingLeft: 0,
      // marginRight: 10,
      height: INPUT_TEXT_CONT.inputHeight,
      flexGrow: Platform.OS !== "web" ? 1 : undefined,
      fontSize: breakPoint ? 21 : 16,
      width: Platform.OS === "web" ? "100%" : undefined,
    },
    buttonCont: {
      width: 44,
      justifyContent: "center",
      alignItems: "center",
      display: isLast ? "none" : undefined,
    },
  });

  return (
    <View style={styles.container}>
      <ListAwareTextInput
        index={index}
        style={styles.input}
        onChangeText={newText => setExercise({ index, text: newText })}
        value={text}
        placeholder="Add exercise..."
        placeholderTextColor={CONST.colorPlaceholderText}
        onSubmitEditing={onSubmitEditing}
      />
      <TouchableOpacity
        accessibilityLabel="Remove exercise"
        onPress={() => removeExercise({ index })}
        style={styles.buttonCont}
      >
        <CloseIcon />
      </TouchableOpacity>
    </View>
  );
};

export default InputText;
