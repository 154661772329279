import * as React from "react";
import {
  StyleSheet,
  Text,
  View,
  ViewProps,
  TextProps,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";

import CustomPicker from "../custom-picker";
import { useAllowedTimeUnitItems, useSelectedDuration } from "./utils";

export interface DurationPickerProps extends ViewProps {
  prompt?: string | (() => string | undefined);
  minMinute?: number;
  maxMinute?: number;
  minSecond?: number;
  maxSecond?: number;
  selectedValue?: string;
  onValueChange?: (newDuration: string) => void;
  spacerStyle?: StyleProp<TextStyle>;
  pickersStyle?: StyleProp<ViewStyle>;
  indicatorsStyle?: StyleProp<TextStyle>;
}

export default function DurationPicker({
  prompt,
  minMinute,
  maxMinute,
  minSecond,
  maxSecond,
  selectedValue,
  onValueChange,
  spacerStyle,
  indicatorsStyle,
  pickersStyle,
  ...props
}: DurationPickerProps) {
  const allowedMinuteItems = useAllowedTimeUnitItems(
    minMinute ?? 0,
    maxMinute ?? 59,
  );

  const allowedSecondItems = useAllowedTimeUnitItems(
    minSecond ?? 0,
    maxSecond ?? 59,
  );

  const [selectedMinute, selectedSecond] = useSelectedDuration(
    selectedValue ?? "0:00",
  );

  const minutesPrompt = React.useMemo(() => {
    if (!prompt || typeof prompt === "string") {
      return prompt ? `${prompt} minutes` : "Minutes";
    }

    return prompt();
  }, [prompt]);

  const secondsPrompt = React.useMemo(() => {
    if (!prompt || typeof prompt === "string") {
      return prompt ? `${prompt} seconds` : "Seconds";
    }

    return prompt();
  }, [prompt]);

  return (
    <View {...props} style={[styles.container, props.style]}>
      <CustomPicker
        prompt={minutesPrompt}
        items={allowedMinuteItems}
        style={pickersStyle}
        indicatorStyle={indicatorsStyle}
        selectedValue={selectedMinute}
        onValueChange={newMinuteValue =>
          onValueChange?.(`${newMinuteValue}:${selectedSecond}`)
        }
      />
      <Text style={spacerStyle}>:</Text>
      <CustomPicker
        prompt={secondsPrompt}
        items={allowedSecondItems}
        style={pickersStyle}
        indicatorStyle={indicatorsStyle}
        selectedValue={selectedSecond}
        onValueChange={newSecondValue =>
          onValueChange?.(`${selectedMinute}:${newSecondValue}`)
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
});
