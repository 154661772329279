import qs, { ParsedQs } from "qs";
import { decompressFromEncodedURIComponent } from "lz-string";
import * as z from "zod";
import { setHiitWorkoutInputsArgs, setCustomWorkoutInputsArgs, action } from "store/types";
import { setCustomWorkoutInputs, setHiitWorkoutInputs } from "application/store/actions";

function parseHiitExercises(
  exercisesInput: undefined | string | string[] | ParsedQs | ParsedQs[],
): string[] {
  // Empty value
  if (!exercisesInput) {
    return [];
  }

  // A single ParsedQs
  if (typeof exercisesInput === "object" && !Array.isArray(exercisesInput)) {
    return [];
  }

  // If there's only one "exercises" param,
  // it will get interpreted as a string,
  // not an array.
  if (typeof exercisesInput === "string" && exercisesInput !== "") {
    return [exercisesInput];
  }

  // Pick only non-empty string results
  if (Array.isArray(exercisesInput)) {
    // Make the type more loose so we can use `filter` easily
    return (
      (exercisesInput as (string | ParsedQs)[])
        // Filter only string results (we don't consider objects valid exercise inputs).
        // We need to specify that the function is a type guard so TS knows that the returned
        // array will only contain strings.
        .filter((res): res is string => typeof res === "string")
        .filter(res => res !== "")
    );
  }

  return [];
}

// zod schemas of custom workout object

const nonNegativeInt = z
  .number()
  .nonnegative()
  .int();

const restSchema = z.object({
  isRest: z.literal(true),
  time: nonNegativeInt,
});

const exerciseSchema = z.object({
  isRest: z.literal(false),
  name: z.string(),
  weight: z.object({
    amount: nonNegativeInt,
    format: z.union([z.literal("kg"), z.literal("lbs")]),
  }),
  repeat: z.object({
    amount: nonNegativeInt,
    format: z.union([z.literal("max"), z.literal("time"), z.literal("reps")]),
  }),
});

const customWorkoutSchema = z.object({
  rest: nonNegativeInt,
  rounds: nonNegativeInt,
  routine: z.array(z.union([restSchema, exerciseSchema])),
});

function calculateCustomWorkoutFromUrl(url: string): setCustomWorkoutInputsArgs | false {
  const strippedUrl = url?.startsWith("?") ? url.slice(1) : url;
  const inputs = qs.parse(strippedUrl ?? "");
  // First we're decompressing the (hopefully) stringified JSON object
  // of inputsCustom with `lz-string` (with which it was compressed
  // by our sharable URL calculator). Using this lossless compression
  // library makes URLs up to 2x shorter.
  const inputObject = decompressFromEncodedURIComponent(`${inputs.i}`);
  if (!inputObject) {
    return false;
  }
  // If inputObject was not empty, it means that:
  // - the `i` param wasn't empty and
  // - it was a `lz-string`-encoded string.
  // This means that we are ok thinking that
  // the URL is deliberately parsable by the following code.
  try {
    // Then we try to parse the (hopefully) stringified JSON object.
    // If it can't be parsed we'll catch the error, so the app
    // will handle this problem gracefully.
    const parsedObject = JSON.parse(inputObject);
    // Once we have the object parsed we want to verify that
    // it is valid - it conforms to what we expect of a custom routine
    // and it contains no unknown fields. For that we use the `zod`
    // library which, as a bonus, is TypeScript-compatible.
    // If the object didn't match the schema, an error would be thrown
    // which we would catch and return false from the function
    // (meaning that the URL couldn't have been interpreted
    // as a custom workout URL).
    const parsedWorkout = customWorkoutSchema.parse(parsedObject);
    // zod is non-optional by default, so if it returns without throwing
    // the object is not empty.
    return parsedWorkout;
  } catch (e) {
    // We want to catch that warning since it means that
    // serializing and parsing have become out-of-sync.
    console.warn("Invalid object parsed from URL:", inputObject, e);
    return false;
  }
}

export default function calculateFromUrl(
  url: string,
): action | null {
  const customWorkoutArgs = calculateCustomWorkoutFromUrl(url);
  if (customWorkoutArgs) {
    return setCustomWorkoutInputs(customWorkoutArgs);
  }

  const hiitWorkoutArgs = calculateHiitWorkoutFromUrl(url);
  if (hiitWorkoutArgs) {
    return setHiitWorkoutInputs(hiitWorkoutArgs);
  }

  const v1HiitWorkoutArgs = v1CalculateFromUrl(url);
  if (v1HiitWorkoutArgs) {
    return setHiitWorkoutInputs(v1HiitWorkoutArgs);
  }

  return null;
}

function calculateHiitWorkoutFromUrl(url: string) {
  const strippedUrl = url?.startsWith("?") ? url.slice(1) : url;
  const inputs = qs.parse(strippedUrl ?? "");

  const secondsExercise = Number.parseInt(`${inputs.exercise}`);
  const secondsRest = Number.parseInt(`${inputs.rest}`);
  const rounds = Number.parseInt(`${inputs.rounds}`);

  if (
    !Number.isInteger(secondsExercise) ||
    !Number.isInteger(secondsRest) ||
    !Number.isInteger(rounds) ||
    !inputs
  ) {
    return false;
  }

  return {
    secondsExercise: +secondsExercise,
    secondsRest: +secondsRest,
    rounds: +rounds,
    exercises: parseHiitExercises(inputs.exercises),
  };
}

// Legacy, but still supported format of URLs
function v1CalculateFromUrl(url: string): setHiitWorkoutInputsArgs | false {
  let urlRemoveFirstChar = url;

  if (!urlRemoveFirstChar) {
    return false;
  }

  if (urlRemoveFirstChar.charAt(0) === "?") {
    urlRemoveFirstChar = urlRemoveFirstChar.substring(1);
  }

  if (urlRemoveFirstChar.search("___") === -1) {
    return false;
  }

  const urlWithCrapRemoved = urlRemoveFirstChar.split("___")[0];

  if (!urlWithCrapRemoved) {
    return false;
  }

  const inputs = urlWithCrapRemoved.split("__");
  const [secondsExercise, secondsRest, rounds] = inputs;
  const exercises = inputs.slice(3).filter(res => res);

  if (!secondsExercise || !secondsRest || !rounds || !inputs) {
    return false;
  }

  return {
    secondsExercise: +secondsExercise,
    secondsRest: +secondsRest,
    rounds: +rounds,
    exercises,
  };
}
