import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { formatRoutineLength } from "components/utils/formatSeconds";
import { RoutineLength } from "application/store/types";

type Props = {
  remaining: RoutineLength;
};

const Remaining = ({ remaining }: Props) => {
  const timeFormatted = formatRoutineLength(remaining);

  return (
    <View>
      <Text style={styles.text}>-{timeFormatted}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    color: "white",
    textTransform: "uppercase",
  },
});

export default Remaining;
