import { RoutineLength } from "application/store/types";

type mainRes = {
  hours: number;
  minutes: number;
  seconds: number;
};

const main = (secs: number): mainRes => {
  secs = Math.round(secs);
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  return {
    hours,
    minutes,
    seconds,
  };
};

type stringRes = {
  hours: string;
  minutes: string;
  seconds: string;
};

export const formatSecondsString = (secs: number): stringRes => {
  const res = main(secs);
  // TODO get padStart working in TS
  const pad = (number: number) => {
    const numberString = `${number}`;
    if (numberString.length === 1) {
      return `0${numberString}`;
    }
    return numberString;
  };
  return {
    hours: pad(res.hours),
    minutes: pad(res.minutes),
    seconds: pad(res.seconds),
  };
};

export const formatRoutineLength = (routineLength: RoutineLength) => {
  const { hours, minutes, seconds } = formatSecondsString(routineLength.seconds);
  // If hours is just 00, let's strip it for simplicity
  const hoursPart = hours === "00" ? "" : `${hours}:`;
  const inaccuracyIndicator = !routineLength.isAccurate ? "*" : "";
  return `${hoursPart}${minutes}:${seconds}${inaccuracyIndicator}`;
}

export default main;
