import addEmptyExercise from "store/utils/addEmptyExercise";
import { actionMoveExerciseUp, state } from "store/types";

type Args = {
  state: state;
  action: actionMoveExerciseUp;
};

const moveExerciseUp = ({ state, action }: Args): state => {
  if (action.index === 0) return state; // Do nothing if it's the first result
  const exerciseToMoveUp = state.inputsHiit.exercises[action.index];
  const exercisesMovedUp = state.inputsHiit.exercises.filter(
    (item, index) => index !== action.index,
  );
  exercisesMovedUp.splice(action.index - 1, 0, exerciseToMoveUp);
  return {
    ...state,
    inputsHiit: {
      ...state.inputsHiit,
      exercises: addEmptyExercise(exercisesMovedUp),
    },
  };
};

export default moveExerciseUp;
