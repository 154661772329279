import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";
import CONST from "./CONST";

type Props = {
  isDark: boolean;
};

const IconGooglePlayStore = ({ isDark }: Props) => {
  const { darkColor } = CONST;
  return (
    <Svg
      width={18}
      height={22}
      viewBox="0 0 18 22"
      fill="none"
      {...preventTabbing}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.161 3.112A.767.767 0 002 3.77v14.96c0 .595.65.963 1.161.657l12.467-7.48a.767.767 0 000-1.315L3.16 3.112zM0 3.77C0 1.619 2.346.29 4.19 1.397l12.467 7.48c1.79 1.075 1.79 3.67 0 4.745L4.19 21.102C2.346 22.208 0 20.88 0 18.729V3.77z"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.767 2.416L14.25 13.899l-1.414 1.415L1.352 3.83l1.415-1.414z"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 19.2L12.484 7.716l1.414 1.414L2.414 20.614 1 19.2z"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconGooglePlayStore;
