import * as React from "react";
import { connect } from "react-redux";
import { state } from "store/types";
import { routineCurrent as routineCurrentMemo } from "store/computed/calculateRoutine";
// import { togglePause } from "store/actions";
import MakeSoundInvisible from "./make-sound-invisible";

const mapState = (state: state) => {
  const { warmUpIsFinished, warmUpTimeIndex, sound } = state;
  const routineCurrent = routineCurrentMemo(state);
  return {
    sound,
    routineCurrent,
    warmUpIsFinished,
    warmUpTimeIndex,
    // routineTimeIndexIs0: routineTimeIndex === 0,
  };
};

export default connect(mapState)(MakeSoundInvisible);
