// import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import { socialShareModalToggle } from "store/actions";
import ShareWorkout from "./share-workout";

const mapDispatch = {
  shareWeb: socialShareModalToggle,
};

export default connect(null, mapDispatch)(ShareWorkout);
