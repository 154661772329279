import {
  RoutineCustom,
  InputCustomExercise,
  InputCustomRest,
  reorderExercisesArgs,
} from "store/types";
import * as React from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import Rest from "components/pages/choose/workout-custom/rest";
import Exercise from "components/pages/choose/workout-custom/exercise";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortEnd,
} from "react-sortable-hoc";
import styles from "./STYLES";

const DragHandler = SortableHandle(() => (
  <View style={styles.invisibleHandle} />
));

interface InputCustomRest2 extends InputCustomRest {
  index2: number;
}
interface InputCustomExercise2 extends InputCustomExercise {
  index2: number;
}

const SortableItem = SortableElement(
  (props: InputCustomRest2 | InputCustomExercise2) => {
    if (props.isRest) {
      return (
        <View style={styles.itemWrapper}>
          <Rest {...props} index={props.index2} />
          <DragHandler />
        </View>
      );
    }
    return (
      <View style={styles.itemWrapper}>
        <Exercise {...props} index={props.index2} />
        <DragHandler />
      </View>
    );
  },
);

const SortableList = SortableContainer(
  ({ routine }: { routine: RoutineCustom }) => {
    return (
      <View>
        {routine.map((item, index) => {
          return (
            <SortableItem {...item} key={index} index={index} index2={index} />
          );
        })}
      </View>
    );
  },
);

const DragContainer = ({
  routine,
  customReorderExercises,
}: {
  routine: RoutineCustom;
  customReorderExercises: (arg0: reorderExercisesArgs) => void;
}) => {
  const handleDragEnd = (e: SortEnd) => {
    const { oldIndex, newIndex } = e;
    customReorderExercises({ oldIndex, newIndex });
  };
  return (
    <SortableList
      lockAxis="y"
      useDragHandle={true}
      routine={routine}
      onSortEnd={handleDragEnd}
    />
  );
};

export default DragContainer;
