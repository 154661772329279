import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";
import CONST from "./CONST";

type Props = {
  isDark: boolean;
};

const IconContact = ({ isDark }: Props) => {
  const { darkColor } = CONST;
  return (
    <Svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      {...preventTabbing}
    >
      <Path
        d="M10.503 4.999a.833.833 0 100 1.667.833.833 0 000-1.667zM9.667 14.998h1.667V8.332H9.667v6.666z"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
      <Path
        d="M1.333 10a9.167 9.167 0 1018.334 0 9.167 9.167 0 00-18.334 0zM18 10a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
        fill={isDark ? darkColor : "white"}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconContact;
