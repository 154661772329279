import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";

import { resetWorkout, soundHackActivatedToggle } from "store/actions";
import Go from "./go";
import { routineLength } from "store/computed/calculateRoutine";
import breakPoint from "store/computed/breakPoints";

const mapState = (state: state) => {
  const { soundHackActivated, workoutType, inputsCustom } = state;

  const hiitWorkoutIsNotEmpty =
    state.inputsHiit.exercises.filter(item => item.text).length > 0;

  const customWorkoutIsNotEmpty =
    inputsCustom.routine.filter(item => {
      if (item.isRest) return false;

      if (!item.name) return false; // Make sure item has a name.
      if (item.repeat.format === "max") return true;

      // if duration is time or reps based make sure the value isn't 0.
      if (item.repeat.amount > 0) return true;

      return false;
    }).length > 0;

  const atLeastOneExercise =
    state.workoutType === "HIIT"
      ? hiitWorkoutIsNotEmpty
      : customWorkoutIsNotEmpty;

  return {
    breakPoint: breakPoint(state),
    workoutType,
    soundHackActivated,
    routineLength: routineLength(state),
    atLeastOneExercise,
  };
};

const mapDispatch = {
  resetWorkout,
  soundHackActivatedToggle,
};

export default connect(mapState, mapDispatch)(Go);
