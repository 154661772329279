import * as React from "react";
import { connect } from "react-redux";
import { customSetWeightValue, customSetWeightFormat } from "store/actions";
import WeightInput from "./weight-input";

const mapDispatch = {
  customSetWeightValue,
  customSetWeightFormat,
};

export default connect(null, mapDispatch)(WeightInput);
