import { state } from "store/types";
import { warmUpTimeStartIndex } from "store/initState";

const resetWorkout = (state: state): state => {
  return {
    ...state,
    paused: false,
    routineTimeIndex: 0,
    routineIsFinished: false,
    warmUpIsFinished: false,
    warmUpTimeIndex: warmUpTimeStartIndex,
  };
};

export default resetWorkout;
