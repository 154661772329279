import { socialShareModalToggleArgs } from "store/types";
import * as React from "react";

import { connect } from "react-redux";
import { socialShareModalToggle } from "store/actions";

import { Text, TouchableOpacity } from "react-native";
import ShareIcon from "components/global/components/icons/share-icon";

type Props = {
  sharedStyles: any;
  // styles: any;
  socialShareModalToggle: (arg0: socialShareModalToggleArgs) => void;
};

const SocialShareButton = ({ sharedStyles, socialShareModalToggle }: Props) => {
  return (
    <TouchableOpacity
      onPress={() => {
        socialShareModalToggle({ isOpen: true });
      }}
      style={[sharedStyles.button]}
    >
      <ShareIcon />

      <Text
        style={[sharedStyles.buttonText, sharedStyles.buttonText__withIcon]}
      >
        Share
      </Text>
    </TouchableOpacity>
  );
};

const mapDispatch = {
  socialShareModalToggle,
};

export default connect(null, mapDispatch)(SocialShareButton);
