import * as React from "react";
import { Platform, StyleSheet, View } from "react-native";
import FocusTrap from "focus-trap-react";

type Props = {
  children: React.ReactElement;
  showOffCanvasMenu: boolean;
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
});

const PlatformSpecificsWeb = ({ showOffCanvasMenu, children }: Props) => {
  React.useEffect(() => {
    if (Platform.OS !== "web") {
      return;
    }
    // Prevent scrolling when modal is open
    const body = document.getElementsByTagName("body")[0];
    if (showOffCanvasMenu) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [showOffCanvasMenu]);

  if (!showOffCanvasMenu) {
    return null;
  }

  return (
    <FocusTrap>
      <View style={styles.container}>{children}</View>
    </FocusTrap>
  );
};

export default PlatformSpecificsWeb;
