import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";

type Props = {
  color?: string;
};

const IconDragHandle = ({ color }: Props) => {
  const color2 = color || "#CFCCD6";
  return (
    <Svg width={11} height={18} viewBox="0 0 11 18" {...preventTabbing}>
      <Path
        d="M9.05599 17.3333C8.13551 17.3333 7.38932 16.5871 7.38932 15.6667C7.38932 14.7462 8.13551 14 9.05599 14C9.97646 14 10.7227 14.7462 10.7227 15.6667C10.7227 16.5871 9.97646 17.3333 9.05599 17.3333Z"
        fill={color2}
        {...preventTabbing}
      />
      <Path
        d="M7.38932 8.99999C7.38932 9.92046 8.13551 10.6667 9.05599 10.6667C9.97646 10.6667 10.7227 9.92046 10.7227 8.99999C10.7227 8.07952 9.97646 7.33332 9.05599 7.33332C8.13551 7.33332 7.38932 8.07952 7.38932 8.99999Z"
        fill={color2}
        {...preventTabbing}
      />
      <Path
        d="M9.05599 3.99999C8.13551 3.99999 7.38932 3.2538 7.38932 2.33332C7.38932 1.41285 8.13551 0.666656 9.05599 0.666656C9.97646 0.666656 10.7227 1.41285 10.7227 2.33332C10.7227 3.2538 9.97646 3.99999 9.05599 3.99999Z"
        fill={color2}
        {...preventTabbing}
      />
      <Path
        d="M0.722656 15.6667C0.722656 16.5871 1.46885 17.3333 2.38932 17.3333C3.3098 17.3333 4.05599 16.5871 4.05599 15.6667C4.05599 14.7462 3.3098 14 2.38932 14C1.46885 14 0.722656 14.7462 0.722656 15.6667Z"
        fill={color2}
        {...preventTabbing}
      />
      <Path
        d="M2.38932 10.6667C1.46885 10.6667 0.722656 9.92046 0.722656 8.99999C0.722656 8.07952 1.46885 7.33332 2.38932 7.33332C3.3098 7.33332 4.05599 8.07952 4.05599 8.99999C4.05599 9.92046 3.3098 10.6667 2.38932 10.6667Z"
        fill={color2}
        {...preventTabbing}
      />
      <Path
        d="M0.722656 2.33332C0.722656 3.2538 1.46885 3.99999 2.38932 3.99999C3.3098 3.99999 4.05599 3.2538 4.05599 2.33332C4.05599 1.41285 3.3098 0.666656 2.38932 0.666656C1.46885 0.666656 0.722656 1.41285 0.722656 2.33332Z"
        fill={color2}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconDragHandle;
