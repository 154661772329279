import { StyleSheet, View } from "react-native";
import * as React from "react";
import InputNumericContainer from "components/pages/choose/input-numeric-container";
import InputTextContainer from "components/pages/choose/input-text-container";
import CONST from "components/CONST";

type Props = {
  width: number;
  breakPoint: boolean;
};

const WorkoutHIIT = ({ width, breakPoint }: Props) => {
  const {
    viewportHorizontalPaddingMobile,
    viewportHorizontalPaddingDesktop,
  } = CONST.sizes;

  const styles = StyleSheet.create({
    cols: {
      flexDirection: breakPoint ? "row" : "column",
    },
    col1: {
      flexBasis: breakPoint ? width / 2 : undefined,
      paddingLeft: breakPoint
        ? viewportHorizontalPaddingDesktop
        : viewportHorizontalPaddingMobile / 2,
      paddingRight: breakPoint
        ? viewportHorizontalPaddingDesktop / 2
        : viewportHorizontalPaddingMobile / 2,
      marginBottom: breakPoint ? undefined : 24,
    },
    col2: {
      flexBasis: breakPoint ? width / 2 : undefined,
      paddingRight: breakPoint
        ? viewportHorizontalPaddingDesktop
        : viewportHorizontalPaddingMobile,
      paddingLeft: breakPoint
        ? viewportHorizontalPaddingDesktop / 2
        : viewportHorizontalPaddingMobile,
    },
  });

  return (
    <View style={[styles.cols]}>
      <View style={[styles.col1]}>
        <InputTextContainer />
      </View>
      <View style={[styles.col2]}>
        <InputNumericContainer />
      </View>
    </View>
  );
};

export default WorkoutHIIT;
