import * as React from "react";
import { StyleSheet, Text, View } from "react-native";

type Props = {
  exerciseWithinRoutineIndex: number;
  totalNumberOfExercises: number;
};

const ExerciseNumber = ({
  exerciseWithinRoutineIndex,
  totalNumberOfExercises,
}: Props) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "center",
    },
    text: {
      color: "white",
      textTransform: "uppercase",
    },
  });

  return (
    <View style={[styles.container]}>
      <Text style={styles.text}>Exercise </Text>
      <Text style={styles.text}>
        {exerciseWithinRoutineIndex + 1} of {totalNumberOfExercises}
      </Text>
    </View>
  );
};

export default ExerciseNumber;
