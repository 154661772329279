import * as React from "react";
import { Platform } from "react-native";
import Svg, {
  Defs,
  LinearGradient,
  Path,
  RadialGradient,
  Stop,
} from "react-native-svg";

type Props = {
  width?: number;
};

const AboutIllustration = ({ width }: Props) => {
  const originalWidth = 345;
  const originalHeight = 272;

  const props =
    Platform.OS !== "web" && width
      ? {
          width,
          height: (originalHeight / originalWidth) * width,
        }
      : null;

  // TODO Add tabIndex 0

  return (
    <Svg {...props} viewBox="0 0 345 272">
      <Path
        d="M345 272h-42.804l-147.138-.354s2.039-31.32 22.115-24.454c6.274 2.146 4.42-3.858 1.193-14.312-7.101-23-20.852-67.542 30.672-94.222 70.697-36.608 95.172 22.699 104.377 45.007.553 1.339 1.051 2.546 1.501 3.59 3.85 8.945 9.946 5.626 17.835 1.331 3.703-2.016 7.802-4.247 12.249-5.526V272z"
        fill="url(#prefix__paint0_linear)"
      />
      <Path
        d="M275.577 257.745v-79.359s-9.604.292-4.802 19.367-10.913-3.204-9.603 12.086c1.309 15.289 12.513 17.546-.073 18.638-12.586 1.092.946 30.433 14.478 29.268z"
        fill="url(#prefix__paint1_radial)"
      />
      <Path
        d="M275.577 257.745v-79.359s9.603.292 4.801 19.367c-4.801 19.075 10.913-3.204 9.603 12.086-1.309 15.289-12.513 17.546.073 18.638 12.586 1.092-.946 30.433-14.477 29.268z"
        fill="url(#prefix__paint2_radial)"
      />
      <Path
        d="M275.577 270.122a.808.808 0 01-.801-.801v-51.983c0-.437.364-.801.801-.801.436 0 .8.364.8.801v51.983a.808.808 0 01-.8.801z"
        fill="#fff"
      />
      <Path
        d="M283.361 241.145l-7.566 7.572c-.291.291-.291.655 0 .947.291.291.655.291.946 0l7.566-7.572c.291-.291.291-.656 0-.947-.291-.218-.728-.218-.946 0zM283.361 218.284l-7.566 7.572c-.291.291-.291.655 0 .946.291.292.655.292.946 0l7.566-7.571c.291-.292.291-.656 0-.947-.291-.218-.728-.218-.946 0zM267.792 232.19l7.566 7.572c.291.291.291.655 0 .946-.291.292-.654.292-.945 0l-7.567-7.571c-.291-.292-.291-.656 0-.947.291-.291.655-.291.946 0z"
        fill="#fff"
      />
      <Path
        d="M283.87 265.098h-16.369v5.024h16.369v-5.024z"
        fill="url(#prefix__paint3_radial)"
      />
      <Path
        d="M310.786 252.503V139.508s-13.676.364-6.838 27.593c6.838 27.23-15.568-4.514-13.677 17.255 1.892 21.769 17.824 25.046-.145 26.502-17.897 1.456 1.382 43.247 20.66 41.645z"
        fill="url(#prefix__paint4_radial)"
      />
      <Path
        d="M310.786 252.503V139.508s13.677.364 6.839 27.593c-6.839 27.23 15.568-4.514 13.676 17.255-1.891 21.769-17.823 25.046.146 26.502 17.969 1.456-1.31 43.247-20.661 41.645z"
        fill="url(#prefix__paint5_radial)"
      />
      <Path
        d="M310.786 270.122a1.12 1.12 0 01-1.091-1.092v-74.044a1.12 1.12 0 011.091-1.092c.582 0 1.092.51 1.092 1.092v74.044c0 .582-.437 1.092-1.092 1.092z"
        fill="#fff"
      />
      <Path
        d="M321.845 228.841l-10.768 10.848a.925.925 0 000 1.311.922.922 0 001.31 0l10.767-10.776a.922.922 0 000-1.31c-.364-.437-.946-.437-1.309-.073zM321.845 196.297l-10.768 10.775a.924.924 0 000 1.31.922.922 0 001.31 0l10.767-10.775a.922.922 0 000-1.31.921.921 0 00-1.309 0zM299.656 216.1l10.768 10.775a.925.925 0 010 1.311.922.922 0 01-1.31 0l-10.767-10.776a.922.922 0 010-1.31.921.921 0 011.309 0z"
        fill="#fff"
      />
      <Path
        d="M322.646 262.914h-23.353v7.208h23.353v-7.208z"
        fill="url(#prefix__paint6_radial)"
      />
      <Path
        d="M204.314 263.204L105.231 70.559s-8.148 3.204 1.309 24.536c8.366 18.93 38.993 37.714 11.64 31.088-27.354-6.698-21.025 8.446-14.477 11.649 6.547 3.131 50.851 19.439 41.03 25.118-9.894 5.679-43.722-13.178-46.195 10.703-2.474 23.88 61.181 15.362 60.818 32.471-.364 17.11-39.212-1.019-30.409 21.842 8.802 22.861 46.341 4.077 51.506 23.662 5.165 19.585 23.861 11.576 23.861 11.576z"
        fill="url(#prefix__paint7_radial)"
      />
      <Path
        d="M105.231 70.56s4.801-2.767 10.621 4.004c5.82 6.77 8.585 37.422 15.932 30.942 7.348-6.48 6.257-36.184 20.734-31.743 14.477 4.441-19.061 55.187-.582 62.904 18.478 7.718 7.42-29.34 26.407-35.675 18.988-6.334-10.912 83.072 3.783 78.267 14.696-4.805 12.368-46.232 32.592-43.247 20.224 2.985-10.112 51.256-17.169 72.078-7.057 20.823 20.515-23.516 28.154-13.251 7.638 10.266.654 55.697-21.388 68.365L105.231 70.56z"
        fill="url(#prefix__paint8_radial)"
      />
      <Path
        d="M290.109 270H159.314v-17.057h11.422c7.526 0 11.289 0 14.163-1.468a13.448 13.448 0 005.873-5.885c1.465-2.881 1.465-6.652 1.465-14.193v-87.532c0-7.542 0-11.312-1.465-14.193a13.448 13.448 0 00-5.873-5.885c-2.874-1.468-6.637-1.468-14.163-1.468h-15.678v-16.384l103.92-3.591v129.053c0 7.541 0 11.312 1.465 14.193a13.45 13.45 0 005.872 5.885c2.875 1.468 6.638 1.468 14.164 1.468h9.63V270zM256.514 37.93c0 20.948-16.946 37.93-37.85 37.93s-37.85-16.982-37.85-37.93S197.76 0 218.664 0s37.85 16.982 37.85 37.93z"
        fill="#fff"
      />
      <Path
        d="M0 271.797h210.079s-12.712-28.904-34.536-39.024c-21.752-10.121-39.576 32.325-48.524 19.147-9.021-13.177-15.277-59.191-51.215-49.872-35.938 9.32-18.623 52.858-38.484 46.742C17.532 242.601 0 271.797 0 271.797z"
        fill="url(#prefix__paint9_radial)"
      />
      <Path
        opacity={0.44}
        d="M70.204 172.279c-.873 0-1.674.145-2.401.436-.146-6.552-5.456-11.794-12.004-11.794-.8 0-1.6.073-2.328.218C50.925 154.66 44.596 150 37.176 150c-9.603 0-17.46 7.79-17.46 17.473v.51c-.946-.364-1.964-.51-2.983-.51-4.801 0-8.73 3.932-8.73 8.737s3.929 8.737 8.73 8.737h53.398c3.492 0 6.329-2.84 6.329-6.334 0-3.495-2.692-6.334-6.256-6.334z"
        fill="url(#prefix__paint10_linear)"
      />
      <Path
        opacity={0.44}
        d="M131.011 39.32c-.364 0-.655.072-1.019.218-.072-2.694-2.255-4.951-5.019-4.951-.364 0-.655 0-1.019.073-1.091-2.694-3.71-4.66-6.765-4.66-4.002 0-7.275 3.276-7.275 7.28v.219a3.4 3.4 0 00-1.237-.218 3.603 3.603 0 00-3.637 3.64c0 2.038 1.6 3.64 3.637 3.64h22.334a2.61 2.61 0 002.619-2.62 2.61 2.61 0 00-2.619-2.622z"
        fill="url(#prefix__paint11_linear)"
      />
      <Path
        d="M318.426 73.94c-.436 0-.873.073-1.309.219-.073-3.495-2.91-6.335-6.402-6.335-.437 0-.873.073-1.237.146-1.382-3.495-4.729-5.97-8.73-5.97-5.165 0-9.384 4.223-9.384 9.392v.291c-.509-.145-1.019-.291-1.601-.291a4.643 4.643 0 00-4.656 4.66c0 2.62 2.11 4.66 4.656 4.66h28.663a3.416 3.416 0 003.419-3.423c0-1.82-1.527-3.349-3.419-3.349z"
        fill="url(#prefix__paint12_linear)"
      />
      <Defs>
        <RadialGradient
          id="prefix__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(413.985 0 0 414.294 223.095 179.587)"
        >
          <Stop offset={0.003} stopColor="#4DD599" />
          <Stop offset={1} stopColor="#00918E" />
        </RadialGradient>
        <RadialGradient
          id="prefix__paint2_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(229.79 0 0 229.963 222.088 158.053)"
        >
          <Stop offset={0.003} stopColor="#4DD599" />
          <Stop offset={1} stopColor="#00918E" />
        </RadialGradient>
        <RadialGradient
          id="prefix__paint3_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(375.446 0 0 375.725 176.41 241.736)"
        >
          <Stop offset={0.003} stopColor="#4DD599" />
          <Stop offset={1} stopColor="#00918E" />
        </RadialGradient>
        <RadialGradient
          id="prefix__paint4_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(788.703 0 0 789.325 195.568 87.274)"
        >
          <Stop offset={0.003} stopColor="#4DD599" />
          <Stop offset={1} stopColor="#00918E" />
        </RadialGradient>
        <RadialGradient
          id="prefix__paint5_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(315.712 0 0 315.961 260.398 85.062)"
        >
          <Stop offset={0.003} stopColor="#4DD599" />
          <Stop offset={1} stopColor="#00918E" />
        </RadialGradient>
        <RadialGradient
          id="prefix__paint6_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(311.517 0 0 311.751 187.46 211.138)"
        >
          <Stop offset={0.003} stopColor="#4DD599" />
          <Stop offset={1} stopColor="#00918E" />
        </RadialGradient>
        <RadialGradient
          id="prefix__paint7_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-81.195 97.887 -89.53) scale(231.619 231.446)"
        >
          <Stop offset={0.003} stopColor="#4DD599" />
          <Stop offset={1} stopColor="#00918E" />
        </RadialGradient>
        <RadialGradient
          id="prefix__paint8_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-81.195 109.722 -115.591) scale(383.366 383.078)"
        >
          <Stop offset={0.003} stopColor="#4DD599" />
          <Stop offset={1} stopColor="#00918E" />
        </RadialGradient>
        <RadialGradient
          id="prefix__paint9_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(758.798 0 0 759.397 639.447 429.215)"
        >
          <Stop stopColor="#51C0FF" />
          <Stop offset={1} stopColor="#325AC3" />
        </RadialGradient>
        <LinearGradient
          id="prefix__paint0_linear"
          x1={490.408}
          y1={81.187}
          x2={211.66}
          y2={666.101}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#8673DF" />
          <Stop offset={1} stopColor="#865DC9" />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint10_linear"
          x1={-8.376}
          y1={64.896}
          x2={46.962}
          y2={187.802}
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0.001} stopColor="#fff" />
          <Stop offset={1} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint11_linear"
          x1={98.139}
          y1={-5.549}
          x2={121.261}
          y2={45.805}
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0.001} stopColor="#fff" />
          <Stop offset={1} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint12_linear"
          x1={276.294}
          y1={16.378}
          x2={305.966}
          y2={82.28}
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0.001} stopColor="#fff" />
          <Stop offset={1} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default AboutIllustration;
