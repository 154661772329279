import { InputCustomExercise, RoutineLength } from "./store/types";

export type RoutineActivity = string | Omit<InputCustomExercise, "isRest">;

export type CalculatedRoutineMoment = {
  activity: RoutineActivity;
  nextActivity: RoutineActivity;
  roundIndex: number;
  exerciseWithinRoundIndex: number; // exercise index within rounds eg 1,2,1,2
  exerciseWithinRoutineIndex: number; // exercise index within routine eg 1,2,3,4
  activityDuration: number | "at-will";
  activityDurationIndex: number;
  elapsedRoutineLength: RoutineLength;
};

export type CalculatedRoutineType = CalculatedRoutineMoment[];

export enum InputNumericTypes {
  EXERCISE = "EXERCISE",
  REST = "REST",
  ROUNDS = "ROUNDS",
}

export enum Activities {
  WARM_UP = "WARM_UP",
  REST = "REST",
  FINISH = "FINISH",
}

export enum Pages {
  choose = "choose",
  go = "go",
  about = "about",
  legalWeb = "legal-web",
  legalApp = "legal-app",
  legalDisclaimer = "legal-disclaimer",
  saved = "saved",
  appAndroid = "appAndroid",
  appIOs = "appIOs",
}
