import { state } from "store/types";
import { connect } from "react-redux";
import breakPoint from "store/computed/breakPoints";
import InputTextContainer from "./input-text-container";

const mapState = (state: state) => {
  return {
    breakPoint: breakPoint(state),
  };
};

export default connect(mapState)(InputTextContainer);
