import * as React from "react";
import { Path, Svg } from "react-native-svg";
import { preventTabbing } from "components/CONST";

type Props = {
  fullWidth?: boolean;
};

const ShareIcon = ({ fullWidth }: Props) => {
  return (
    <Svg
      width={19}
      height={21}
      viewBox="0 0 19 21"
      style={{
        width: fullWidth ? "100%" : undefined,
        height: "auto",
      }}
      {...preventTabbing}
    >
      <Path
        d="M10.5 14.9521V4.36627L13.7929 7.65916L15.2071 6.24495L9.5 0.537842L3.79289 6.24495L5.20711 7.65916L8.5 4.36627V14.9521H10.5ZM18.5 18.9521V9.95205H16.5V18.9521H2.5V9.95205H0.5V18.9521C0.5 20.0566 1.39543 20.9521 2.5 20.9521H16.5C17.6046 20.9521 18.5 20.0566 18.5 18.9521Z"
        fill="white"
        {...preventTabbing}
      />
    </Svg>
  );
};

export default ShareIcon;
