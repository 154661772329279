import { CalculatedRoutineMoment } from "application/types";
import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import CONST from "components/CONST";
import calculateRoutineActivityName from "application/store/computed/calculateRoutineActivityName";

type Props = {
  routineCurrent: CalculatedRoutineMoment;
  togglePause: () => void;
  heightBreakPoint: boolean;
  warmUpIsFinished: boolean;
  breakPoint: boolean;
  paused: boolean;
};

const Activity = ({
  heightBreakPoint,
  routineCurrent,
  togglePause,
  warmUpIsFinished,
  breakPoint,
  paused,
}: Props) => {
  // TODO Remove activity prop is not used
  const { activity, activityDuration } = routineCurrent;

  // Either
  // - current activity must not be an at-will or
  // - the warmup must not be finished
  // for the pause to be considered active.
  const actsAsPaused =
    paused && (activityDuration !== "at-will" || !warmUpIsFinished);

  const mainText = (() => {
    if (actsAsPaused) {
      return "Paused";
    }
    if (!warmUpIsFinished) {
      return "Get ready";
    }

    if (typeof activity === "string") {
      return activity;
    }

    return activity.name;
  })();

  const weight = (() => {
    if (typeof activity === "string" || !warmUpIsFinished) return null;
    const { amount, format } = activity.weight;
    if (amount === 0) return null;
    return `${amount}${format}`;
  })();

  const styles = StyleSheet.create({
    container: {
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
    },
    activity: {
      fontFamily: CONST.fonts.latoRegular,
      color: "white",
      fontSize: breakPoint ? 58 : 36,
      textAlign: "center",
    },
    weight: {
      fontFamily: CONST.fonts.latoRegular,
      color: "white",
      fontSize: breakPoint ? 29 : 18,
      textAlign: "center",
      position: "absolute",
      top: breakPoint ? 64 : 40,
      left: 0,
      right: 0,
    },
    activityWhenPaused: {
      fontFamily: CONST.fonts.latoRegular,
      color: "white",
      fontSize: breakPoint ? 30 : 18,
      textAlign: "center",
      opacity: 0.5,
      position: "absolute",
      left: 0,
      right: 0,
      top: heightBreakPoint ? -30 : -18,
    },
  });

  const activityWhenPaused = (() => {
    if (!warmUpIsFinished) {
      return "Get ready";
    }
    if (activityDuration === "at-will") {
      return null;
    }
    return calculateRoutineActivityName(activity);
  })();

  const actsAsIfCurrentActivityIsAtWill =
    activityDuration === "at-will" && warmUpIsFinished;

  return (
    <View style={styles.container}>
      <TouchableOpacity
        disabled={actsAsIfCurrentActivityIsAtWill}
        onPress={() => togglePause()}
      >
        {actsAsPaused && (
          <Text style={styles.activityWhenPaused}>{activityWhenPaused}</Text>
        )}
        <Text style={styles.activity}>{mainText}</Text>
        {weight && <Text style={styles.weight}>{weight}</Text>}
      </TouchableOpacity>
    </View>
  );
};

export default Activity;
