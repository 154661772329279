import * as React from "react";
import { connect } from "react-redux";
import { customSetRestDuration } from "store/actions";
import RestInput from "./rest-input";

const mapDispatch = {
  customSetRestDuration,
};

export default connect(null, mapDispatch)(RestInput);
