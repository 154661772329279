import qs from "qs";
import Constants from "expo-constants";
import * as Linking from "expo-linking";
import { Platform } from "react-native";
import { compressToEncodedURIComponent } from "lz-string";
import { InputsHiit, state } from "../types";

function getBaseUrl() {
  let baseUrl = Constants.manifest.extra.baseUrl;

  if (Platform.OS === "web") {
    baseUrl = window.location.origin;
  }

  // We're only using Linking in Expo Client (= in development),
  // since we want real users of the standalone app to share web URLs
  // since they are more "portable".
  if (Constants.appOwnership === "expo") {
    baseUrl = Linking.makeUrl();
  }

  // Well-formatted URL with / at the end
  return new URL(baseUrl).toString();
}

function calculateHiitWorkoutSharableUrl(inputs: InputsHiit) {
  const baseUrl = getBaseUrl();

  const queryString = qs.stringify(
    {
      exercise: inputs.secondsExercise,
      rest: inputs.secondsRest,
      rounds: inputs.rounds,
      exercises: inputs.exercises
        .map(item => item.text)
        .filter(item => item !== ""),
    },
    {
      // Setting `arrayFormat` to `"repeat"` makes `qs`
      // represent arrays repeating the same parameter multiple times:
      // eg. `?exercise=Jumps&exercise=Squats`
      // There are other ways to represent arrays in query parameters,
      // namely repeating parameter with square brackets or providing a list
      // of comma-separated values. There is no consensus on how to do this
      // as per https://medium.com/raml-api/arrays-in-query-params-33189628fa68.
      // `"repeat"` was chosen because it doesn't introduce any "special characters"
      // like a comma or strange looking [].
      arrayFormat: "repeat",
    },
  );

  return `${baseUrl}?${queryString}`;
}

function calculateCustomWorkoutSharableUrl(inputs: state["inputsCustom"]) {
  const baseUrl = getBaseUrl();

  // Sharable URL for a custom workout
  // is a stringified, `lz-string`-compressed inputs object.
  const stringifedInputs = JSON.stringify(inputs);
  const compressedInputs = compressToEncodedURIComponent(stringifedInputs);
  return `${baseUrl}?i=${compressedInputs}`;
}

export const calculateSharableUrl = (
  state: Pick<state, "inputsCustom" | "inputsHiit" | "workoutType">,
) => {
  if (state.workoutType === "Custom") {
    return calculateCustomWorkoutSharableUrl(state.inputsCustom);
  }

  return calculateHiitWorkoutSharableUrl(state.inputsHiit);
};

export default calculateSharableUrl;
