import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";
import CONST from "./CONST";

type Props = {
  isDark: boolean;
};

const IconWorkout = ({ isDark }: Props) => {
  const { darkColor } = CONST;
  return (
    <Svg
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      {...preventTabbing}
    >
      <Path
        d="M1.752 11.166C.784 11.166 0 10.396 0 9.448c0-.95.784-1.719 1.752-1.719.967 0 1.751.77 1.751 1.719 0 .949-.784 1.718-1.751 1.718zM4.878 0c.443 0 .802.352.802.787v6.47l5.236.01a2.326 2.326 0 012.041 1.205l4.922 9.05a.996.996 0 01-.422 1.36c-.5.26-1.12.075-1.387-.415l-4.086-7.517L9.1 18.353a1.031 1.031 0 01-1.323.58 1 1 0 01-.592-1.298l2.335-5.987H5.68v6.565c0 .435-.359.787-.802.787a.795.795 0 01-.802-.787V.787c0-.435.36-.787.802-.787z"
        fill={isDark ? darkColor : "#fff"}
        {...preventTabbing}
      />
    </Svg>
  );
};

export default IconWorkout;
