import * as React from "react";
import { connect } from "react-redux";
import SetScreenSize from "./set-screen-size";
import { setDimensions } from "store/actions";

const mapDispatch = {
  setDimensionsAction: setDimensions,
};

export default connect(null, mapDispatch)(SetScreenSize);
