import addEmptyExercise from "store/utils/addEmptyExercise";
import { actionMoveExerciseDown, state } from "store/types";

type Args = {
  state: state;
  action: actionMoveExerciseDown;
};

const moveExerciseUp = ({ state, action }: Args): state => {
  const exerciseToMoveDown = state.inputsHiit.exercises[action.index];
  const exercisesMovedDown = state.inputsHiit.exercises.filter(
    (item, index) => index !== action.index,
  );
  exercisesMovedDown.splice(action.index + 1, 0, exerciseToMoveDown);

  return {
    ...state,
    inputsHiit: {
      ...state.inputsHiit,
      exercises: addEmptyExercise(exercisesMovedDown),
    },
  };
};

export default moveExerciseUp;
