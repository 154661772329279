import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import breakPoint from "store/computed/breakPoints";
import Header from "./header";

const mapState = (state: state) => {
  return {
    breakPoint: breakPoint(state),
  };
};

export default connect(mapState)(Header);
