import * as React from "react";
import VARS from "application/VARS";
import { Animated, Platform, ScrollView, StyleSheet, View } from "react-native";
import { useSafeArea } from "react-native-safe-area-context";
import Constants from "expo-constants";
import { LinearGradient } from "expo-linear-gradient";
import { useKeepAwake } from "expo-keep-awake";

import Header from "application/components/global/components/header";
import CONST from "components/CONST";

import ToggleSound from "./toggle-sound";
import Back from "./back";
import SkipPrev from "./skip-prev";
import Timer from "./timer";
import Activity from "./activity";
import ProgressBar from "./progress-bar";
import ExerciseNumber from "./details/exercise-number";
import Elapsed from "./details/elapsed";
import Remaining from "./details/remaining";
import ShareWorkoutBtn from "./share-workout-btn";
import UpNext from "./up-next";

import CountdownTick from "./countdown-tick-invisible";
import MakeSoundInvisible from "./make-sound-invisible";
import SetMetaTags from "components/global/invisible/setMetaTags";
import ScrollTop from "components/global/invisible/scrollTop";
import { CalculatedRoutineMoment } from "application/types";
import VideoKeepScreenOn from "./video-keep-screen-on";

type Props = {
  isResting: boolean;
  warmUpIsFinished: boolean;
  breakPoint: boolean;
  breakPointSmall: boolean;
  routineCurrent: CalculatedRoutineMoment;
};

const InProgressPage = ({
  warmUpIsFinished,
  breakPoint,
  breakPointSmall,
  isResting,
  routineCurrent,
}: Props) => {
  const safeArea = useSafeArea();

  const [fadeAnim] = React.useState(new Animated.Value(0));

  React.useEffect(() => {
    let colorValue;
    if (!warmUpIsFinished) {
      colorValue = 0;
    } else if (!isResting) {
      colorValue = 1;
    } else {
      colorValue = 2;
    }

    Animated.timing(fadeAnim, {
      toValue: colorValue,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [isResting, warmUpIsFinished]);

  let gradientBackgroundSolid;
  let gradientBackgroundTransparent;
  if (!warmUpIsFinished) {
    gradientBackgroundSolid = CONST.workoutColors.backgroundWarmUp;
    gradientBackgroundTransparent =
      CONST.workoutColors.backgroundWarmUpTransparent;
  } else if (!isResting) {
    gradientBackgroundSolid = CONST.workoutColors.backgroundActive;
    gradientBackgroundTransparent =
      CONST.workoutColors.backgroundActiveTransparent;
  } else {
    gradientBackgroundSolid = CONST.workoutColors.backgroundRest;
    gradientBackgroundTransparent =
      CONST.workoutColors.backgroundRestTransparent;
  }

  const backgroundColorConfig = fadeAnim.interpolate({
    inputRange: [0, 1, 2],
    outputRange: [
      CONST.workoutColors.backgroundWarmUp,
      CONST.workoutColors.backgroundActive,
      CONST.workoutColors.backgroundRest,
    ],
  });

  const paddingForPrevAndNext = (() => {
    if (breakPointSmall) {
      return 0;
    }
    return breakPoint ? 100 : 50;
  })();

  const styles = StyleSheet.create({
    // Containers
    container: {
      flex: 1,
    },
    container2: {
      flex: 1,
      paddingTop: Constants.statusBarHeight + CONST.sizes.viewportTopPadding,
      overflow: "hidden", // needed for background circle
    },
    // Main layout sections
    top: {
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      paddingBottom: breakPoint ? 50 : 30,
    },
    mid: {
      flexDirection: "row",
      alignItems: "center",
      zIndex: -1,
    },
    midColFirst: {
      flex: 1,
      alignItems: breakPointSmall ? "center" : "flex-end",
      zIndex: 1,
      paddingRight: paddingForPrevAndNext,
    },
    midColLast: {
      flex: 1,
      alignItems: breakPointSmall ? "center" : "flex-start",
      zIndex: 1,
      paddingLeft: paddingForPrevAndNext,
    },
    lowerFirst: {
      alignItems: "center",
      flex: 1,
      paddingTop: breakPoint ? 50 : 30,
    },
    lower: {
      flexDirection: "row",
      paddingBottom: 20,
      paddingTop: 20,
      alignItems: "flex-end",
      paddingHorizontal: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
    },
    footer: {
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
      paddingBottom: safeArea.bottom + 15,
      paddingTop: 15,
    },
    // Sub sections
    lowerCol1: {
      flex: 1,
    },
    lowerCol2: {
      //
    },
    lowerCol3: {
      flex: 1,
      alignItems: "flex-end",
    },
    footerCol1: {
      flex: 1,
      display: breakPoint ? undefined : "none",
    },
    footerCol2: {
      flex: 2,
    },
    footerCol3: {
      flex: 1,
      alignItems: "flex-end",
      display: breakPoint ? undefined : "none",
    },
    // Misc
    soundToggleMobile: {
      position: "absolute",
      top: Constants.statusBarHeight + CONST.sizes.viewportTopPadding - 3,
      right: 80,
      zIndex: 1,
      elevation: 1,
    },
    closeBtnMobile: {
      position: "absolute",
      top: Constants.statusBarHeight + CONST.sizes.viewportTopPadding,
      left: 20,
      zIndex: 1,
      elevation: 1,
    },
    videoKeepScreenOnCont: {
      position: "absolute",
      top: 0,
      left: "50%",
      opacity: 0,
    },
  });

  // TODO Remove hidden video when this is supported on web: https://docs.expo.io/versions/latest/sdk/keep-awake/
  useKeepAwake();

  const shouldCountDownAutomatically =
    !warmUpIsFinished || routineCurrent.activityDuration !== "at-will";

  return (
    <>
      {Platform.OS === "web" && (
        <View style={styles.videoKeepScreenOnCont}>
          <VideoKeepScreenOn />
        </View>
      )}
      <ScrollTop />
      {shouldCountDownAutomatically && <CountdownTick />}
      <SetMetaTags title="Love HIIT - GO!" />
      <MakeSoundInvisible />
      <Animated.View
        style={[
          styles.container,
          {
            backgroundColor: backgroundColorConfig,
          },
        ]}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          scrollEventThrottle={16}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View style={styles.container2}>
            <Header />

            {!breakPoint && (
              <View style={styles.soundToggleMobile}>
                <ToggleSound />
              </View>
            )}

            {!breakPoint && (
              <View style={styles.closeBtnMobile}>
                <Back />
              </View>
            )}

            <View style={styles.top}>
              <Activity />
            </View>

            <View style={styles.mid}>
              <View style={styles.midColFirst}>
                <SkipPrev type="prev" />
              </View>
              <View>
                <Timer />
              </View>
              <View style={styles.midColLast}>
                <SkipPrev
                  type={shouldCountDownAutomatically ? "skip" : "next"}
                />
              </View>
            </View>

            <View style={styles.lowerFirst}>
              <UpNext />
            </View>

            <LinearGradient
              style={styles.lower}
              colors={[gradientBackgroundTransparent, gradientBackgroundSolid]}
              // start={[0, 0]}
              // end={[1, 0]}
            >
              <View style={styles.lowerCol1}>
                <Elapsed />
              </View>
              <View style={styles.lowerCol2}>
                <ExerciseNumber />
              </View>
              <View style={styles.lowerCol3}>
                <Remaining />
              </View>
            </LinearGradient>

            <ProgressBar />

            <Animated.View
              style={[
                styles.footer,
                { backgroundColor: backgroundColorConfig },
              ]}
            >
              <View style={styles.footerCol1}>
                <Back />
              </View>

              {(Platform.OS === "web" || VARS.envIsDev) && (
                <View style={styles.footerCol2}>
                  <ShareWorkoutBtn />
                </View>
              )}

              <View style={styles.footerCol3}>
                <ToggleSound />
              </View>
            </Animated.View>
          </View>
        </ScrollView>
      </Animated.View>
    </>
  );
};

export default InProgressPage;
