import {
  Activities,
  RoutineActivity,
  CalculatedRoutineMoment,
} from "application/types";
// import { LayoutChangeEvent } from "react-native";
import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import CONST from "components/CONST";
import calculateRoutineActivityName from "application/store/computed/calculateRoutineActivityName";

type Props = {
  nextActivity: RoutineActivity;
  breakPoint: boolean;
  paused: boolean;
  warmUpIsFinished: boolean;
  currentActivityDuration: CalculatedRoutineMoment["activityDuration"];
};

const UpNext = ({
  breakPoint,
  nextActivity,
  paused,
  currentActivityDuration,
  warmUpIsFinished,
}: Props) => {
  const actsAsPaused =
    paused &&
    // Either
    // - current activity must not be an at-will or
    // - the warmup must not be finisehd
    // for the pause to be considered active.
    (currentActivityDuration !== "at-will" || !warmUpIsFinished);
  let opacity = 1;
  if (nextActivity === Activities.FINISH) {
    opacity = 0;
  } else if (actsAsPaused) {
    opacity = 0.5;
  }

  const styles = StyleSheet.create({
    container: {
      opacity,
    },
    text: {
      color: "white",
      fontFamily: CONST.fonts.latoRegular,
      textAlign: "center",
    },
    textLabel: {
      textTransform: "uppercase",
      fontSize: breakPoint ? 18 : 11,
      marginBottom: breakPoint ? 1 : 5,
      // marginBottom: 12,
    },
    textHeading: {
      fontSize: breakPoint ? 30 : 18,
    },
  });

  return (
    <View style={styles.container}>
      <Text style={[styles.text, styles.textLabel]}>Up Next</Text>
      <Text style={[styles.text, styles.textHeading]}>
        {calculateRoutineActivityName(nextActivity)}
      </Text>
    </View>
  );
};

export default UpNext;
