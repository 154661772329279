import { Pages } from "application/types";
import { Props } from "./index";

import React from "react";
import { Link } from "react-router-dom";

import VARS from "application/VARS";

const styles = {
  link: {
    textDecoration: "none",
    display: "flex",
  },
};

const LinkComponent = ({
  children,
  goTo,
  accessibilityLabel,
  onPress,
}: Props) => {
  if (goTo === Pages.appAndroid || goTo === Pages.appIOs) {
    const url =
      goTo === Pages.appAndroid
        ? VARS.googlePlayStoreUrl
        : VARS.appleAppStoreUrl;
    return (
      <a target="_blank" href={url} style={styles.link}>
        {children}
      </a>
    );
  }
  return (
    <Link
      to={goTo === Pages.choose ? "/" : goTo}
      style={styles.link}
      aria-label={accessibilityLabel}
      onClick={() => {
        onPress && onPress();
      }}
    >
      {children}
    </Link>
  );
};

export default LinkComponent;
