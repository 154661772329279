import * as React from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";
import CONST from "components/CONST";
import { state, toggleWorkoutTypeArgs } from "store/types";
import { toggleWorkoutType } from "store/actions";

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    backgroundColor: CONST.colorBtnSecondary,
    borderRadius: CONST.buttonBorderRadius,
  },
  button: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  button_active: {
    backgroundColor: CONST.colorChooseCtaGreen,
    borderRadius: CONST.buttonBorderRadius,
  },
  buttonText: {
    color: "white",
    fontSize: 14,
    paddingVertical: 12,
    fontFamily: CONST.fonts.latoBold,
  },
});

type Props = {
  toggleWorkoutType: (arg0: toggleWorkoutTypeArgs) => void;
  workoutType: state["workoutType"];
};

// TODO Animate toggle
const WorkoutTypeToggle = ({ workoutType, toggleWorkoutType }: Props) => {
  const workoutIsHIIT = workoutType === "HIIT";
  return (
    <View style={styles.root}>
      <TouchableOpacity
        onPress={() => toggleWorkoutType({ workoutType: "HIIT" })}
        style={[styles.button, workoutIsHIIT && styles.button_active]}
      >
        <Text style={[styles.buttonText]}>HIIT</Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => toggleWorkoutType({ workoutType: "Custom" })}
        style={[styles.button, !workoutIsHIIT && styles.button_active]}
      >
        <Text style={[styles.buttonText]}>Custom</Text>
      </TouchableOpacity>
    </View>
  );
};

export default WorkoutTypeToggle;
