import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";
import { preventTabbing } from "application/components/CONST";

interface Props {
  size: number;
}

const TwitterIcon = ({ size }: Props) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 54 54" {...preventTabbing}>
      <Rect width="54" height="54" rx="12" fill="#4DC8F1" {...preventTabbing} />
      <Path
        d="M40.5196 20.4229C40.9599 30.2341 33.6441 41.1731 20.6926 41.1731C16.7536 41.1731 13.0869 40.0177 10 38.0386C13.7001 38.4747 17.3943 37.4488 20.3266 35.1497C17.2749 35.0937 14.6984 33.077 13.8112 30.3059C14.9039 30.5148 15.9791 30.4538 16.9607 30.1873C13.6057 29.5123 11.2907 26.4905 11.3659 23.2591C12.3066 23.7813 13.3826 24.0954 14.5263 24.1305C11.4194 22.0553 10.5397 17.9534 12.3676 14.818C15.807 19.0386 20.9465 21.8155 26.7444 22.1071C25.7277 17.7428 29.0376 13.5415 33.5397 13.5415C35.5464 13.5415 37.3592 14.3878 38.6307 15.7437C40.2197 15.4312 41.7126 14.8506 43.0602 14.0511C42.5397 15.6802 41.4336 17.0478 39.9933 17.9099C41.4043 17.742 42.7485 17.3669 44 16.8122C43.0652 18.2115 41.8822 19.4396 40.5196 20.4229Z"
        fill="white"
        {...preventTabbing}
      />
    </Svg>
  );
};

export default TwitterIcon;
