import * as React from "react";
import { connect } from "react-redux";
import { state } from "store/types";

import RootComponent from "./root-component";

const mapState = (state: state) => {
  const { width, height } = state;
  return {
    width,
    height,
  };
};

export default connect(mapState)(RootComponent);
