import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { formatRoutineLength } from "components/utils/formatSeconds";
import { RoutineLength } from "application/store/types";
// import STYLES from "../../STYLES";

type Props = {
  elapsed: RoutineLength;
};

const Elapsed = ({ elapsed }: Props) => {
  const timeFormatted = formatRoutineLength(elapsed);

  return (
    <View>
      {/*<Text style={[styles.text, styles.label]}>Elapsed</Text>*/}
      <Text style={styles.text}>{timeFormatted}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    color: "white",
    textTransform: "uppercase",
  },
  // label: {
  //   marginBottom: STYLES.labelBottomMargin,
  // },
});

export default Elapsed;
