import { state, RoutineCustom } from "store/types";
import addEmptyExercise from "store/utils/addEmptyExercise";
import { v4 as uuid } from "uuid";
import * as Localization from "expo-localization";

const exercisesShort: string[] = ["Push ups", "Squats", "Sit ups"];
const exercisesLong = new Array(20).fill("").map((_, index) => `${index}`);

const seedData = {
  exercises: exercisesShort,
  secondsExercise: 40,
  secondsRest: 20,
  rounds: 4,
};

export const oneSecondInMilliseconds: number = 1000;

export const warmUpTimeStartIndex = 5;

// Based on https://commons.wikimedia.org/wiki/File:Metric_system_adoption_map.svg
const localesUsingNonMetricSystem = ["mm", "us", "lr"];
const usesMetricSystem = !localesUsingNonMetricSystem.some(locale =>
  // eg. "en-US"
  Localization.locale.toLowerCase().includes(locale),
);

const routineCustom: RoutineCustom = [
  {
    name: "",
    weight: {
      amount: 0,
      format: usesMetricSystem ? "kg" : "lbs",
    },
    repeat: {
      amount: 10, // TODO
      format: "reps", // TODO
    },
    isRest: false,
  },
  // {
  //   isRest: true,
  //   time: "00:30",
  // },
  // {
  //   name: "Pull ups",
  //   weight: {
  //     amount: 0,
  //     format: "kg",
  //   },
  //   repeat: {
  //     amount: "0",
  //     format: "max",
  //   },
  //   isRest: false,
  // },
  // {
  //   isRest: true,
  //   time: "00:15",
  // },
  // {
  //   name: "Push ups",
  //   weight: {
  //     amount: 0,
  //     format: "kg",
  //   },
  //   repeat: {
  //     amount: "10",
  //     format: "reps",
  //   },
  //   isRest: false,
  // },
];

export const initState: state = {
  showEmailSignup: false,
  emailSignupWasClosed: null,
  soundHackActivated: false, // https://github.com/expo/expo/issues/8235
  savedWorkouts: [],
  routineTimeIndex: 0,
  routineIsFinished: false,
  warmUpTimeIndex: warmUpTimeStartIndex,
  warmUpIsFinished: false,
  isLandscape: false,
  width: 0,
  height: 0,
  paused: false,
  sound: true,
  shareModalIsOpen: false,
  popularWorkoutsModalIsOpen: false,
  mobileMenuIsOpen: false,
  saveModalIsOpen: false,
  legalConsentGranted: false,
  workoutType: "HIIT",
  inputsHiit: {
    secondsExercise: seedData.secondsExercise,
    secondsRest: seedData.secondsRest,
    rounds: seedData.rounds,
    exercises: addEmptyExercise(
      seedData.exercises.map(item => {
        return {
          text: item,
          id: uuid(),
        };
      }),
    ),
  },
  inputsCustom: {
    routine: routineCustom,
    rounds: 1,
    rest: 0,
  },
  usesMetricSystem,
  lastSetCustomRestDuration: 0,
  lastSetCustomExerciseDuration: 0,
  lastSetCustomExerciseDurationFormat: "reps",
  userEmailAddress: null,
  emailSubscriptions: {},
};

export default initState;
