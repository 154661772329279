import { state } from "store/types";
import * as React from "react";
import { connect } from "react-redux";
import { routineLength, routineCurrent } from "store/computed/calculateRoutine";
import Remaining from "./remaining";

const mapState = (state: state) => {
  const wholeRoutineLengthValue = routineLength(state);
  const elapsedRoutineLengthValue = routineCurrent(state).elapsedRoutineLength;
  return {
    remaining: {
      seconds:
        wholeRoutineLengthValue.seconds - elapsedRoutineLengthValue.seconds,
      isAccurate: wholeRoutineLengthValue.isAccurate,
    },
  };
};

export default connect(mapState)(Remaining);
