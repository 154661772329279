import {
  InputCustomExercise,
  removeExerciseArgs,
  setExerciseArgs,
} from "store/types";
import * as React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  ViewProps,
} from "react-native";
import CONST from "components/CONST";
import IconDragHandle from "components/global/components/icons/dragHandle";
import IconClose from "components/global/components/icons/close-icon";
import WeightInput from "../input/weight-input";
import DurationInput from "../input/duration-input";

interface Props extends InputCustomExercise {
  removeExerciseCustom: (arg0: removeExerciseArgs) => void;
  index: number;
  customSetExerciseText: (arg0: setExerciseArgs) => void;
}

// TODO! Should this be ListAwareTextInput?

const Exercise = ({
  name,
  weight,
  repeat,
  removeExerciseCustom,
  index,
  customSetExerciseText,
}: Props) => {
  return (
    <View style={styles.root}>
      <View style={styles.colDragHandle}>
        <IconDragHandle />
      </View>
      <View style={styles.colMain}>
        <View style={styles.rowTop}>
          <TextInput
            style={styles.name}
            placeholder="Add exercise..."
            placeholderTextColor={CONST.colorPlaceholderText}
            value={name}
            onChangeText={text => customSetExerciseText({ index, text })}
          />
          <TouchableOpacity onPress={() => removeExerciseCustom({ index })}>
            <IconClose />
          </TouchableOpacity>
        </View>
        <View style={styles.rowBottom}>
          <WeightInput
            value={weight.amount}
            format={weight.format}
            index={index}
          />

          {/* TODO Change name of duration or repeat? */}
          <DurationInput
            value={repeat.amount}
            format={repeat.format}
            index={index}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    backgroundColor: "white",
    borderRadius: 12,
    padding: 20,
    // height: 130,
  },
  colDragHandle: {
    paddingLeft: 15,
    paddingRight: 12,
    justifyContent: "center",
    // backgroundColor: "rgba(247,199,8,.5)", // gold
    marginRight: 10,
  },
  colMain: {
    flexDirection: "column",
    flexGrow: 1,
  },
  rowTop: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#F3F2F5",
    // paddingBottom: 15,
    marginBottom: 15,
    height: 40,
  },
  rowBottom: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {
    fontSize: 16,
    fontFamily: CONST.fonts.latoRegular,
    color: CONST.colorFontGrey,
    paddingVertical: 10,
    flexGrow: 1,
    marginRight: 10,
  },
});

export default Exercise;
