import { Pages } from "application/types";
import { setLegalConsentArgs } from "store/types";

import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import Link from "components/global/components/link";
import CloseIcon from "components/global/components/icons/close-icon";

import CONST from "components/CONST";

type Props = {
  breakPoint: boolean;
  customBreakPoint1: boolean;
  customBreakPoint2: boolean;
  legalConsentGranted: boolean;
  setLegalConsent: (arg0: setLegalConsentArgs) => void;
};

const preBreakPoint1VerticalSpacing = 15;

const LegalMessageWeb = ({
  breakPoint,
  customBreakPoint1,
  customBreakPoint2,
  legalConsentGranted,
  setLegalConsent,
}: Props) => {
  if (legalConsentGranted) {
    return null;
  }

  const styles = StyleSheet.create({
    container: {
      backgroundColor: CONST.colorHeaderMessageBackground,
      borderBottomColor: "white",
      borderBottomWidth: 1,
      paddingHorizontal: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
      paddingVertical: 15,
      flexDirection: customBreakPoint2 ? "row" : "column",
    },
    section: {
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
    },
    text: {
      color: "white",
      fontFamily: CONST.fonts.latoRegular,
      fontSize: 12,
    },
    heading: {
      fontSize: 15,
      marginRight: 10,
    },
    link: {
      textDecorationLine: "underline",
      marginTop: 1,
    },
    buttonWrap: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 1,
      borderColor: "white",
      borderRadius: 4,
      paddingVertical: 6,
      paddingHorizontal: 15,
      marginLeft: customBreakPoint2 ? "auto" : undefined,
    },
    button: {
      marginRight: 8,
    },
    buttonWrapPreCustomBreakpoint1: {
      position: "absolute",
      right: breakPoint
        ? CONST.sizes.viewportHorizontalPaddingDesktop
        : CONST.sizes.viewportHorizontalPaddingMobile,
      bottom: 15,
    },
  });

  const sectionMarginTop = () => {
    if (!customBreakPoint1) {
      return preBreakPoint1VerticalSpacing;
    }
    if (!customBreakPoint2) {
      return 10;
    }
    return 0;
  };

  const renderCloseIcon = () => {
    return <CloseIcon size={8} />;
  };

  const renderCookies = () => {
    return (
      <View style={styles.section}>
        <Text style={[styles.text, styles.heading]}>We use cookies:</Text>
        <Link goTo={Pages.legalWeb}>
          <Text style={[styles.text, styles.link]}>Read privacy policy</Text>
        </Link>
      </View>
    );
  };

  const renderExercise = () => {
    return (
      <View
        style={[
          styles.section,
          {
            marginLeft: customBreakPoint2 ? 70 : undefined,
            marginTop: sectionMarginTop(),
          },
        ]}
      >
        <Text style={[styles.text, styles.heading]}>
          Exercises are performed at your own risk:
        </Text>
        <Link goTo={Pages.legalDisclaimer}>
          <Text style={[styles.text, styles.link]}>Read disclaimer</Text>
        </Link>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {renderCookies()}
      {renderExercise()}
      <TouchableOpacity
        onPress={() => setLegalConsent({ value: true })}
        style={[
          styles.buttonWrap,
          !customBreakPoint1 && {
            marginTop: preBreakPoint1VerticalSpacing,
          },
          customBreakPoint1 &&
            !customBreakPoint2 &&
            styles.buttonWrapPreCustomBreakpoint1,
        ]}
      >
        <Text style={[styles.text, styles.button]}>Agree to both</Text>
        {renderCloseIcon()}
      </TouchableOpacity>
    </View>
  );
};

export default LegalMessageWeb;
