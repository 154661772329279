import { StyleSheet } from "react-native";
import CONST from "components/CONST";

export default () => {
  return StyleSheet.create({
    container: {
      // backgroundColor: CONST.colorChooseCtaGreen,
      borderRadius: 8,
    },
    link: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
    },
    text: {
      textAlign: "center",
      color: "white",
      textTransform: "uppercase",
      // fontSize: width / 100 + 15,
      fontFamily: CONST.fonts.latoBold,
      marginLeft: 10,
      fontSize: 16,
    },
  });
};
