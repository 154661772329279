import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useSafeArea } from "react-native-safe-area-context";
import CONST from "application/components/CONST";

export default function StatusBarUnderlay() {
  const { top } = useSafeArea();
  return <View style={[styles.underlay, { height: top }]} />;
}

const styles = StyleSheet.create({
  underlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: CONST.colorBlueInputBackground,
  },
});
