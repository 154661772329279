import * as React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "application/store";
import NavigationProvider from "./navigation";
import RootComponent from "./root-component";
import Loader from "./root-component/loader";
import { SafeAreaProvider } from "react-native-safe-area-context";

const { store, persistor } = configureStore();

const ProviderComponent = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Loader />}>
        <SafeAreaProvider>
          <NavigationProvider>
            <RootComponent />
          </NavigationProvider>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
};

export default ProviderComponent;
