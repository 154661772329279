// import { state } from "store/types";
import { connect } from "react-redux";
import { toggleMobileMenu } from "store/actions";
import OffCanvasMenu from "./offCanvasMenu";

const mapDispatch = {
  toggleMobileMenu,
};

export default connect(null, mapDispatch)(OffCanvasMenu);
